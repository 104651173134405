import ChatCloud from '@/assets/icons/chat-cloud';
import CloseIcon from '@/assets/icons/close-icon';
import MiloAvatar from '@/assets/icons/milo-avatar';
import MiloHeadIcon from '@/assets/icons/milo-head-icon';
import { fToNow } from '@/utils/format-time';
import { getNotificationQueries, type INotification } from '@repo/features/notification';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { parseISO } from 'date-fns';

import axiosInstance from '@/lib/axios.ts';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import ReplyCloud from '../../assets/icons/reply-cloud';
import MdxPreview from '../mdx/mdx-preview';

export interface UpdateCardProps {
  notification: INotification;
}
const notificationQueries = getNotificationQueries(axiosInstance);

export default function UpdateCard({ notification }: UpdateCardProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: markNotificationAsRead } = notificationQueries.markAsRead.useMutation({
    onSuccess: () => {
      // Invalidate the 'all' notifications query after mutation succeeds
      queryClient.invalidateQueries({ queryKey: notificationQueries.all.getKey() });
      queryClient.invalidateQueries({ queryKey: notificationQueries.count.getKey() });
    }
  });
  // const lastMessage = conversation.thread.messages.find(msg => msg.role === 'assistant' || msg.role === 'support');
  return (
    <div className="bg-white p-8 rounded-xl">
      <div className="flex justify-between">
        <MiloHeadIcon />
        <Button
          asChild
          onClick={async () => {
            markNotificationAsRead({ id: notification._id });
          }}
          size="icon"
          variant="icon"
        >
          <CloseIcon className="w-3" />
        </Button>
      </div>
      <div className="mt-5 text-lg line-clamp-3">
        <MdxPreview scope={{}} source={notification.message} />
      </div>
      <div className="mt-8">
        <Button
          className="w-full rounded-full h-14 text-2xs"
          onClick={async () => {
            markNotificationAsRead({ id: notification._id });
            if (notification.notificationFor) {
              navigate({
                to: '/conversation/$id',
                params: {
                  id: notification.notificationFor as string
                }
              });
            }
          }}
          variant="primary"
        >
          Reply
        </Button>
      </div>
    </div>
  );
}
