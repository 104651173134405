import * as React from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
} & VariantProps<typeof inputVariants>;

export const inputVariants = cva(
  'flex flex-1 bg-transparent transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground  focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        md: 'h-14',
        lg: 'h-16'
      }
    },
    defaultVariants: {
      size: 'lg'
    }
  }
);

export const inputContainerVariants = cva(
  'flex items-center relative w-full rounded-full border border-primary-400 text-sm focus-within:border-primary-500',
  {
    variants: {
      size: {
        md: 'px-6 text-2xs',
        lg: 'px-8 text-xs'
      }
    },
    defaultVariants: {
      size: 'lg'
    }
  }
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, leftIcon, rightIcon, disabled, type, size, ...props }, ref) => {
    return (
      <div
        className={cn(inputContainerVariants({ size }), {
          'border-neutral-400': disabled
        })}
      >
        {leftIcon ? <div className="pr-2">{leftIcon}</div> : null}
        <input
          className={cn(inputVariants({ size }), className)}
          disabled={disabled}
          ref={ref}
          type={type}
          {...props}
        />
        <div className="absolute right-2 top-1/2 -translate-y-1/2">{rightIcon}</div>
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
