import React, { useMemo } from 'react';
import ApexCharts from 'react-apexcharts';

import useResponsiveness from '@/hooks/use-responsiveness';

export type ChartProps = {
  data: any;
  height?: number;
  width?: number;
};

function BarChart({ width = 400, height = 350, data }: ChartProps) {
  const responsiveness = useResponsiveness();
  if (responsiveness === 'xs') {
    width = 300;
    height = 250;
  }
  const options: ApexCharts.ApexOptions = useMemo(
    () => ({
      colors: ['#000AEE', '#9A9EFF'],
      chart: {
        parentHeightOffset: 10,
        offsetY: 20
      },
      xaxis: {
        type: 'category',
        crosshairs: {
          show: false
        },
        labels: {
          style: {
            colors: '#828282',
            // fontFamily: theme.typography.fontSecondaryFamily,
            fontSize: '11px',
            fontWeight: 'bold'
          }
        },
        categories: data.options.xaxis.categories
      },
      grid: {
        borderColor: '#F2F2F2'
      },
      yaxis: {
        title: {
          text: undefined
        },

        labels: {
          style: {
            colors: '#828282',
            // fontFamily: theme.typography.fontSecondaryFamily,
            fontSize: '11px',
            fontWeight: 'normal'
          }
        }
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: false
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        }
      },
      plotOptions: {
        bar: {
          columnWidth: 30,
          dataLabels: {
            position: 'top'
          }
        }
      },
      fill: {
        opacity: 1
      },
      dataLabels: {
        offsetY: -20,
        textAnchor: 'middle',
        style: {
          colors: ['#828282'],
          // fontFamily: theme.typography.fontSecondaryFamily,
          fontSize: '11px',
          fontWeight: 'normal'
        }
      },
      responsive: [
        {
          breakpoint: 900,
          options: {
            plotOptions: {
              bar: {
                columnWidth: '80%'
              }
            },
            dataLabels: {
              enabled: false
            }
          }
        }
      ],
      legend: {
        position: 'bottom',
        markers: {
          radius: 10
        }
      },
      ...data.options
    }),
    [data.options]
  );
  return <ApexCharts height={height} options={options} series={data.series} type="bar" width={width} />;
}

export default React.memo(BarChart);
