import * as React from 'react';
import BusinessProfileIcon from '@/icons/BusinessProfile.svg?react';
import MiloIcon from '@/icons/Milo.svg?react';
import PersonalSettingsIcon from '@/icons/PersonalSettings.svg?react';
import { useProfileStore } from '@/sections/profile/use-profile-store';
import { useShallow } from 'zustand/react/shallow';

import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { Separator } from '@/components/ui/separator';
import DrawerSearchHeader from '@/components/drawer-search-header';
import MenuRow from '@/components/menu-row';

export default function AccountSettingsMenu() {
  const { toggleMenu, closeMenu, isOpen, activeMenu, openMenu } = useProfileStore(
    useShallow(state => ({
      toggleMenu: state.toggleMenu,
      isOpen: state.isOpen,
      closeMenu: state.closeMenu,
      activeMenu: state.activeMenu,
      openMenu: state.openMenu
    }))
  );
  const renderHeader = (
    <DrawerSearchHeader
      onBackClick={() => {
        closeMenu();
      }}
    >
      Account Settings
    </DrawerSearchHeader>
  );

  return (
    <Drawer
      direction="left"
      onOpenChange={() => {
        toggleMenu('account_settings');
      }}
      open={isOpen ? activeMenu === 'account_settings' : false}
    >
      <DrawerContent
        className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[28.1rem] left-0 right-auto rounded-none border-none focus-visible:outline-none"
        hideHandle
      >
        <DrawerHeader className="hidden">
          <DrawerTitle>Account Settings Menu</DrawerTitle>
          <DrawerDescription>Account Settings Menu items are here.Choose one.</DrawerDescription>
        </DrawerHeader>
        {renderHeader}
        <MenuRow
          chevron
          className="text-base"
          description="Manage your personal details and preferences"
          icon={<PersonalSettingsIcon />}
          onClick={() => {
            openMenu('personal_settings');
          }}
        >
          Personal Settings
        </MenuRow>
        <Separator className="bg-neutral-300" />
        <MenuRow
          chevron
          className="text-base"
          description="Make sure your business details are up-to-date"
          icon={<BusinessProfileIcon />}
          onClick={() => {
            openMenu('business_profile');
          }}
        >
          Business Profile
        </MenuRow>
        <Separator className="bg-neutral-300" />
        <MenuRow
          chevron
          className="text-base"
          description="Manage your &Milo email address"
          icon={<MiloIcon />}
          onClick={() => {
            openMenu('milo_settings');
          }}
        >
          You & Milo
        </MenuRow>
        <Separator className="bg-neutral-300" />
      </DrawerContent>
    </Drawer>
  );
}
