import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import { IConversation, IMessage } from './types';

export const getConversationQueries = (axiosInstance: AxiosInstance) =>
  router('conversation', {
    byId: router.query<IConversation, { id: number | string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/conversations/${variables.id}`).then(res => res.data)
    }),
    all: router.query<IConversation[], undefined, AxiosError>({
      fetcher: () => axiosInstance.get(`/conversations/all`).then(res => res.data)
    }),
    update: router.mutation<IConversation, { id: number | string; data: Partial<IConversation> }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.put<IConversation>(`/conversations/${variables.id}`, variables.data).then(res => res.data)
    }),
    create: router.mutation<
      IConversation,
      {
        data: Partial<IConversation> & {
          messages: Pick<IMessage, 'content' | 'role'>[];
        };
      },
      AxiosError
    >({
      mutationFn: async variables =>
        axiosInstance.post<IConversation>('/conversations', variables.data).then(res => res.data)
    })
  });
