import { useEffect } from 'react';
import presenter1 from '@/assets/gifs/presenter1.gif';
import AnswerSelector from '@/sections/onboarding/questionnaire/answer-selector';
import NextStepSection from '@/sections/onboarding/questionnaire/next-step-section';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { useBoolean } from '@/hooks/use-boolean';
import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';
import { useWizard } from '@/components/form-wizard';

const AnimatedButton = motion(Button);

export const businessEntityTypeVariants = [
  {
    name: 'S Corp',
    value: 'S-Corp',
    description: 'Income passes to owners for individual taxes'
  },
  {
    name: 'C Corp',
    value: 'C-Corp',
    description: 'A corporation taxed separately from its owners'
  },
  {
    name: 'Sole Proprietor',
    value: 'Sole Proprietorship',
    description: 'A business owned and run by one person'
  },
  {
    name: 'Partnership',
    value: 'Partnership',
    description: 'A business shared by two or more people'
  },
  {
    name: 'I’m Not Sure',
    value: '',
    description: 'Choose this if you need help deciding'
  }
];

export default function BusinessEntityTypeStep() {
  const { control, getValues } = useFormContext();
  const textRevealed = useBoolean(false);
  const bottomAnimationConfig = {
    animate: textRevealed.value ? { opacity: 1 } : false,
    initial: { opacity: 0 },
    transition: { duration: 0.4, ease: 'easeInOut' }
  };
  const [goToNextStep, stepNumber, activeStep] = useWizard(
    useShallow(state => [state.goToNextStep, state.stepNumber, state.activeStep])
  );
  const questionText = 'How is your business set for taxes?';
  useEffect(() => {
    Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_View`, {
      questionText: questionText
    });
  }, []);
  return (
    <>
      <div className="flex flex-1 flex-col gap-8 py-4 px-12 overflow-hidden">
        <div className="flex flex-col gap-4">
          <img className="w-[9rem] h-[4.125rem] lg:hidden" src={presenter1} />
          <div className="text-2xl">
            <MotionTextRevealContainer
              value={questionText}
              wrapperProps={{
                onAnimationComplete() {
                  textRevealed.onTrue();
                }
              }}
            />
          </div>
        </div>
        <FormField
          control={control}
          name="businessEntityType"
          render={({ field }) => (
            <FormItem className="space-y-5 overflow-y-scroll scrollbar-hidden">
              <FormControl>
                <RadioGroup className="flex flex-col" defaultValue={field.value} onValueChange={field.onChange}>
                  {businessEntityTypeVariants.map(variant => (
                    <FormItem
                      className={cn('flex items-center animate-in fade-in duration-[800ms]', {
                        hidden: !textRevealed.value
                      })}
                      key={variant.name}
                    >
                      <FormControl className="hidden">
                        <RadioGroupItem value={variant.value} />
                      </FormControl>
                      <FormLabel className="w-full">
                        <AnswerSelector
                          description={variant.description}
                          selected={field.value === variant.value}
                          variant="secondary"
                        >
                          {variant.name}
                        </AnswerSelector>
                      </FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {activeStep.canSkip ? (
          <AnimatedButton
            {...bottomAnimationConfig}
            className="absolute right-9 lg:right-14 lg:top-14 top-7 text-xs font-semibold"
            onClick={() => {
              goToNextStep();
              Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_SkipClick`);
            }}
            size="auto"
            type="button"
            variant="ghost"
          >
            Skip
          </AnimatedButton>
        ) : null}
      </div>
      <NextStepSection
        onClick={() => {
          const values = getValues();
          Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_ButtonClick`, {
            answerText: values?.businessEntityType ?? 'I’m Not Sure'
          });
        }}
        startAnimation={textRevealed.value}
      >
        Next
      </NextStepSection>
    </>
  );
}
