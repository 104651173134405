import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';

import type { ClientToServerEvents, ServerToClientEvents } from './types';

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(CHAT_URL, {
  withCredentials: true,
  autoConnect: false
});
export default socket;
