import type { IInsight, INotification } from '@repo/features/notification';
import { getNotificationQueries } from '@repo/features/notification';
import { useQueryClient } from '@tanstack/react-query';

import axiosInstance from '@/lib/axios';

const notificationQueries = getNotificationQueries(axiosInstance);

function useGetInsights() {
  const queryClient = useQueryClient();

  const { data: notifications = [] } = notificationQueries.all.useSuspenseQuery<
    (Omit<INotification, 'notificationFor'> & { notificationFor: IInsight })[]
  >({
    variables: { read: false, entityType: 'Insight', expand: ['notificationFor'] }
  });
  const { mutate: markNotificationAsRead } = notificationQueries.markAsRead.useMutation({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: notificationQueries.all.getKey() }),
        queryClient.invalidateQueries({ queryKey: notificationQueries.count.getKey() })
      ]);
    }
  });
  const insightsNotifications = notifications
    .filter(
      (notification: INotification) =>
        notification.entityType === 'Insight' &&
        notification.notificationFor &&
        typeof notification.notificationFor !== 'string'
    )
    .map((notification: INotification) => ({
      ...(notification.notificationFor as IInsight),
      notificationId: notification._id
    }));

  return {
    insights: insightsNotifications as (IInsight & { notificationId: string })[],
    markAsRead: markNotificationAsRead
  };
}

export default useGetInsights;
