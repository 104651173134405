import React from 'react';
import { Button } from '@/components/ui/button';
import { Link } from '@tanstack/react-router';

interface LinkToPulseButtonProps {
  children: React.ReactNode;
}

export default function LinkToPulseButton({ children }: LinkToPulseButtonProps) {
  return (
    <Button asChild className="text-secondary hover:text-secondary-foreground hover:bg-secondary" variant="ghost">
      <Link to="/finances">{children}</Link>
    </Button>
  );
}
