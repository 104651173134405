import type React from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface HeaderPortalProps {
  children: React.ReactNode;
}

export function HeaderPortal({ children }: HeaderPortalProps) {
  const [headerContainer, setHeaderContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const container = document.getElementById('header-content');
    if (container) {
      setHeaderContainer(container);
    }
  }, []);

  return headerContainer ? createPortal(children, headerContainer) : null;
}
