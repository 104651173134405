// src/hooks/useTransactionsQueries.ts
import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import { PaginatedResponse } from '../types';
import { GetTransactionsParams, ITransaction, UpdateTransaction } from './types';

export const getTransactionsQueries = (axiosInstance: AxiosInstance) =>
  router('transaction', {
    // Query to fetch transactions with pagination and optional description search
    search: router.query<PaginatedResponse<ITransaction>, GetTransactionsParams, AxiosError>({
      fetcher: variables => axiosInstance.get('/transactions', { params: variables }).then(res => res.data)
    }),
    byId: router.query<ITransaction, { id: string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/transactions/${variables.id}`).then(res => res.data)
    }),
    topCategories: router.query<{ name: string; count: number }[], { limit?: number }, AxiosError>({
      fetcher: variables =>
        axiosInstance.get('/transactions/top-categories', { params: variables }).then(res => res.data)
    }),
    update: router.mutation<ITransaction, { id: string; data: UpdateTransaction }, AxiosError>({
      mutationFn: variables => axiosInstance.put(`/transactions/${variables.id}`, variables.data).then(res => res.data)
    }),
    patch: router.mutation<ITransaction, { id: string; data: Partial<UpdateTransaction> }, AxiosError>({
      mutationFn: variables =>
        axiosInstance.patch(`/transactions/${variables.id}`, variables.data).then(res => res.data)
    })
  });
