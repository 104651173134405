import React, { Suspense } from 'react';
import { useAuth } from '@/auth/hooks';
import StatisticCard from '@/sections/pulse/components/statistic-card';
import type { MotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import { ErrorBoundary } from 'react-error-boundary';

import ConnectAccountButton from '@/components/connect-account-button';
import { useAppStateStore } from "@/stores/app-state";

export type MetricWrapperProps = {
  children: React.ReactNode;
  motionProps: MotionProps;
  title: string;
  showConnect?: boolean;
};

const MetricWrapper = React.forwardRef<HTMLDivElement, MetricWrapperProps>(
  ({ children, title, motionProps, showConnect = false }, ref) => {
    const { delegationWithOrg } = useAuth();
    const isAccountingEnabled = !!delegationWithOrg?.organization.accountingMetadata?.tealInstanceId;
    if (!isAccountingEnabled)
      return (
        <motion.div className="relative" ref={ref} {...motionProps}>
          <StatisticCard amount={0} title={title} />
        </motion.div>
      );
    return (
      <ErrorBoundary fallback={<StatisticCard amount="Error" title={title} />}>
        <Suspense fallback={<StatisticCard amount="" title="" />}>
          <motion.div ref={ref} {...motionProps}>
            {children}
          </motion.div>
        </Suspense>
      </ErrorBoundary>
    );
  }
);

export default MetricWrapper;
