import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';

import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent
} from '@/components/ui/chart';
import type { ChartConfig } from '@/components/ui/chart';

export type CashflowNumberChartData = {
  name: string;
  inflow: number;
  outflow: number;
};

export type CashflowNumberChartProps = {
  data: CashflowNumberChartData[];
  config?: ChartConfig;
  className?: string;
  xAxisFormatter?: (value: string) => string;
};

const defaultConfig = {
  inflow: {
    label: 'Cash In',
    color: 'hsl(var(--primary))'
  },
  outflow: {
    label: 'Cash Out',
    color: 'hsl(var(--neutral-400))'
  }
} satisfies ChartConfig;

const defaultXAxisFormatter = (value: string) => value.slice(0, 3);

export function CashflowNumberChart({
  data,
  config = defaultConfig,
  className = 'lg:w-[60%]',
  xAxisFormatter = defaultXAxisFormatter
}: CashflowNumberChartProps) {
  return (
    <ChartContainer className={className} config={config}>
      <BarChart accessibilityLayer data={data}>
        <CartesianGrid horizontal={false} vertical={false} />
        <XAxis
          axisLine={false}
          className="text-4xs"
          dataKey="name"
          tickFormatter={xAxisFormatter}
          tickLine={false}
          tickMargin={10}
        />
        <ChartTooltip content={<ChartTooltipContent />} />
        <Bar dataKey="inflow" fill="var(--color-inflow)" />
        <Bar dataKey="outflow" fill="var(--color-outflow)" />
        <ChartLegend content={<ChartLegendContent />} />
      </BarChart>
    </ChartContainer>
  );
}
