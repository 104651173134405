import type { HomeActionProperty } from '@/sections/home/home-action';
import type { AuthUserType } from '@repo/features/auth';
import type { IMessage } from '@repo/features/conversation';
import type { AxiosError } from 'axios';

import type { CalendarDateRange } from '@/components/ui/calendar';
import type { Suggestion } from '@/components/suggestion';
import {PlaidLinkOnExitMetadata, PlaidLinkOnSuccessMetadata} from "react-plaid-link";

export type Action =
  | 'HOMEPAGE_ACTION_CLICK'
  | 'ONBOARDING_USER_UPDATE'
  | 'SIGN_UP_BUTTON_CLICK'
  | 'GOOGLE_SIGN_UP'
  | 'GOOGLE_SIGN_IN'
  | 'DATABASE_SIGN_IN'
  | 'DATABASE_SIGN_UP'
  | 'PULSE_STORY_CLICK'
  | 'FORGOT_PASSWORD_CLICK'
  | 'CONNECT_BANK_CLICK'
  | 'PLAID_SUCCESS_RESPONSE'
  | 'CONNECT_BANK_SUCCESS'
  | 'CONNECT_BANK_FAILURE'
  | 'CONNECT_BANK_EXIT'
  | 'USER_SETTLED'
  | 'ON_APP_INIT'
  | 'PAGE_VIEW'
  | 'USER_SEND_MESSAGE'
  | 'SUGGESTION_CLICKED'
  | 'MESSAGE_FEEDBACK'
  | 'PULSE_FILTER_USED';

type HomePageActionClickData = {
  action: HomeActionProperty;
};
type OnboardingUserUpdate = {
  onboardingData: Record<string, unknown>;
};
type SignUpButtonClickData = undefined;
type GoogleSignUpData = undefined;

type GoogleSignInData = undefined;

type DatabaseSignInData = undefined;

type DatabaseSignUpData = undefined;

type PulseStoryClickData = {
  type: 'cash_flow' | 'bottom_line';
};

type ForgotPasswordClickData = undefined;

type ConnectBankClickData = undefined;

type PlaidSuccessResponse = PlaidLinkOnSuccessMetadata;

type ConnectBankSuccessData = undefined;

type UserSettledData = {
  user: AuthUserType;
};

type OnAppInitData = undefined;
type PageViewData = {
  pathname: string;
};

type ConnectBankFailureData = {
  error: AxiosError;
};

type UserSendMessageData = {
  message: IMessage;
};

type SuggestionClickedData = {
  suggestion: Suggestion;
};

type MessageFeedBackData = {
  message: IMessage;
  feedback: 'good' | 'bad';
};

type PulseFilterUsedData = {
  range?: CalendarDateRange;
};

type ConnectBankExitData = PlaidLinkOnExitMetadata;

// Define a mapped type for ActionData
export type ActionData = {
  HOMEPAGE_ACTION_CLICK: HomePageActionClickData;
  ONBOARDING_USER_UPDATE: OnboardingUserUpdate;
  SIGN_UP_BUTTON_CLICK: SignUpButtonClickData;
  GOOGLE_SIGN_UP: GoogleSignUpData;
  GOOGLE_SIGN_IN: GoogleSignInData;
  DATABASE_SIGN_IN: DatabaseSignInData;
  DATABASE_SIGN_UP: DatabaseSignUpData;
  PULSE_STORY_CLICK: PulseStoryClickData;
  FORGOT_PASSWORD_CLICK: ForgotPasswordClickData;
  CONNECT_BANK_CLICK: ConnectBankClickData;
  PLAID_SUCCESS_RESPONSE: PlaidSuccessResponse;
  CONNECT_BANK_SUCCESS: ConnectBankSuccessData;
  CONNECT_BANK_FAILURE: ConnectBankFailureData;
  CONNECT_BANK_EXIT: ConnectBankExitData;
  USER_SETTLED: UserSettledData;
  ON_APP_INIT: OnAppInitData;
  PAGE_VIEW: PageViewData;
  USER_SEND_MESSAGE: UserSendMessageData;
  SUGGESTION_CLICKED: SuggestionClickedData;
  MESSAGE_FEEDBACK: MessageFeedBackData;
  PULSE_FILTER_USED: PulseFilterUsedData;
};
// Helper type to determine if an action requires data
type ActionDataRequired<T extends Action> = ActionData[T] extends undefined ? false : true;

// Improved ActionObserverCallback type
type ActionObserverCallback<T extends Action> =
  ActionDataRequired<T> extends true
    ? (action: T, data: ActionData[T]) => void
    : (action: T, data?: ActionData[T]) => void;

class ActionsObserver {
  private subscribers: Map<Action, ActionObserverCallback<any>[]>;

  constructor() {
    this.subscribers = new Map();
  }

  subscribe<T extends Action>(action: T, callback: ActionObserverCallback<T>): () => void {
    if (!this.subscribers.has(action)) {
      this.subscribers.set(action, []);
    }
    this.subscribers.get(action)!.push(callback);
    return () => {
      const callbacks = this.subscribers.get(action)!;
      const index = callbacks.indexOf(callback);
      if (index > -1) callbacks.splice(index, 1);
    };
  }

  unsubscribe(action: Action): void {
    this.subscribers.delete(action);
  }

  notify<T extends Action>(
    action: T,
    ...args: ActionDataRequired<T> extends true ? [data: Exclude<ActionData[T], undefined>] : [data?: ActionData[T]]
  ): void {
    if (this.subscribers.has(action)) {
      const callbacks = this.subscribers.get(action)!;
      callbacks.forEach(callback => {
        callback(action, args[0]);
      });
    }
  }
}

const actionsObserver = new ActionsObserver();
export default actionsObserver;
