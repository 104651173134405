import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import JumpingDog from '@/assets/lottie/jumping-dog';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { AnimatePresence, motion } from 'framer-motion';

import useGetInsights from '@/hooks/use-get-insights';
import { Button } from '@/components/ui/button';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';

function Wrapper({ children }: { children: ReactNode }) {
  return (
    <AnimatePresence mode="sync">
      <motion.div
        animate="animateState"
        className="flex flex-col px-12 gap-4 lg:gap-12 lg:px-0"
        exit="exitState"
        initial="initialState"
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 15,
          mass: 1
        }}
        variants={{
          initialState: {
            x: '100vw'
          },
          animateState: {
            x: 0
          },
          exitState: {
            x: '-100vw',
            zIndex: -10
          }
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export default function InsightsVariant() {
  const { insights, markAsRead } = useGetInsights();
  const [currentInsightIndex, setCurrentInsightIndex] = useState(0);
  console.log('insights', insights);
  useEffect(() => {
    Amplitude.track(`HomeScreenInsightsView`, {
      insights: insights
    });
  }, []);

  const handleNextInsight = () => {
    setCurrentInsightIndex(prevIndex => (prevIndex + 1) % insights.length);
  };

  const insight = insights[currentInsightIndex];
  const onClickInsight = async () => {
    markAsRead({ id: insight.notificationId });
    handleNextInsight();
  };

  return (
    <Wrapper key={currentInsightIndex}>
      <div className="rotate-y-180 w-20 lg:w-32">
        <JumpingDog
          style={{
            transform: 'rotateY(180deg)'
          }}
        />
      </div>

      <div className="text-[1.75rem] leading-[120%] lg:text-3xl lg:leading-[110%] text-white">
        <div className="mt-4">
          <div>
            <MotionTextRevealContainer transition={{ duration: 0.5 }} value={insight?.title} />
          </div>
        </div>

        <div className="text-4xs text-white opacity-75 mt-2 lg:mt-6">{`Potential savings: ${insight?.potentialSavings}`}</div>
      </div>
      <motion.div
        animate={{ opacity: 1 }}
        className="flex flex-1 flex-col gap-5 lg:gap-6 lg:flex-row-reverse lg:px-0"
        initial={{ opacity: 0 }}
        transition={{ duration: 2, ease: 'easeInOut' }}
      >
        <Button
          className="w-full"
          onClick={async () => {
            await onClickInsight();
            Amplitude.track(`Insight_CTA`, {
              insight,
              cta: insight?.callToAction
            });
          }}
          size="lg"
          variant="secondary"
        >
          {insight?.callToAction}
        </Button>
        <Button
          className="w-full"
          onClick={async () => {
            await onClickInsight();
            Amplitude.track(`Insight_CTA`, {
              insight,
              cta: 'dismiss'
            });
          }}
          size="lg"
          variant="outline"
        >
          Dismiss
        </Button>
      </motion.div>
    </Wrapper>
  );
}
