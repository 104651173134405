import sniffingDog from '@/assets/gifs/sniffing1.gif';
import AppHeader from '@/layouts/common/app-header';
import { HeaderPortal } from '@/layouts/common/header-portal';
import LibraryNavBar from '@/layouts/common/library-nav-bar';
import MainLayout from '@/layouts/main-layout';
import TransactionFilters from '@/sections/library/transaction/transaction-filters';
import { createFileRoute, Outlet, useMatch } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/library')({
  component: () => (
    <MainLayout>
      <LibraryPage />
    </MainLayout>
  )
});

function LibraryPage() {
  const match = useMatch({ from: '/_guarded/library/transactions', shouldThrow: false });
  return (
    <>
      <HeaderPortal>
        <LibraryNavBar />
        {match !== undefined && <TransactionFilters />}
      </HeaderPortal>

      <Outlet />
      {/*<div className="flex flex-col justify-center items-center w-full h-full">*/}
      {/*  <div>*/}
      {/*    <img alt="" className="h-28" src={sniffingDog} />*/}
      {/*  </div>*/}
      {/*  <div className="text-base font-bold text-black mt-7">Coming Up...</div>*/}
      {/*  <div className="text-base font-medium text-black mt-7 text-center w-[300px]">*/}
      {/*    &milo organizes your financial paperwork in one place - customers, invoices, proposals, contracts, bills,*/}
      {/*    receipts, contractors, and more.*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
}
