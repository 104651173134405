import type { Suggestion } from '@/components/suggestion';

export const getPulseSuggestions = ({
  isActive,
  accountsConnected
}: {
  isActive: boolean;
  accountsConnected: boolean;
}): (Suggestion & { show: boolean; firstMsg: string })[] => [
  {
    name: 'Business setup',
    category: 'setup',
    description: 'Handles your entire formation process',
    firstMsg: 'Please help me with setup.',
    show: true
  },
  {
    name: 'Customer Invoices',
    category: 'manage_finances',
    description: 'Creates, tracks and collects invoices',
    firstMsg: 'Please create an invoice for me.',
    show: true
  },
  {
    name: 'Spend Management',
    category: 'manage_finances',
    description: 'Collects, organizes, records and tracks receipts and bills',
    firstMsg: 'Please help me manage my spending.',
    show: true
  },
  {
    name: 'Cash Flow Analysis',
    category: 'know_your_finances',
    description: 'Cash position across accounts, insights, alerts, forecasting',
    firstMsg: 'Can you analyze my cash flow?',
    show: isActive && accountsConnected
  },
  {
    name: 'Milo product love',
    category: 'customer_care',
    description: 'Provides product support, collects product feedback',
    firstMsg: 'I need help with the Milo product.',
    show: true
  },
  {
    name: 'Onboarding assistant',
    category: 'customer_care',
    description: 'Gets you started with milo, guides, answers questions',
    firstMsg: 'Please assist me with onboarding.',
    show: !isActive
  },
  {
    name: 'Spend tracking',
    category: 'know_your_finances',
    description: 'Tracks spend across accounts, insights, alerts, forecasting',
    firstMsg: 'Please track my spending for me.',
    show: isActive && accountsConnected
  },
  {
    name: 'Profitability analysis',
    category: 'know_your_finances',
    description: 'Tracks revenue & profitability, insights, alerts, forecasting',
    firstMsg: 'Can you perform a profitability analysis for me?',
    show: isActive && accountsConnected
  }
];

export const getCashFlowDrawerSuggestions = ({
  isActive,
  accountsConnected
}: {
  isActive: boolean;
  accountsConnected: boolean;
}): (Suggestion & { show: boolean; firstMsg: string })[] => [
  {
    name: 'Business setup',
    category: 'setup',
    description: "Why I don't see any numbers?",
    firstMsg: "Why don't I see any numbers?",
    show: !accountsConnected
  },
  {
    name: 'Business setup',
    category: 'setup',
    description: 'Why to connect account?',
    firstMsg: 'Can you explain why I need to connect my account?',
    show: !accountsConnected
  },
  {
    name: 'Business setup',
    category: 'setup',
    description: 'Is it safe and secure?',
    firstMsg: 'Is it safe and secure to connect my account?',
    show: !accountsConnected
  },
  {
    name: 'Cash Flow Analysis',
    category: 'know_your_finances',
    description: 'Soft cash flow forecast',
    firstMsg: 'Can you give me a soft cash flow forecast?',
    show: isActive && accountsConnected
  },
  {
    name: 'Compare financial state',
    category: 'know_your_finances',
    description: 'Compare previous months',
    firstMsg: 'Can you compare my financial state with previous months?',
    show: isActive && accountsConnected
  },
  {
    name: 'What if',
    category: 'know_your_finances',
    description: 'What if money up/down',
    firstMsg: 'What would happen if my income goes up or down?',
    show: isActive && accountsConnected
  }
];
