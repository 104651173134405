'use client';

import * as React from 'react';
import { useState } from 'react';
import CalendarIcon from '@/assets/icons/calendar-icon';
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subMonths,
  subQuarters,
  subYears
} from 'date-fns';
import type { DateRange, DayPickerRangeProps } from 'react-day-picker';

import { cn } from '@/lib/utils';

import { Button } from './button';
import { Calendar } from './calendar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './dropdown-menu';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export type DateRangePickerProps = Omit<DayPickerRangeProps, 'mode' | 'onSelect'> & {
  onSelect: (dateRange?: DateRange) => void;
};
const now = new Date();

type Preset = 'this_month' | 'last_month' | 'this_quarter' | 'last_quarter' | 'this_year' | 'last_year';

const presetsMap: Record<Preset, DateRange> = {
  this_month: {
    from: startOfMonth(now),
    to: now
  },
  last_month: {
    from: startOfMonth(subMonths(now, 1)),
    to: endOfMonth(subMonths(now, 1))
  },
  this_quarter: {
    from: startOfQuarter(now),
    to: now
  },
  last_quarter: {
    from: startOfQuarter(subQuarters(now, 1)),
    to: endOfQuarter(subQuarters(now, 1))
  },
  this_year: {
    from: startOfYear(now),
    to: now
  },
  last_year: {
    from: startOfYear(subYears(now, 1)),
    to: endOfYear(subYears(now, 1))
  }
};

export function DatePickerWithRange({ selected, onSelect, className, ...other }: DateRangePickerProps) {
  const [currentMonth, setCurrentMonth] = useState<Date | undefined>(new Date());
  const onPresetSelect = (preset: Preset) => {
    const range = presetsMap[preset];
    onSelect(range);
    setCurrentMonth(range.to);
  };
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            className={cn(
              'w-full justify-start text-left rounded-md py-5 px-4 h-18 text-sm font-semibold bg-neutral-200 text-black hover:bg-primary-200',
              !selected && 'text-muted-foreground'
            )}
            id="date"
          >
            <CalendarIcon className="mr-2 size-6" />
            {selected?.from ? (
              selected.to ? (
                <>
                  {format(selected.from, 'LLL dd, y')} - {format(selected.to, 'LLL dd, y')}
                </>
              ) : (
                format(selected.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-auto p-0">
          <DropdownMenu>
            <div className="flex justify-center p-3">
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Presets</Button>
              </DropdownMenuTrigger>
            </div>
            <DropdownMenuContent className="w-56">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onClick={() => {
                    onPresetSelect('this_month');
                  }}
                >
                  This month
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    onPresetSelect('last_month');
                  }}
                >
                  Last month
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    onPresetSelect('this_quarter');
                  }}
                >
                  This quarter
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    onPresetSelect('last_quarter');
                  }}
                >
                  Last quarter
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    onPresetSelect('this_year');
                  }}
                >
                  This year
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    onPresetSelect('last_year');
                  }}
                >
                  Last year
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <Calendar
            defaultMonth={selected?.from}
            initialFocus
            mode="range"
            month={currentMonth}
            numberOfMonths={2}
            onMonthChange={setCurrentMonth}
            onSelect={onSelect}
            selected={selected}
            {...other}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
