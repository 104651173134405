import { memo } from 'react';
import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function DotsIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 3 14"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M1.75 3.25C2.44036 3.25 3 2.69036 3 2C3 1.30964 2.44036 0.75 1.75 0.75C1.05964 0.75 0.5 1.30964 0.5 2C0.5 2.69036 1.05964 3.25 1.75 3.25ZM1.75 8.25C2.44036 8.25 3 7.69036 3 7C3 6.30964 2.44036 5.75 1.75 5.75C1.05964 5.75 0.5 6.30964 0.5 7C0.5 7.69036 1.05964 8.25 1.75 8.25ZM3 12C3 12.6904 2.44036 13.25 1.75 13.25C1.05964 13.25 0.5 12.6904 0.5 12C0.5 11.3096 1.05964 10.75 1.75 10.75C2.44036 10.75 3 11.3096 3 12Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default memo(DotsIcon);
