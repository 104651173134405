import sniffingDog from '@/assets/gifs/sniffing1.gif';
import MusicListeningDog from '@/assets/lottie/music-listening-dog';
import ActivityCard from '@/sections/pulse/activity-card';
import { getConversationQueries } from '@repo/features/conversation';

import axiosInstance from '@/lib/axios.ts';
import { Drawer, DrawerContent, DrawerHeader } from '@/components/ui/drawer';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import DrawerSearchHeader from '@/components/drawer-search-header';

export type UpdatesSheetProps = {
  isOpen: boolean;
  onOpenChange?: (open: boolean) => void;
  onClose?: () => void;
};
const conversationQueries = getConversationQueries(axiosInstance);
function ListItem() {
  return <div />;
}
export default function ActivityDrawer({ isOpen, onOpenChange, onClose }: UpdatesSheetProps) {
  const { data: conversations = [] } = conversationQueries.all.useQuery({});
  const renderActivities = (
    <div className="flex flex-1 flex-col h-max divide-y overflow-y-scroll">
      {conversations
        .filter(c => c.status !== 'hidden')
        .slice(0, 6)
        .map(conversation => (
          <ActivityCard conversation={conversation} key={conversation._id} />
        ))}
    </div>
  );

  const renderEmptyScreen = (
    <div className="h-full flex flex-1 flex-col items-center justify-center">
      <img alt="" className="w-52" src={sniffingDog} />
      <div className="text-md font-bold mt-7">No Activity With Milo... Yet!</div>
      <div className="text-sm text-neutral-500 mt-2">Your activity history will appear here</div>
    </div>
  );

  return (
    <Drawer direction="right" onClose={onClose} onOpenChange={onOpenChange} open={isOpen}>
      <DrawerContent
        className="lg:rounded-tl-lg lg:rounded-bl-lg h-full w-full sm:w-[31.25rem] rounded-none right-0 left-auto border-none focus-visible:outline-none"
        hideHandle
      >
        <DrawerSearchHeader
          onBackClick={() => {
            onOpenChange && onOpenChange(false);
          }}
        />
        <div className="flex flex-col gap-5">
          <div className="text-base font-bold px-10">Activities</div>
          <Tabs className="flex flex-col gap-5" defaultValue="in_progress">
            <TabsList className="justify-start px-10">
              <TabsTrigger value="in_progress">In Progress</TabsTrigger>
              <TabsTrigger value="completed">Completed</TabsTrigger>
            </TabsList>
            <TabsContent value="in_progress">
              {conversations.length > 0 ? renderActivities : renderEmptyScreen}
            </TabsContent>
          </Tabs>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
