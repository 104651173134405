import BackArrowIcon from '@/assets/icons/back-arrow-icon';
import CloseIcon from '@/assets/icons/close-icon';
import SearchIcon from '@/assets/icons/search-icon';

import useResponsiveness from '@/hooks/use-responsiveness';
import { Button } from '@/components/ui/button';
import { DrawerHeader } from '@/components/ui/drawer';

export type DrawerHeaderProps = {
  children?: React.ReactNode;
  onBackClick?: () => void;
  onSearchClick?: () => void;
};

export default function DrawerSearchHeader({ children, onBackClick }: DrawerHeaderProps) {
  const responsiveness = useResponsiveness();
  return (
    <DrawerHeader className="px-7 flex justify-between items-center h-21 lg:py-5 lg:px-10">
      <Button onClick={onBackClick} size="auto" variant="icon">
        {responsiveness === 'xs' || responsiveness === 'sm' ? (
          <BackArrowIcon className="size-5" />
        ) : (
          <CloseIcon className="size-3" />
        )}
      </Button>
      <div className="text-sm font-semibold">{children}</div>
      <SearchIcon className="size-5 invisible" />
    </DrawerHeader>
  );
}
