import React from 'react';
import ApexCharts from 'react-apexcharts';

import useResponsiveness from '@/hooks/use-responsiveness';

export type ChartProps = {
  data: any;
  height?: number;
  width?: number;
};

function PieChart({ width = 400, height = 350, data }: ChartProps) {
  const responsiveness = useResponsiveness();
  if (responsiveness === 'xs') {
    width = 300;
    height = 250;
  }
  const options: ApexCharts.ApexOptions = {
    colors: ['#000AEE', '#9A9EFF', '#5A6ACF', '#5A6AE1'],
    ...data.options,
    ...(responsiveness === 'xs' ? {} : {})
  };
  return <ApexCharts height={height} options={options} series={data.series} type="donut" width={width} />;
}

export default React.memo(PieChart);
