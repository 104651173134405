import { PATH_AFTER_LOGIN } from '@/config-global.ts';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_guested')({
  beforeLoad: ({ context }) => {
    const isAuthorized = Boolean(context.auth.accessToken);
    if (isAuthorized) {
      // throw redirect({ to: '/onboarding', resetScroll: false });
      throw redirect({ to: PATH_AFTER_LOGIN, resetScroll: false });
    }
  },
  component: () => <Outlet />
});
