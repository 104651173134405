declare global {
  interface Window {
    _hsq: any[];
  }
}

class HubSpotManager {
  private static instance: HubSpotManager; // Singleton instance
  private initialized: boolean;
  private hsLoaded: boolean;
  private debug: boolean;
  private portalId: string | null;

  // Private constructor to restrict instantiation from outside
  private constructor() {
    this.portalId = null; // `portalId` will be set during the init call
    this.debug = false; // `debug` will be set during the init call
    this.initialized = false;
    this.hsLoaded = false;
  }

  // Method to get the singleton instance
  public static getInstance(): HubSpotManager {
    if (!HubSpotManager.instance) {
      HubSpotManager.instance = new HubSpotManager();
    }
    return HubSpotManager.instance;
  }

  // Helper function to log warnings
  private warn(message: string) {
    console.warn(message);
  }

  // Helper function to log debug messages
  private log(message: string, data?: any) {
    if (this.debug) {
      console.log(message, data);
    }
  }

  // Function to verify if the HubSpot script is initialized
  private verifyInit(): boolean {
    if (!this.initialized) {
      this.warn('HubSpot tracking code not initialized. Call init() with the required portalId.');
      return false;
    }
    return true;
  }

  // Function to load the HubSpot script dynamically
  private loadHubSpotScript() {
    if (!this.hsLoaded) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = `//js-eu1.hs-scripts.com/${this.portalId}.js`;
      document.body.appendChild(script);
      this.hsLoaded = true; // Prevent multiple script loading
      this.initialized = true; // Mark as initialized
    }
  }

  // Function to get the HubSpot `hutk` cookie
  public getHubspotUTK(): string | null {
    const matches = document.cookie.match(/(?:^|; )hubspotutk=([^;]*)/);
    return matches ? matches[1] : null;
  }

  // Initialize the HubSpot tracking script with portalId and debug flag
  public init(portalId: string, prod = false) {
    if (!portalId) {
      this.warn('A valid portalId is required to initialize HubSpotManager.');
      return;
    }
    this.portalId = portalId;
    this.debug = !prod;
    window._hsq = window._hsq || [];

    if (this.debug) {
      return;
    }
    this.loadHubSpotScript();

    if (!this.initialized) {
      this.warn('HubSpot tracking script is not loaded yet.');
      return;
    }

    this.log('HubSpot tracking initialized with portalId:', portalId);
  }

  // Function to track custom events with HubSpot

  // WE CAN'T USE THIS NOW, WE DON"T HAVE ENTERPRISE ACCOUNT ON HUBSOUT
  public trackEvent(eventId: string, eventData: Record<string, any> = {}) {
    if (!this.verifyInit()) return;

    if (!eventId) {
      this.warn('A valid event ID is required to track the event.');
      return;
    }

    // Ensure HubSpot script is fully loaded
    window._hsq = window._hsq || [];
    window._hsq.push([
      'trackEvent',
      {
        id: eventId,
        value: eventData
      }
    ]);

    this.log('Tracked custom event:', { eventId, eventData });
  }

  // Function to identify users in HubSpot
  public identifyUser(userData: { email: string }) {
    if (!this.verifyInit()) return;

    if (!userData.email) {
      this.warn('A valid email is required to identify the user.');
      return;
    }

    // Ensure HubSpot script is fully loaded
    window._hsq.push([
      'identify',
      {
        email: userData.email
      }
    ]);
    // needed to identify.
    window._hsq.push(['trackPageView']);
    this.log('Identified user:', userData);
  }

  public pageView(pageData: { pathname: string }) {
    if (!this.verifyInit()) return;

    window._hsq.push(['setPath', pageData.pathname]);
    window._hsq.push(['trackPageView']);
  }

  // Form submission function to submit data to HubSpot
  public async submitForm(
    formId: string,
    formData: {
      email: string;
      utm_source: string;
      utm_medium: string;
      utm_campaign: string;
      utm_content: string;
      utm_term: string;
      firstname: string;
    }
  ) {
    if (!this.verifyInit()) return;
    if (!formId) {
      this.warn('A valid formId is required to submit the form.');
      return;
    }

    const hutk = this.getHubspotUTK(); // Get the HubSpot `hutk` cookie
    const submissionData = {
      submittedAt: Date.now(),
      fields: [
        { name: 'email', value: formData.email },
        { name: 'utm_source', value: formData.utm_source },
        { name: 'utm_medium', value: formData.utm_medium },
        { name: 'utm_campaign', value: formData.utm_campaign },
        { name: 'utm_content', value: formData.utm_content },
        { name: 'utm_term', value: formData.utm_term },
        { name: 'firstname', value: formData.firstname }
      ],
      context: {
        hutk: hutk || '', // Pass the `hutk` value or empty string if not available
        pageUri: window.location.href,
        pageName: document.title
      }
    };

    try {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${formId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submissionData)
        }
      );

      const data = await response.json();
      this.log('Form submitted successfully:', data);
    } catch (error) {
      console.error('Form submission failed:', error);
    }
  }
}

// Export the singleton instance
const hubSpotManager = HubSpotManager.getInstance();

export default hubSpotManager;
