import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import qs from 'qs';

let baseInstance: AxiosInstance | null;
let baseURL: string | undefined;
export const setTealInstance = (newInstance: AxiosInstance) => {
  baseInstance = newInstance;
}

export const setTealBaseURL = (url: string) => {
  baseURL = url;
}

export const createTealInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  if(!baseInstance) throw new Error('No base instance provided');
  const source = axios.CancelToken.source();
  const promise = baseInstance({
    ...config,
    baseURL,
    cancelToken: source.token,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    }
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

