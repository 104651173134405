import * as React from 'react';
import { useAuth } from '@/auth/hooks';
import BankAccount from '@/icons/BankAccount.svg?react';
import CreditCard from '@/icons/CreditCard.svg?react';
import PaymentProcessors from '@/icons/PaymentProcessors.svg?react';
import Payrolls from '@/icons/Payrolls.svg?react';
import { useProfileStore } from '@/sections/profile/use-profile-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { getAuthQueries } from '@repo/features/auth';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { authAxiosInstance } from '@/lib/axios';
import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import DrawerSearchHeader from '@/components/drawer-search-header';
import MenuRow from '@/components/menu-row';

const formSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().min(1).email('Email is incorrect')
  // password: z.string().min(6, {
  //   message: 'Password must be at least 6 characters.'
  // })
});

type FormFields = z.infer<typeof formSchema>;

const authQueries = getAuthQueries(authAxiosInstance);

export default function PersonalSettingsDrawer() {
  const { mutateAsync: updateUser } = authQueries.updateUser.useMutation();
  const { toggleMenu, closeMenu, isOpen, activeMenu, openMenu } = useProfileStore(
    useShallow(state => ({
      toggleMenu: state.toggleMenu,
      isOpen: state.isOpen,
      closeMenu: state.closeMenu,
      activeMenu: state.activeMenu,
      openMenu: state.openMenu
    }))
  );

  const { user, selectedOrgId } = useAuth();

  const onSubmit = async (values: FormFields) => {
    try {
      await updateUser({
        id: user!._id,
        data: values
      });
      // setErrorMsg('');
      // await signUp({ data: values, query: qs.parse(window.location.search || '', { ignoreQueryPrefix: true }) });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // setErrorMsg((e.response as any).data.message);
      }
    }
  };

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email
      // password: ''
    }
  });
  const renderHeader = (
    <DrawerSearchHeader
      onBackClick={() => {
        closeMenu();
      }}
    />
  );

  return (
    <Drawer
      direction="left"
      onOpenChange={() => {
        toggleMenu('personal_settings');
      }}
      open={isOpen ? activeMenu === 'personal_settings' : false}
    >
      <DrawerContent
        className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[28.1rem] left-0 right-auto rounded-none border-none focus-visible:outline-none"
        hideHandle
      >
        <DrawerHeader className="hidden">
          <DrawerTitle>Personal Settings</DrawerTitle>
          <DrawerDescription>Manage your personal details and preferences</DrawerDescription>
        </DrawerHeader>
        {renderHeader}
        <Form {...form}>
          <form className="flex flex-col flex-1 gap-10 px-10">
            <div className="text-lg font-bold">Personal Settings</div>
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input disabled size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/*<FormField*/}
            {/*  control={form.control}*/}
            {/*  name="password"*/}
            {/*  render={({ field }) => (*/}
            {/*    <FormItem className="w-full">*/}
            {/*      <FormLabel>Password</FormLabel>*/}
            {/*      <FormControl>*/}
            {/*        <Input placeholder="********" size="md" type="password" {...field} />*/}
            {/*      </FormControl>*/}
            {/*      <FormMessage />*/}
            {/*    </FormItem>*/}
            {/*  )}*/}
            {/*/>*/}
            <div className="flex-1" />
          </form>
        </Form>
        <DrawerFooter className="p-10">
          <Button
            className="w-full"
            disabled={!form.formState.isValid}
            onClick={() => form.handleSubmit(onSubmit)()}
            size="md"
          >
            Save Changes
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
