import React from 'react';
import DotsIcon from '@/assets/icons/dots-icon';
import FinancesIcon from '@/assets/icons/navigation/finances-icon';
import HomeIcon from '@/assets/icons/navigation/home-icon';
import LibraryIcon from '@/assets/icons/navigation/library-icon';
import TaxesIcon from '@/assets/icons/navigation/taxes-icon';
import { useAuth } from '@/auth/hooks';
import ProfileMenu from '@/sections/profile/profile-menu';
import { Link, linkOptions } from '@tanstack/react-router';
import { ChevronRight } from 'lucide-react';

import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem
} from '@/components/ui/sidebar';
import Logo from '@/components/logo';

const navConfig = [
  linkOptions({
    label: 'Home',
    Icon: HomeIcon,
    to: '/'
  }),
  linkOptions({
    label: 'Finances',
    Icon: FinancesIcon,
    to: '/finances'
  }),
  linkOptions({
    label: 'Taxes',
    Icon: TaxesIcon,
    to: '/taxes'
  }),
  linkOptions({
    label: 'Library',
    Icon: LibraryIcon,
    to: '/library',
    items: [
      linkOptions({
        label: 'Transactions',
        to: '/library/transactions'
      }),
      linkOptions({
        label: 'Invoices',
        to: '/library/invoices'
      }),
      linkOptions({
        label: 'Customers',
        to: '/library/customers'
      }),
      linkOptions({
        label: 'Bills & Receipts',
        to: '/library/bills'
      }),
      linkOptions({
        label: 'Vendors',
        to: '/library/vendors'
      })
    ]
  })
];

export function AppSidebar() {
  const { delegationWithOrg } = useAuth();
  const { isBelowLg } = useBreakpoint('lg');
  return (
    <Sidebar>
      <SidebarHeader className="h-34 flex justify-center items-center border-b border-b-neutral-300 p-0">
        <Logo beta className="mr-[17px]" />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {navConfig.map(item => (
                <React.Fragment key={item.label}>
                  {'items' in item ? (
                    <Collapsible asChild className="group/collapsible" defaultOpen key={item.label}>
                      <SidebarMenuItem>
                        <CollapsibleTrigger asChild>
                          <SidebarMenuButton className="py-9 px-8" tooltip={item.label}>
                            {item.Icon ? <item.Icon /> : null}
                            <span className="text-sm font-semibold">{item.label}</span>
                            <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                          </SidebarMenuButton>
                        </CollapsibleTrigger>
                        <CollapsibleContent>
                          <SidebarMenuSub>
                            {item.items?.map(subItem => (
                              <SidebarMenuSubItem key={subItem.label}>
                                <Link className="space-x-4" to={subItem.to}>
                                  {({ isActive }) => (
                                    <SidebarMenuSubButton asChild className="py-9 px-8" isActive={isActive}>
                                      <span>{subItem.label}</span>
                                    </SidebarMenuSubButton>
                                  )}
                                </Link>
                              </SidebarMenuSubItem>
                            ))}
                          </SidebarMenuSub>
                        </CollapsibleContent>
                      </SidebarMenuItem>
                    </Collapsible>
                  ) : (
                    <SidebarMenuItem>
                      <Link className="space-x-4" to={item.to}>
                        {({ isActive }: { isActive: boolean }) => (
                          <SidebarMenuButton className="py-9 px-8" isActive={isActive}>
                            <item.Icon />
                            <span className="text-sm font-semibold">{item.label}</span>
                          </SidebarMenuButton>
                        )}
                      </Link>
                    </SidebarMenuItem>
                  )}
                </React.Fragment>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="p-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Avatar className="size-12">
              {/*<AvatarImage src={user?.photoURL} alt={user?.displayName} />*/}
              <AvatarFallback className="bg-primary-300 text-black font-semibold text-3xs">
                {delegationWithOrg?.organization.name.charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <div className="hidden lg:block ml-2 font-archivo text-[15px] tracking-tight text-black">
              {delegationWithOrg?.organization.name}
            </div>
          </div>
          {!isBelowLg && (
            <ProfileMenu>
              <Button size="auto" variant="icon">
                <DotsIcon />
              </Button>
            </ProfileMenu>
          )}
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
