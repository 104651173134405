import { create } from 'zustand';

export interface ConversationsStateState {
  conversationsInProgress: string[];
}

export interface ConversationsStateActions {
  reset: () => void;
  add: (conversationId: string) => void;
  remove: (conversationId: string) => void;
}

export type ConversationsStateStore = ConversationsStateState & ConversationsStateActions;

const initialState: ConversationsStateState = {
  conversationsInProgress: []
};

export const useConversationsStateStore = create<ConversationsStateStore>()(set => ({
  ...initialState,
  reset: () => {
    set(initialState);
  },
  add: (conversationId: string) => {
    set(state => ({ conversationsInProgress: [...new Set([...state.conversationsInProgress, conversationId])] }));
  },
  remove: (conversationId: string) => {
    set(state => ({ conversationsInProgress: state.conversationsInProgress.filter(id => id !== conversationId) }));
  }
}));
