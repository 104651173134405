import { useAuthStore } from '@/auth/store.ts';
import { setTealBaseURL, setTealInstance } from '@repo/teal/axiosInstance';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

// ----------------------------------------------------------------------
interface FailedRequests {
  resolve: (value: AxiosResponse) => void;
  reject: (value: AxiosError) => void;
  config: AxiosRequestConfig;
  error: AxiosError;
}

const createAxios = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    withCredentials: true // Add this to send credentials (cookies)
  });

  instance.interceptors.request.use(request => {
    const accessToken = `Bearer ${useAuthStore.getState().accessToken}`;
    const organization = `${useAuthStore.getState().selectedOrgId}`;
    // if (!request.url?.includes('andmilo-dev-application-bucket')) {
    //   request.headers['bypass-tunnel-reminder'] = 'yes';
    // }
    if (request.url?.includes('googleapis.com') || request.url?.includes('storage.googleapis.com')) {
      request.withCredentials = false;
    }

    if (accessToken) {
      request.headers.Authorization = accessToken;
    }

    if (organization) {
      request.headers['milo-organization-id'] = organization;
    }

    return request;
  });

  let failedRequests: FailedRequests[] = [];
  let isTokenRefreshing = false;

  instance.interceptors.response.use(
    response => response,
    async (error: AxiosError) => {
      const status = error.response?.status;
      const originalRequestConfig = error.config!;

      if (status !== 401) {
        return Promise.reject(error);
      }

      if (isTokenRefreshing) {
        return new Promise((resolve, reject) => {
          failedRequests.push({
            resolve,
            reject,
            config: originalRequestConfig,
            error
          });
        });
      }

      isTokenRefreshing = true;

      try {
        const response = await axiosInstance.post(`${AUTH_URL}/auth/refresh`, {
          refreshToken: useAuthStore.getState().refreshToken,
          accessToken: useAuthStore.getState().accessToken
        });
        const { accessToken = null, refreshToken = null } = response.data;

        if (!accessToken) {
          throw new Error('Something went wrong while refreshing your access token');
        }

        useAuthStore.getState().setAccessToken(accessToken);
        useAuthStore.getState().setRefreshToken(refreshToken);

        failedRequests.forEach(({ resolve, reject, config }) => {
          axiosInstance(config)
            .then(r => {
              resolve(r);
            })
            .catch(err => {
              reject(err);
            });
        });
      } catch (_error: unknown) {
        console.error(_error);
        failedRequests.forEach(({ reject, error: err }) => {
          reject(err);
        });
        useAuthStore.getState().setRefreshToken(null);
        useAuthStore.getState().setAccessToken(null);
        window.location.pathname = '/logout';
        return await Promise.reject(error);
      } finally {
        failedRequests = [];
        isTokenRefreshing = false;
      }

      return axiosInstance(originalRequestConfig);
    }
  );

  return instance;
};

const axiosInstance = createAxios(API_URL);

setTealInstance(axiosInstance);
setTealBaseURL(`${axiosInstance.defaults.baseURL}/accounting/proxy`);

export const authAxiosInstance = createAxios(AUTH_URL);
export default axiosInstance;
