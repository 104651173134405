import AuthLayout from '@/layouts/auth-layout';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_guested/auth/_auth')({
  validateSearch: (search: Record<string, unknown>): { showSplash?: boolean } => {
    return {
      showSplash: (search.showSplash as boolean)
    };
  },
  component: () => {
    const { showSplash } = Route.useSearch();
    return (
      <AuthLayout showSplash={!!showSplash}>
        <Outlet />
      </AuthLayout>
    );
  }
});
