// src/hooks/useVendorsQueries.ts
import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import { GetVendorsResponse, IVendor, IVendorContactInfo } from './types';

export const getVendorQueries = (axiosInstance: AxiosInstance) =>
  router('vendor', {
    // Query to fetch all vendors
    all: router.query<GetVendorsResponse, { limit?: number }, AxiosError>({
      fetcher: variables =>
        axiosInstance
          .get('/vendors', {
            params: variables
          })
          .then(res => res.data)
    }),
    // Query to fetch a single vendor by ID
    byId: router.query<IVendor, { id: string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/vendors/${variables.id}`).then(res => res.data)
    }),
    // Mutation to create a new vendor
    create: router.mutation<IVendor, { data: Partial<IVendor> }, AxiosError>({
      mutationFn: async variables => axiosInstance.post('/vendors', variables.data).then(res => res.data)
    }),
    // Mutation to update a vendor by ID
    update: router.mutation<IVendor, { id: string; data: Partial<IVendor> }, AxiosError>({
      mutationFn: async variables => axiosInstance.put(`/vendors/${variables.id}`, variables.data).then(res => res.data)
    }),
    // Mutation to delete a vendor by ID
    delete: router.mutation<void, { id: string }, AxiosError>({
      mutationFn: async variables => axiosInstance.delete(`/vendors/${variables.id}`).then(res => res.data)
    }),
    // Mutation to add a contact to a vendor
    addContact: router.mutation<IVendor, { id: string; data: IVendorContactInfo }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.post(`/vendors/${variables.id}/contacts`, variables.data).then(res => res.data)
    }),
    // Mutation to update a vendor's contact
    updateContact: router.mutation<IVendor, { id: string; contactId: string; data: IVendorContactInfo }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance
          .put(`/vendors/${variables.id}/contacts/${variables.contactId}`, variables.data)
          .then(res => res.data)
    }),
    // Mutation to delete a contact from a vendor
    deleteContact: router.mutation<void, { id: string; contactId: string }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.delete(`/vendors/${variables.id}/contacts/${variables.contactId}`).then(res => res.data)
    })
  });
