import { AxiosError, AxiosInstance } from 'axios';
import qs from 'qs';
import { router } from 'react-query-kit';

import { EntityType, INotification, NotificationType } from './types';

export const getNotificationQueries = (axiosInstance: AxiosInstance) =>
  router('notification', {
    // Query to fetch a notification by ID
    byId: router.query<INotification, { id: number | string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/notifications/${variables.id}`).then(res => res.data)
    }),

    // Query to fetch all notifications with optional filters (read, notificationType)
    all: router.query<
      INotification[],
      {
        read?: boolean;
        notificationType?: NotificationType;
        entityType?: EntityType;
        expand?: string[];
      },
      AxiosError
    >({
      fetcher: variables => axiosInstance.get(`/notifications/all?${qs.stringify(variables)}`).then(res => res.data)
    }),
    count: router.query<
      { count: number },
      {
        read?: boolean;
        notificationType?: NotificationType;
        entityType?: EntityType;
        expand?: string;
      },
      AxiosError
    >({
      fetcher: variables =>
        axiosInstance.get(`/notifications/all?count=true&${qs.stringify(variables)}`).then(res => res.data)
    }),

    // Mutation to mark a notification as read
    markAsRead: router.mutation<
      void, // no response expected
      { id?: number | string; notificationFor?: string }, // input variables, containing the notification ID
      AxiosError
    >({
      mutationFn: variables =>
        variables.id
          ? axiosInstance.post(`/notifications/${variables.id}/read`)
          : axiosInstance.post(`/notifications/all/notificationFor/${variables.notificationFor}/read`)
    })
  });
