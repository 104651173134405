import { useState } from 'react';
import HistoryIcon from '@/assets/icons/navigation/history-icon';
import NotificationsIcon from '@/assets/icons/navigation/notifications-icon';
import { useAuth } from '@/auth/hooks';
import ActivityDrawer from '@/sections/activity/activity-drawer';
import ProfileMenu from '@/sections/profile/profile-menu';
import UpdatesDrawer from '@/sections/updates/updates-drawer';
import { getNotificationQueries } from '@repo/features/notification';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import axiosInstance from '@/lib/axios.ts';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';

type HomeHeaderProps = VariantProps<typeof wrapperVariants> & {
  className?: string;
};
// ----------------------------------------------------------------------

const notificationQueries = getNotificationQueries(axiosInstance);
const wrapperVariants = cva('z-50 flex w-full flex-row items-center justify-between h-21 lg:h-34 px-7 lg:px-10', {
  variants: {
    variant: {
      dark: 'bg-primary text-white [&_svg]:fill-white [&_svg]:stroke-white [&_.action]:bg-white/20 [&_.action:hover]:bg-white/30',
      light:
        'bg-white text-black [&_svg]:fill-black [&_svg]:stroke-black [&_.action]:bg-primary-200 [&_.action:hover]:bg-primary-300'
    }
  },
  defaultVariants: {
    variant: 'dark'
  }
});
export default function AppHeader({ variant, className }: HomeHeaderProps) {
  const { data: count } = notificationQueries.count.useQuery({
    variables: { read: false, entityType: 'Conversation' }
  });
  const { isBelowLg } = useBreakpoint('lg');
  const { delegationWithOrg } = useAuth();

  const [isActivityShown, setIsActivityShown] = useState(false);
  const [isUpdatesShown, setIsUpdatesShown] = useState(false);
  return (
    <>
      <div className={cn(wrapperVariants({ variant }), className)}>
        {isBelowLg ? (
          <ProfileMenu className="lg:invisible">
            <div className="flex items-center">
              <Avatar className="size-14 cursor-pointer">
                {/*<AvatarImage src={user?.photoURL} alt={user?.displayName} />*/}
                <AvatarFallback className="bg-white/20 text-white font-semibold text-2xs">
                  {delegationWithOrg?.organization.name.charAt(0).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <div className="hidden lg:block ml-2 font-archivo text-[15px] tracking-tight text-black">
                {delegationWithOrg?.organization.name}
              </div>
            </div>
          </ProfileMenu>
        ) : (
          <div />
        )}
        <div className="space-x-5">
          <Button
            className="action size-14"
            onClick={() => {
              setIsActivityShown(true);
            }}
            size="icon"
          >
            <HistoryIcon />
          </Button>

          <Button
            className="action size-14 relative"
            onClick={() => {
              setIsUpdatesShown(true);
            }}
            size="icon"
          >
            <NotificationsIcon />
            {count?.count && count.count > 0 ? (
              <span className="absolute flex size-6 -right-1 top-0">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-fun-red opacity-75" />
                <span className="relative inline-flex items-center justify-center rounded-full size-6 bg-fun-red text-white text-4xs font-bold leading-none">
                  {count.count && count.count > 9 ? '9+' : count.count}
                </span>
              </span>
            ) : null}
          </Button>
        </div>
        {/*<Logo beta className="h-[36px] w-[76px] hidden lg:block" />*/}
      </div>
      <ActivityDrawer
        isOpen={isActivityShown}
        onOpenChange={isOpen => {
          setIsActivityShown(isOpen);
        }}
      />
      <UpdatesDrawer
        isOpen={isUpdatesShown}
        onOpenChange={isOpen => {
          setIsUpdatesShown(isOpen);
        }}
      />
    </>
  );
}
