import React, { useCallback, useEffect } from 'react';
import { useAuth } from '@/auth/hooks';
import socket from '@/socket/socket';
import { SocketContext } from '@/socket/socket-context';
import type { ChatRequestEvent, ChatResponseEvent, IDurableEvent } from '@/socket/types';
import { useConversationsStateStore } from '@/stores/conversations-state';
import axiosInstance from '@/lib/axios.ts';
import { getConversationQueries, useManageConversationData } from '@repo/features/conversation';
import { getNotificationQueries } from '@repo/features/notification';
import { useQueryClient } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

// ----------------------------------------------------------------------
const conversationQueries = getConversationQueries(axiosInstance);
const notificationQueries = getNotificationQueries(axiosInstance);
export function SocketProvider({ children }: Props) {
  const { accessToken, selectedOrgId } = useAuth();
  const { addMessage } = useManageConversationData(axiosInstance);
  const [add, remove] = useConversationsStateStore(useShallow(state => [state.add, state.remove]));
  const queryClient = useQueryClient();
  const { mutate: markNotificationAsRead } = notificationQueries.markAsRead.useMutation({
    onSuccess: async () => {
      // Invalidate the 'all' notifications query after mutation succeeds
      await queryClient.invalidateQueries({ queryKey: notificationQueries.all.getKey() });
      await queryClient.invalidateQueries({ queryKey: notificationQueries.count.getKey() });
    }
  });
  useEffect(() => {
    if (accessToken && selectedOrgId) {
      socket.io.opts.extraHeaders = {
        authorization: `Bearer ${accessToken}`,
        'milo-organization-id': selectedOrgId
      };
      socket.connect();
    }
    return () => {
      socket.disconnect();
    };
  }, [accessToken, selectedOrgId]);

  const onMessageReceived = useCallback(
    (e: IDurableEvent) => {
      if (e.status === 'in_progress') {
        add(e.durableId);
      }
      if (e.status === 'completed' || e.status === 'error') {
        remove(e.durableId);
      }
    },
    [add, remove]
  );

  const onGetMessage = useCallback(
    (e: ChatResponseEvent | ChatRequestEvent) => {
      addMessage(e.conversationId!, e);
    },
    [addMessage]
  );

  useEffect(() => {
    socket.on('chat_response', e => {
      onGetMessage(e);
      // trick
      queryClient.invalidateQueries({
        queryKey: conversationQueries.byId.getKey({
          id: e.conversationId
        })
      });
    });
    socket.on('event_tracing', onMessageReceived);
    socket.prependAnyOutgoing((e, data: ChatRequestEvent) => {
      if (e === 'chat_request') {
        onGetMessage(data);
        if (data.conversationId) {
          markNotificationAsRead({ notificationFor: data.conversationId });
        }
      }
    });
    return () => {
      socket.off('chat_response', onGetMessage);
      socket.off('event_tracing', onMessageReceived);
      socket.offAnyOutgoing();
    };
  }, [onMessageReceived, onGetMessage]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}
