import TransactionRow from '@/sections/library/transaction/transaction-row';
import { getTransactionsQueries } from '@repo/features/transactions';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';

export const Route = createFileRoute('/_guarded/library/transactions')({
  component: TransactionsPage
});

const transactionsQueries = getTransactionsQueries(axiosInstance);
function TransactionsPage() {
  const { data: transactions } = transactionsQueries.search.useQuery({});
  const { isBelowLg } = useBreakpoint('lg');
  return (
    <>
      <div className="divide-y divide-neutral-300  overflow-y-auto max-h-full">
        {transactions?.records.map(record => (
          <TransactionRow key={record._id} transaction={record} variant={isBelowLg ? 'mobile' : 'default'} />
        ))}
      </div>
      <Outlet />
    </>
  );
}
