import { useAuth } from '@/auth/hooks';

import { cn } from '@/lib/utils';
import { useWizard } from '@/components/form-wizard';
import ProgressAvatar from '@/components/progressive-avatar';
import {useShallow} from "zustand/react/shallow";

export type MobileOnboardingHeaderProps = {
  className?: string;
  progress: number;
};

export default function MobileOnboardingHeader({ className }: MobileOnboardingHeaderProps) {
  const { user } = useAuth();
  const progress = useWizard(useShallow(state => state.progress));
  return (
    <div className={cn('h-21 lg:h-44 w-full flex items-center sticky top-0 py-8 px-10', className)}>
      <ProgressAvatar
        className="lg:hidden"
        fallback={user ? `${user.firstName[0]}${user.lastName[0]}` : ''}
        progress={progress}
        variant="secondary"
      />
    </div>
  );
}
