import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function MiloAvatar({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-4', className)}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      {/*<circle cx="16" cy="16" r="16" transform="matrix(-1 0 0 1 32 0)" fill="#FF6F67" />*/}
      {/*<circle cx="16" cy="16" r="16" transform="matrix(-1 0 0 1 32 0)" fill="#FF6F67" />*/}
      <circle cx="16" cy="16" fill="#000AEE" r="16" transform="matrix(-1 0 0 1 32 0)" />
      <path d="M9.96973 9.26758V12.2851H21.6553V9.26758H9.96973Z" fill="white" />
      <path d="M20.6494 12.291V18.323H25.9998V12.291H20.6494Z" fill="white" />
      <path d="M9.96582 18.2793V21.2968H22.0018V18.2793H9.96582Z" fill="white" />
      <path d="M9.9502 22.0552V25.0656H16.9745V22.0552H9.9502Z" fill="white" />
      <path d="M9.97363 12.2549V15.3177H23.9896V12.2549H9.97363Z" fill="white" />
      <path d="M9.99316 13.7168V18.4111H21.6277V13.7168H9.99316Z" fill="white" />
      <path d="M9.9502 6V15.0213H12.9353V6H9.9502Z" fill="white" />
      <path d="M15.1533 6.06152V9.31539H18.8021V6.06152H15.1533Z" fill="white" />
      <path d="M18.3135 12.2441V15.2614H21.3102V12.2441H18.3135Z" fill="#000AEE" />
    </svg>
  );
}

export default memo(MiloAvatar);
