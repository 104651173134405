import { useAuth } from '@/auth/hooks';
import { useGetLedgers } from '@repo/teal/rq-hooks/ledgers';
import { useGetBalanceSheet } from '@repo/teal/rq-hooks/reports';
import { format } from 'date-fns';

import StatisticCard from '../../components/statistic-card.tsx';

export type CashBalanceMetricProps = {
  startDate: Date;
  endDate?: Date;
  onClick?: () => void;
  title: string;
};

export default function ExpectedBalanceMetric({ startDate, endDate, ...other }: CashBalanceMetricProps) {
  const { isAccountingConnected } = useAuth();

  // TODO fix
  const { data } = useGetBalanceSheet(
    {
      end_date: endDate ? format(endDate, 'yyyy-MM-dd') : format(startDate, 'yyyy-MM-dd'),
      include_empty: true
    },
    {
      query: {
        enabled: isAccountingConnected
      }
    }
  );
  const { data: ledgers } = useGetLedgers(
    {
      financial_account_type: 'bank_account'
    },
    {
      query: {
        enabled: isAccountingConnected
      }
    }
  );

  const ledgerIdsToUse = ledgers?.records.map(l => l.id) || [];
  const assets = data?.records.find(r => r.name === 'Asset');
  const liabilities = data?.records.find(r => r.name === 'Liability');
  const currentAssets = assets?.children?.find(c => c.name === 'Current Assets');
  const currentLiabilities = liabilities?.children?.find(c => c.name === 'Current Liabilities');
  const accountPayableAmount =
    currentLiabilities?.children?.find(c => c.name === 'Accounts Payable')?.total_amount || 0;
  const accountReceivableAmount =
    currentAssets?.children?.find(c => c.name === 'Accounts Receivable')?.total_amount || 0;
  const accounts = currentAssets?.children?.filter(c => ledgerIdsToUse.includes(c.ledger_id!)) || [];
  const cashBalance = accounts.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);
  return <StatisticCard amount={cashBalance - accountPayableAmount + accountReceivableAmount || 0} {...other} />;
}
