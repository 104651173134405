import { useAuth } from '@/auth/hooks';
import { getFinancialQueries } from '@repo/features/financial-data';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import axiosInstance from '@/lib/axios';

import StatisticCard from '../../components/statistic-card.tsx';

export type ExpectedBalanceMetricProps = {
  startDate: Date;
  endDate?: Date;
  onClick?: () => void;
  title: string;
};

const financialQueries = getFinancialQueries(axiosInstance);

export default function ExpectedBalanceMetric({ startDate, endDate, ...other }: ExpectedBalanceMetricProps) {
  const { isAccountingConnected } = useAuth();

  const {
    data: expectedBalanceResponse,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['financialExpectedBalance', endDate],
    queryFn: () =>
      financialQueries.expectedBalance.fetcher({
        date: format(endDate || new Date(), 'yyyy-MM-dd')
      }),
    enabled: isAccountingConnected,
    staleTime: 1000 * 60 * 5 // Cache the result for 5 minutesg
  });

  // Extract expected end-of-month balance from the API data
  // Using the expected_end_of_period from the first result in the array
  const expectedEndOfMonth = expectedBalanceResponse?.data?.['0']?.expected_end_of_period ?? 0;

  return isLoading ? (
    <StatisticCard amount={'loading..'} {...other} />
  ) : isError ? (
    <StatisticCard amount={'loading..'} {...other} />
  ) : (
    <StatisticCard amount={expectedEndOfMonth} {...other} />
  );
}
