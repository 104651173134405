import MusicListeningDog from '@/assets/lottie/music-listening-dog';
import { getPulseSuggestions } from '@/mock/suggestions';
import UpdateCard from '@/sections/updates/update-card';
import { useAppStateStore } from '@/stores/app-state';
import type { IConversation } from '@repo/features/conversation';
import type { INotification } from '@repo/features/notification';
import { getNotificationQueries } from '@repo/features/notification';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';
import { Drawer, DrawerContent } from '@/components/ui/drawer';
import DrawerSearchHeader from '@/components/drawer-search-header';

export type UpdatesSheetProps = {
  isOpen: boolean;
  onOpenChange?: (open: boolean) => void;
  onClose?: () => void;
};
const notificationQueries = getNotificationQueries(axiosInstance);

export default function UpdatesDrawer({ isOpen, onOpenChange, onClose }: UpdatesSheetProps) {
  const { data: notifications = [] } = notificationQueries.all.useQuery<
    (Omit<INotification, 'notificationFor'> & { notificationFor: string })[]
  >({
    variables: { read: false, entityType: 'Conversation' as const }
  });
  const [isActive, accountsConnected] = useAppStateStore(
    useShallow(state => [state.isActive, state.accountsConnected])
  );
  const hasNotification = !!notifications.length;
  const pulseSuggestions = getPulseSuggestions({ isActive, accountsConnected }).filter(_s => _s.show);
  const renderNotifications = (
    <div className="flex flex-1 flex-col h-max p-4 bg-neutral-300 gap-4 overflow-y-scroll">
      {notifications.map(notification => (
        <UpdateCard key={notification._id} notification={notification} />
      ))}
    </div>
  );

  const renderEmptyScreen = (
    <div className="h-full flex flex-1 flex-col items-center justify-center">
      <div className="w-52">
        <MusicListeningDog />
      </div>
      <div className="text-md font-bold mt-7">No Updates For Now</div>
      <div className="text-sm text-neutral-500 mt-2">Worry not, Milo's tuned in for any new update</div>
    </div>
  );

  return (
    <Drawer direction="right" onClose={onClose} onOpenChange={onOpenChange} open={isOpen}>
      <DrawerContent
        className="lg:rounded-tl-lg lg:rounded-bl-lg h-full w-full sm:w-[28.125rem] right-0 left-auto rounded-none border-none focus-visible:outline-none"
        hideHandle
      >
        <DrawerSearchHeader
          onBackClick={() => {
            onOpenChange && onOpenChange(false);
          }}
        >
          Milo's Updates
        </DrawerSearchHeader>
        {notifications.length > 0 ? renderNotifications : renderEmptyScreen}
      </DrawerContent>
    </Drawer>
  );
}
