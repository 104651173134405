import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function FeedbackIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-4', className)}
      fill="none"
      stroke="#A1A1A9"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M9.81861 2.92493L7.8851 1.38499C7.63561 1.12833 7.07427 1 6.70004 1H4.32993C3.58147 1 2.77065 1.57748 2.58353 2.34745L1.08662 7.03143C0.77476 7.92973 1.3361 8.6997 2.27167 8.6997H4.76653C5.14076 8.6997 5.45262 9.02053 5.39024 9.46967L5.07839 11.5229C4.95364 12.1004 5.32787 12.742 5.88922 12.9345C6.38819 13.127 7.0119 12.8704 7.26139 12.4854L9.81861 8.57137"
        strokeMiterlimit="10"
      />
      <path
        d="M13.0014 2.92615V9.21424C13.0014 10.1125 12.6272 10.4334 11.754 10.4334H11.1302C10.257 10.4334 9.88281 10.1125 9.88281 9.21424V2.92615C9.88281 2.02785 10.257 1.70703 11.1302 1.70703H11.754C12.6272 1.70703 13.0014 2.02785 13.0014 2.92615Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(FeedbackIcon);
