import Google from '@/assets/icons/google';
import qs from 'qs';

import { cn } from '@/lib/utils';
import type { ButtonProps } from '@/components/ui/button';
import { Button } from '@/components/ui/button';

export type GoogleSignButtonProps = Omit<ButtonProps, 'asChild' | 'children'> & {
  tokenId?: string;
  children?: React.ReactNode;
};

export default function GoogleSignButton({ className, tokenId, children, ...other }: GoogleSignButtonProps) {
  const existing = qs.parse(window.location.search || '', { ignoreQueryPrefix: true });
  const queryString = qs.stringify(
    {
      ...existing,
      redirect: window.location.origin,
      tokenId
    },
    { addQueryPrefix: true }
  );

  return (
    <Button asChild className={cn('', className)} size="lg" {...other}>
      <a href={`${AUTH_URL}/auth/google${queryString}`}>
        <Google className="mr-4" fill="white" />
        {children}
      </a>
    </Button>
  );
}
