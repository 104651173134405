import * as React from 'react';
import BankAccount from '@/icons/BankAccount.svg?react';
import CreditCard from '@/icons/CreditCard.svg?react';
import PaymentProcessors from '@/icons/PaymentProcessors.svg?react';
import Payrolls from '@/icons/Payrolls.svg?react';
import { useProfileStore } from '@/sections/profile/use-profile-store';
import { useShallow } from 'zustand/react/shallow';

import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { Separator } from '@/components/ui/separator';
import DrawerSearchHeader from '@/components/drawer-search-header';
import MenuRow from '@/components/menu-row';

export default function ConnectedAccountsMenu() {
  const { toggleMenu, closeMenu, isOpen, activeMenu, openMenu } = useProfileStore(
    useShallow(state => ({
      toggleMenu: state.toggleMenu,
      isOpen: state.isOpen,
      closeMenu: state.closeMenu,
      activeMenu: state.activeMenu,
      openMenu: state.openMenu
    }))
  );
  const renderHeader = (
    <DrawerSearchHeader
      onBackClick={() => {
        closeMenu();
      }}
    >
      Connected Accounts
    </DrawerSearchHeader>
  );

  return (
    <Drawer
      direction="left"
      onOpenChange={() => {
        toggleMenu('connected_accounts');
      }}
      open={isOpen ? activeMenu === 'connected_accounts' : false}
    >
      <DrawerContent
        className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[28.1rem] left-0 right-auto rounded-none border-none focus-visible:outline-none"
        hideHandle
      >
        <DrawerHeader className="hidden">
          <DrawerTitle>Connected Accounts Menu</DrawerTitle>
          <DrawerDescription>Connected Accounts Menu items are here.Choose one.</DrawerDescription>
        </DrawerHeader>
        {renderHeader}
        <MenuRow
          chevron
          className="text-base"
          description="Connect your business or personal bank accounts"
          icon={<BankAccount />}
          onClick={() => {
            openMenu('bank_accounts');
          }}
        >
          Bank & Credit Cards
        </MenuRow>
        <Separator className="bg-neutral-300" />
        {/*<MenuRow*/}
        {/*  chevron*/}
        {/*  className="text-base"*/}
        {/*  description="Connect your business or personal cards"*/}
        {/*  disabled*/}
        {/*  icon={<CreditCard />}*/}
        {/*>*/}
        {/*  Credit Cards*/}
        {/*</MenuRow>*/}
        <Separator className="bg-neutral-300" />
        <MenuRow chevron className="text-base" description="Coming up" disabled icon={<PaymentProcessors />}>
          Payment Processors
        </MenuRow>
        <Separator className="bg-neutral-300" />
        <MenuRow chevron className="text-base" description="Coming up" disabled icon={<Payrolls />}>
          Payrolls
        </MenuRow>
        <Separator className="bg-neutral-300" />
      </DrawerContent>
    </Drawer>
  );
}
