/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teal API
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  CreateLedgerParams,
  ExpandableJournalEntry,
  ExternalBalanceCheck,
  GetLedgersParams,
  Ledger,
  LedgerCreate,
  LedgerPage,
  LedgerUpdate,
  LedgerUpdateOpeningBalance,
  SetLedgerOpeningBalanceParams,
  TealErrorResponse,
  UpdateLedgerParams,
} from "../types";
import { createTealInstance } from "../axiosInstance";

/**
 * Lists all of an Instance's Ledgers.
 * @summary List all Ledgers
 */
export const getLedgers = (params?: GetLedgersParams, signal?: AbortSignal) => {
  return createTealInstance<LedgerPage>({
    url: `/v0/ledgers`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetLedgersQueryKey = (params?: GetLedgersParams) => {
  return [`/v0/ledgers`, ...(params ? [params] : [])] as const;
};

export const getGetLedgersQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLedgers>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLedgersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLedgers>>> = ({
    signal,
  }) => getLedgers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLedgers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgers>>
>;
export type GetLedgersQueryError = TealErrorResponse;

export function useGetLedgers<
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params: undefined | GetLedgersParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLedgers>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLedgers>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgers<
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLedgers>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLedgers>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgers<
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLedgers>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all Ledgers
 */

export function useGetLedgers<
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLedgers>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List all Ledgers
 */
export const prefetchGetLedgers = async <
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLedgers>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetLedgersQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetLedgersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgers>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLedgersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLedgers>>> = ({
    signal,
  }) => getLedgers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getLedgers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgers>>
>;
export type GetLedgersSuspenseQueryError = TealErrorResponse;

export function useGetLedgersSuspense<
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params: undefined | GetLedgersParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgers>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgersSuspense<
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgers>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgersSuspense<
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgers>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all Ledgers
 */

export function useGetLedgersSuspense<
  TData = Awaited<ReturnType<typeof getLedgers>>,
  TError = TealErrorResponse,
>(
  params?: GetLedgersParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgers>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgersSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Creates a Ledger.
 * @summary Create a Ledger
 */
export const createLedger = (
  ledgerCreate: LedgerCreate,
  params?: CreateLedgerParams,
) => {
  return createTealInstance<Ledger>({
    url: `/v0/ledgers`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: ledgerCreate,
    params,
  });
};

export const getCreateLedgerMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLedger>>,
    TError,
    { data: LedgerCreate; params?: CreateLedgerParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createLedger>>,
  TError,
  { data: LedgerCreate; params?: CreateLedgerParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createLedger>>,
    { data: LedgerCreate; params?: CreateLedgerParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createLedger(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateLedgerMutationResult = NonNullable<
  Awaited<ReturnType<typeof createLedger>>
>;
export type CreateLedgerMutationBody = LedgerCreate;
export type CreateLedgerMutationError = TealErrorResponse;

/**
 * @summary Create a Ledger
 */
export const useCreateLedger = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLedger>>,
    TError,
    { data: LedgerCreate; params?: CreateLedgerParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createLedger>>,
  TError,
  { data: LedgerCreate; params?: CreateLedgerParams },
  TContext
> => {
  const mutationOptions = getCreateLedgerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates a Ledger.
 * @summary Update a Ledger
 */
export const updateLedger = (
  ledgerId: string,
  ledgerUpdate: LedgerUpdate,
  params?: UpdateLedgerParams,
) => {
  return createTealInstance<Ledger>({
    url: `/v0/ledgers/${ledgerId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: ledgerUpdate,
    params,
  });
};

export const getUpdateLedgerMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLedger>>,
    TError,
    { ledgerId: string; data: LedgerUpdate; params?: UpdateLedgerParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateLedger>>,
  TError,
  { ledgerId: string; data: LedgerUpdate; params?: UpdateLedgerParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateLedger>>,
    { ledgerId: string; data: LedgerUpdate; params?: UpdateLedgerParams }
  > = (props) => {
    const { ledgerId, data, params } = props ?? {};

    return updateLedger(ledgerId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateLedgerMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateLedger>>
>;
export type UpdateLedgerMutationBody = LedgerUpdate;
export type UpdateLedgerMutationError = TealErrorResponse;

/**
 * @summary Update a Ledger
 */
export const useUpdateLedger = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLedger>>,
    TError,
    { ledgerId: string; data: LedgerUpdate; params?: UpdateLedgerParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateLedger>>,
  TError,
  { ledgerId: string; data: LedgerUpdate; params?: UpdateLedgerParams },
  TContext
> => {
  const mutationOptions = getUpdateLedgerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a Ledger. Ledgers with line entries cannot be deleted.
 * @summary Delete a Ledger
 */
export const deleteLedger = (ledgerId: string) => {
  return createTealInstance<void>({
    url: `/v0/ledgers/${ledgerId}`,
    method: "DELETE",
  });
};

export const getDeleteLedgerMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLedger>>,
    TError,
    { ledgerId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteLedger>>,
  TError,
  { ledgerId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteLedger>>,
    { ledgerId: string }
  > = (props) => {
    const { ledgerId } = props ?? {};

    return deleteLedger(ledgerId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteLedgerMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteLedger>>
>;

export type DeleteLedgerMutationError = TealErrorResponse;

/**
 * @summary Delete a Ledger
 */
export const useDeleteLedger = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLedger>>,
    TError,
    { ledgerId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteLedger>>,
  TError,
  { ledgerId: string },
  TContext
> => {
  const mutationOptions = getDeleteLedgerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sets the opening balance for a Ledger on the entries start date for the
Instance, or updates opening balance if the Ledger already has one. Returns a
journal entry.
 * @summary Set a Ledger's opening balance
 */
export const setLedgerOpeningBalance = (
  ledgerId: string,
  ledgerUpdateOpeningBalance: LedgerUpdateOpeningBalance,
  params?: SetLedgerOpeningBalanceParams,
) => {
  return createTealInstance<ExpandableJournalEntry>({
    url: `/v0/ledgers/${ledgerId}/opening-balance`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: ledgerUpdateOpeningBalance,
    params,
  });
};

export const getSetLedgerOpeningBalanceMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setLedgerOpeningBalance>>,
    TError,
    {
      ledgerId: string;
      data: LedgerUpdateOpeningBalance;
      params?: SetLedgerOpeningBalanceParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setLedgerOpeningBalance>>,
  TError,
  {
    ledgerId: string;
    data: LedgerUpdateOpeningBalance;
    params?: SetLedgerOpeningBalanceParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setLedgerOpeningBalance>>,
    {
      ledgerId: string;
      data: LedgerUpdateOpeningBalance;
      params?: SetLedgerOpeningBalanceParams;
    }
  > = (props) => {
    const { ledgerId, data, params } = props ?? {};

    return setLedgerOpeningBalance(ledgerId, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetLedgerOpeningBalanceMutationResult = NonNullable<
  Awaited<ReturnType<typeof setLedgerOpeningBalance>>
>;
export type SetLedgerOpeningBalanceMutationBody = LedgerUpdateOpeningBalance;
export type SetLedgerOpeningBalanceMutationError = TealErrorResponse;

/**
 * @summary Set a Ledger's opening balance
 */
export const useSetLedgerOpeningBalance = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setLedgerOpeningBalance>>,
    TError,
    {
      ledgerId: string;
      data: LedgerUpdateOpeningBalance;
      params?: SetLedgerOpeningBalanceParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof setLedgerOpeningBalance>>,
  TError,
  {
    ledgerId: string;
    data: LedgerUpdateOpeningBalance;
    params?: SetLedgerOpeningBalanceParams;
  },
  TContext
> => {
  const mutationOptions = getSetLedgerOpeningBalanceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns the opening balance journal entry
 * @summary Get all ledgers opening balances
 */
export const getLedgersOpeningBalances = (signal?: AbortSignal) => {
  return createTealInstance<ExpandableJournalEntry>({
    url: `/v0/ledgers/opening-balance`,
    method: "GET",
    signal,
  });
};

export const getGetLedgersOpeningBalancesQueryKey = () => {
  return [`/v0/ledgers/opening-balance`] as const;
};

export const getGetLedgersOpeningBalancesQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLedgersOpeningBalancesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLedgersOpeningBalances>>
  > = ({ signal }) => getLedgersOpeningBalances(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgersOpeningBalancesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgersOpeningBalances>>
>;
export type GetLedgersOpeningBalancesQueryError = TealErrorResponse;

export function useGetLedgersOpeningBalances<
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgersOpeningBalances<
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgersOpeningBalances<
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all ledgers opening balances
 */

export function useGetLedgersOpeningBalances<
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgersOpeningBalancesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all ledgers opening balances
 */
export const prefetchGetLedgersOpeningBalances = async <
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetLedgersOpeningBalancesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetLedgersOpeningBalancesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLedgersOpeningBalancesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLedgersOpeningBalances>>
  > = ({ signal }) => getLedgersOpeningBalances(signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgersOpeningBalancesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgersOpeningBalances>>
>;
export type GetLedgersOpeningBalancesSuspenseQueryError = TealErrorResponse;

export function useGetLedgersOpeningBalancesSuspense<
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  >;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgersOpeningBalancesSuspense<
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  >;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgersOpeningBalancesSuspense<
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  >;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get all ledgers opening balances
 */

export function useGetLedgersOpeningBalancesSuspense<
  TData = Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getLedgersOpeningBalances>>,
      TError,
      TData
    >
  >;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions =
    getGetLedgersOpeningBalancesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Triggers a request for new transactions from a Ledger's data source, if it
has one. Success or error messages will be sent via the `transactions.source`
webhooks.
 * @summary Check a Ledger for new transactions
 */
export const refreshTransactions = (ledgerId: string) => {
  return createTealInstance<unknown>({
    url: `/v0/ledgers/${ledgerId}/refresh`,
    method: "POST",
  });
};

export const getRefreshTransactionsMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshTransactions>>,
    TError,
    { ledgerId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof refreshTransactions>>,
  TError,
  { ledgerId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof refreshTransactions>>,
    { ledgerId: string }
  > = (props) => {
    const { ledgerId } = props ?? {};

    return refreshTransactions(ledgerId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof refreshTransactions>>
>;

export type RefreshTransactionsMutationError = TealErrorResponse;

/**
 * @summary Check a Ledger for new transactions
 */
export const useRefreshTransactions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshTransactions>>,
    TError,
    { ledgerId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof refreshTransactions>>,
  TError,
  { ledgerId: string },
  TContext
> => {
  const mutationOptions = getRefreshTransactionsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves the Ledger's current balanced status and the most recent datetime
it was balanced.
 * @summary Retrieve a Ledger's balanced status
 */
export const getLastBalanced = (ledgerId: string, signal?: AbortSignal) => {
  return createTealInstance<ExternalBalanceCheck>({
    url: `/v0/ledgers/${ledgerId}/last-balanced`,
    method: "GET",
    signal,
  });
};

export const getGetLastBalancedQueryKey = (ledgerId: string) => {
  return [`/v0/ledgers/${ledgerId}/last-balanced`] as const;
};

export const getGetLastBalancedQueryOptions = <
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLastBalancedQueryKey(ledgerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLastBalanced>>> = ({
    signal,
  }) => getLastBalanced(ledgerId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!ledgerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLastBalanced>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLastBalancedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLastBalanced>>
>;
export type GetLastBalancedQueryError = TealErrorResponse;

export function useGetLastBalanced<
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLastBalanced>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLastBalanced<
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLastBalanced>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLastBalanced<
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a Ledger's balanced status
 */

export function useGetLastBalanced<
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLastBalancedQueryOptions(ledgerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve a Ledger's balanced status
 */
export const prefetchGetLastBalanced = async <
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  ledgerId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetLastBalancedQueryOptions(ledgerId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetLastBalancedSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLastBalancedQueryKey(ledgerId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLastBalanced>>> = ({
    signal,
  }) => getLastBalanced(ledgerId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!ledgerId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getLastBalanced>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLastBalancedSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLastBalanced>>
>;
export type GetLastBalancedSuspenseQueryError = TealErrorResponse;

export function useGetLastBalancedSuspense<
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLastBalancedSuspense<
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLastBalancedSuspense<
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a Ledger's balanced status
 */

export function useGetLastBalancedSuspense<
  TData = Awaited<ReturnType<typeof getLastBalanced>>,
  TError = TealErrorResponse,
>(
  ledgerId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLastBalanced>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLastBalancedSuspenseQueryOptions(
    ledgerId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
