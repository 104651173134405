import { useCallback, useContext } from 'react';
import { SocketContext } from '@/socket/socket-context';
import uuidv4 from '@/utils/uuidv4';
import type { IMessage } from '@repo/features/conversation';
import { useUploadMultipleFiles } from '@repo/features/file';

import ActionsObserver from '@/lib/actions-observer';
import axiosInstance from '@/lib/axios.ts';

const useSendMessage = (params: { conversationId?: string; activityId?: string } = {}) => {
  const socket = useContext(SocketContext);
  const { upload, progress: fileProgress } = useUploadMultipleFiles(axiosInstance);

  type SendMessageParams = {
    message: string;
    files?: File[];
    cleanFiles?: () => void;
    agentType?: string;
    activityType?: string;
    activityCategory?: string;
    activitySubject?: string;
  };

  const sendMessage = useCallback(
    async (
      {
        message,
        files = [],
        cleanFiles = undefined,
        activityType,
        activitySubject,
        activityCategory
      }: SendMessageParams,
      callback?: (cData: { conversationId: string; activityId: string; msgs?: IMessage[] }) => void
    ) => {
      const fileIds = await upload({ files });
      // Activates user on first message
      const messageObject = {
        _id: uuidv4(),
        content: message,
        conversationId: params.conversationId,
        activityId: params.activityId,
        role: 'user' as const,
        ...(activityType ? { activityType } : {}),
        ...(activitySubject ? { activitySubject } : {}),
        ...(activityCategory ? { activityCategory } : { activityCategory: 'owner_ask_or_request' }),
        fileIds,
        createdAt: new Date().toISOString()
      };
      ActionsObserver.notify('USER_SEND_MESSAGE', { message: messageObject });
      if (files.length > 0 || !!message) {
        socket.emit('chat_request', messageObject, callbackData => {
          callback && callback(callbackData);
        });
        cleanFiles && cleanFiles();
      }

      // addMessage(params.conversation?._id!, messageObject);
    },
    [params.conversationId, params.activityId, upload]
  );
  return { sendMessage, fileProgress };
};

export default useSendMessage;
