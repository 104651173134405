import { memo } from 'react';

import { cn } from '@/lib/utils';

function TaxesIcon({ className, active, ...other }: React.ComponentProps<'svg'> & { active?: boolean }) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M6.04273 1.89024C5.40288 1.89024 4.88419 2.40894 4.88419 3.04878V5.82927C4.88419 6.21317 4.57297 6.52439 4.18907 6.52439C3.80516 6.52439 3.49395 6.21317 3.49395 5.82927V3.04878C3.49395 1.64113 4.63507 0.5 6.04273 0.5H12.5305C12.7149 0.5 12.8917 0.573236 13.0221 0.703596L17.6562 5.33774C17.7866 5.4681 17.8598 5.64491 17.8598 5.82927V16.0244C17.8598 17.432 16.7187 18.5732 15.311 18.5732H10.6769C10.293 18.5732 9.98175 18.2619 9.98175 17.878C9.98175 17.4941 10.293 17.1829 10.6769 17.1829H15.311C15.9509 17.1829 16.4696 16.6642 16.4696 16.0244V6.52439H13.4574C12.5616 6.52439 11.8354 5.79822 11.8354 4.90244V1.89024H6.04273ZM13.2256 2.87329L15.4865 5.13414H13.4574C13.3294 5.13414 13.2256 5.03041 13.2256 4.90244V2.87329ZM5.1159 9.30487C3.96418 9.30487 3.03053 10.2385 3.03053 11.3902C3.03053 12.542 3.96418 13.4756 5.1159 13.4756C6.26761 13.4756 7.20126 12.542 7.20126 11.3902C7.20126 10.2385 6.26761 9.30487 5.1159 9.30487ZM1.64029 11.3902C1.64029 9.47071 3.19637 7.91463 5.1159 7.91463C7.03542 7.91463 8.5915 9.47071 8.5915 11.3902C8.5915 12.5161 8.05621 13.5169 7.22626 14.152L8.56219 18.6051C8.64188 18.8708 8.55605 19.1583 8.34382 19.3368C8.13159 19.5153 7.83356 19.5506 7.58552 19.4266L5.1159 18.1918L2.64628 19.4266C2.39823 19.5506 2.1002 19.5153 1.88797 19.3368C1.67574 19.1583 1.58992 18.8708 1.6696 18.6051L3.00553 14.152C2.17558 13.5169 1.64029 12.5161 1.64029 11.3902ZM4.27367 14.7631L3.4635 17.4637L4.80503 16.7929C5.00072 16.695 5.23107 16.695 5.42676 16.7929L6.76829 17.4637L5.95812 14.7631C5.68846 14.8302 5.40634 14.8658 5.1159 14.8658C4.82546 14.8658 4.54334 14.8302 4.27367 14.7631Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default memo(TaxesIcon);
