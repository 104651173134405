import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function CalendarIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 15 17"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M11.9957 2.1H11.2173V1.31297C11.2173 0.863243 10.8541 0.5 10.4043 0.5C9.95459 0.5 9.59135 0.863243 9.59135 1.31297V2.09135H4.82595V1.31297C4.82595 0.863243 4.4627 0.5 4.01297 0.5C3.56324 0.5 3.2 0.863243 3.2 1.31297V2.09135H2.42162C1.08108 2.1 0 3.18108 0 4.50432V14.087C0 15.4189 1.08108 16.5 2.41297 16.5H11.9957C13.3276 16.5 14.4086 15.4189 14.4086 14.087V4.50432C14.4086 3.17243 13.3276 2.09135 11.9957 2.09135V2.1ZM1.62595 8.5173H12.7741V14.087C12.7741 14.5195 12.4195 14.8654 11.9957 14.8654H2.41297C1.98054 14.8654 1.63459 14.5108 1.63459 14.087V8.5173H1.62595ZM4.00432 5.32595C4.45405 5.32595 4.8173 4.9627 4.8173 4.51297V3.73459H9.57405V4.51297C9.57405 4.9627 9.9373 5.32595 10.387 5.32595C10.8368 5.32595 11.2 4.9627 11.2 4.51297V3.73459H11.9784C12.4108 3.73459 12.7568 4.08919 12.7568 4.51297V6.89135H1.62595V4.51297C1.62595 4.08054 1.98054 3.73459 2.40432 3.73459H3.1827V4.51297C3.1827 4.9627 3.54595 5.32595 3.99568 5.32595H4.00432Z" />
    </svg>
  );
}

export default memo(CalendarIcon);
