import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Fragment, jsx, jsxs } from 'react/jsx-runtime';
import BarChart from '@/sections/mdx/snippets/bar-chart';
import PieChart from '@/sections/mdx/snippets/pie-chart';
import { evaluate } from '@mdx-js/mdx';
import type { EvaluateOptions } from '@mdx-js/mdx';
import type { MDXComponents, MDXModule, MDXProps } from 'mdx/types';
import remarkGfm from 'remark-gfm';

type ReactMDXContent = (props: MDXProps) => ReactNode;
type Runtime = Pick<EvaluateOptions, 'jsx' | 'jsxs' | 'Fragment'>;

const runtime = { jsx, jsxs, Fragment } as Runtime;
const components: Readonly<MDXComponents> | null | undefined = {
  PieChart: props => {
    return <PieChart data={props.data} />;
  },
  BarChart: props => <BarChart data={props.data} />,
  p: props => <p style={{ margin: 0 }} {...props} />
};

function useMDX(content: string) {
  const [exports, setExports] = useState<MDXModule>({ default: () => Fragment as any });

  useEffect(() => {
    evaluate(content, { ...runtime, remarkPlugins: [remarkGfm] }).then(exports => {
      setExports(exports);
    });
  }, [content]);

  return exports;
}

const Preview: FC<{ source?: string; scope: Record<string, unknown> }> = ({ source = '', scope = {} }) => {
  const exports = useMDX(source);
  const Content = exports.default;
  return <Content components={components} {...scope} />;
};
export default Preview;
