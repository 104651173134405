import sniffing1 from '@/assets/gifs/sniffing1.gif';

export type ComingUpProps = {};

export default function ComingUp({}: ComingUpProps) {
  return (
    <div className="flex flex-1 h-full flex-col justify-center items-center gap-7">
      <div>
        <img alt="Milo is working on it" className="h-[3.75rem]" src={sniffing1} />
      </div>
      <div className="text-center">
        <div className="text-md font-bold">Coming Up</div>
        <div className="text-sm text-neutral-500">Milo is working on it</div>
      </div>
    </div>
  );
}
