import { useAppStateStore } from '@/stores/app-state';
import { getFinancialQueries } from '@repo/features/financial-data';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';

import StatisticCard from '../../components/statistic-card.tsx';

export type ProfitOrLossMetricProps = {
  startDate: Date;
  endDate?: Date;
  title: string;
};

const financialQueries = getFinancialQueries(axiosInstance);

export default function ProfitOrLossMetric({ startDate, endDate, ...other }: ProfitOrLossMetricProps) {
  const accountsConnected = useAppStateStore(useShallow(state => state.accountsConnected));

  const {
    data: financialSummary,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['financialSummary', startDate, endDate],
    queryFn: () =>
      financialQueries.financialSummary.fetcher({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: endDate ? format(endDate, 'yyyy-MM-dd') : format(startDate, 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5 // Cache the result for 5 minutes
  });

  // Calculate profit or loss by subtracting outflow from inflow
  const profitOrLoss = financialSummary ? financialSummary.data.revenue + financialSummary.data.expense : 0;

  return isLoading ? (
    <StatisticCard amount={'loading..'} {...other} />
  ) : isError ? (
    <StatisticCard amount={'loading..'} {...other} />
  ) : (
    <StatisticCard amount={profitOrLoss} {...other} />
  );
}
