import { useGetLedgers } from '@repo/teal/rq-hooks/ledgers';
import { useGetBalanceSheetSuspense } from '@repo/teal/rq-hooks/reports';
import { format } from 'date-fns';

import StatisticCard from '../statistic-card';

export type CashBalanceMetricProps = {
  title: string;
  endDate?: Date;
  onClick?: () => void;
};

export default function CashBalanceMetric({ endDate, onClick, ...other }: CashBalanceMetricProps) {
  const { data } = useGetBalanceSheetSuspense({
    end_date: format(endDate || new Date(), 'yyyy-MM-dd')
  });
  const { data: ledgers } = useGetLedgers({
    financial_account_type: 'bank_account'
  });

  const ledgerIdsToUse = ledgers?.records.map(l => l.id) || [];
  const assets = data.records.find(r => r.name === 'Asset');
  const currentAssets = assets?.children?.find(c => c.name === 'Current Assets');
  const cashBalances = currentAssets?.children?.filter(c => ledgerIdsToUse.includes(c.ledger_id!));
  const cashBalance = cashBalances?.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);

  return (
    <div className="relative">
      <StatisticCard amount={cashBalance || 0} {...other} />
    </div>
  );
}
