import { useAuth } from '@/auth/hooks';
import { useAppStateStore } from '@/stores/app-state.tsx';
import { getFinancialQueries } from '@repo/features/financial-data';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';

import StatisticCard from '../../components/statistic-card.tsx';

export type RevenueMetricProps = {
  startDate: Date;
  endDate?: Date;
  onClick?: () => void;
  title: string;
};

const financialQueries = getFinancialQueries(axiosInstance);

export default function RevenueMetric({ startDate, endDate, ...other }: RevenueMetricProps) {
  const accountsConnected = useAppStateStore(useShallow(state => state.accountsConnected));

  const {
    data: financialSummary,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['financialSummary', startDate, endDate],
    queryFn: () =>
      financialQueries.financialSummary.fetcher({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: endDate ? format(endDate, 'yyyy-MM-dd') : format(startDate, 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5 // Cache the result for 5 minutes
  });

  // Get the total inflow amount
  const revenue = financialSummary?.data.revenue ?? 0;

  return isLoading ? (
    <StatisticCard amount={'loading..'} {...other} />
  ) : isError ? (
    <StatisticCard amount={'loading..'} {...other} />
  ) : (
    <StatisticCard amount={revenue} {...other} />
  );
}
