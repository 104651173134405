import React from 'react';
import type AppHeader from '@/layouts/common/app-header';
import { AppSidebar } from '@/layouts/common/app-sidebar';
import MobileNavBar from '@/layouts/common/mobile-nav-bar';
import AccountSettingsMenu from '@/sections/profile/account-settings-menu';
import BankAccountsDrawer from '@/sections/profile/bank-accounts-drawer';
import BusinessProfileDrawer from '@/sections/profile/business-profile-drawer';
import ConnectedAccountsMenu from '@/sections/profile/connected-accounts-menu';
import MiloSettingsDrawer from '@/sections/profile/milo-settings-drawer';
import PersonalSettingsDrawer from '@/sections/profile/personal-settings-drawer';
import SupportDrawer from '@/sections/profile/support-drawer';

import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { SidebarProvider } from '@/components/ui/sidebar';
import MiloInput from '@/components/milo-input';

// ----------------------------------------------------------------------

type MainLayoutProps = {
  children: React.ReactNode;
  headerProps?: React.ComponentProps<typeof AppHeader>;
  inputProps?: React.ComponentProps<typeof MiloInput>;
  className?: string;
  mainClassName?: string;
};

export default function MainLayout({
  children,
  className,
  mainClassName,
  headerProps = {},
  inputProps = {}
}: MainLayoutProps) {
  const { isBelowLg } = useBreakpoint('lg');
  return (
    <SidebarProvider>
      <AppSidebar />
      <div
        className={cn(
          'flex flex-col flex-1 w-full h-dvh overflow-hidden relative landscape:hidden lg:landscape:flex',
          className
        )}
      >
        <header {...headerProps} id="header-content" />
        <main className={cn('flex-1 overflow-y-scroll', mainClassName)}>{children}</main>
        <div className="sticky bottom-0 p-5 bg-white lg:bg-inherit lg:pb-14 pt-4 lg:flex rounded-tr-lg rounded-tl-lg lg:justify-center lg:items-center lg:rounded-none">
          <div className="lg:w-[50rem]">
            <MiloInput
              label="Tell Milo what to do..."
              pushIntoConversation
              size={isBelowLg ? 'sm' : 'default'}
              variant="dark"
              {...inputProps}
            />
          </div>
        </div>
        <MobileNavBar className="sticky bottom-0 sm:hidden" />
      </div>
      <div className="fixed inset-0 hidden landscape:flex items-center justify-center bg-background text-foreground lg:landscape:hidden">
        <div className="flex flex-col items-center space-y-4 p-6 max-w-md text-center">
          <svg
            className="w-16 h-16 text-primary animate-bounce"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 6v6m0 0v6m0-6h6m-6 0H6" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <h2 className="text-xl font-semibold">Please Rotate Your Device</h2>
          <p className="text-neutral-500">
            This application is best viewed in portrait mode. Please rotate your device to continue.
          </p>
        </div>
      </div>
      <ConnectedAccountsMenu />
      <AccountSettingsMenu />
      <PersonalSettingsDrawer />
      <BusinessProfileDrawer />
      <MiloSettingsDrawer />
      <SupportDrawer />
      <BankAccountsDrawer />
    </SidebarProvider>
  );
}
