// financialQueries.ts
import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import {
  ApiResponse,
  BalanceStatusData,
  CashBalanceData,
  DateRangeParams,
  DateRangeWithAccountParams,
  ExpectedBalanceData,
  FinancialStatementData,
  MoneyFlowData,
  MonthlyCashFlowData,
  SingleDateParams,
  SingleDateWithAccountParams
} from './types';

export const getFinancialQueries = (axiosInstance: AxiosInstance) =>
  router('/reports/finances', {
    cashBalance: router.query<ApiResponse<CashBalanceData>, SingleDateWithAccountParams, AxiosError>({
      fetcher: ({ date, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/cash-balance`, {
            params: { date, financialAccountId }
          })
          .then(({ data }) => data)
    }),

    expectedBalance: router.query<ApiResponse<ExpectedBalanceData[]>, SingleDateParams, AxiosError>({
      fetcher: ({ date }) =>
        axiosInstance
          .get(`/reports/finances/expected-balance`, {
            params: { date }
          })
          .then(({ data }) => data)
    }),

    moneyFlow: router.query<ApiResponse<MoneyFlowData>, DateRangeWithAccountParams, AxiosError>({
      fetcher: ({ startDate, endDate, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/money-flow`, {
            params: { startDate, endDate, financialAccountId }
          })
          .then(({ data }) => data)
    }),

    balanceStatus: router.query<ApiResponse<BalanceStatusData[]>, SingleDateWithAccountParams, AxiosError>({
      fetcher: ({ date, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/balance-status`, {
            params: { date, financialAccountId }
          })
          .then(({ data }) => data)
    }),
    financialSummary: router.query<ApiResponse<FinancialStatementData>, DateRangeParams, AxiosError>({
      fetcher: ({ startDate, endDate }) =>
        axiosInstance
          .get(`/reports/finances/financial-summary`, {
            params: { startDate, endDate }
          })
          .then(({ data }) => data)
    }),

    monthlyCashflow: router.query<ApiResponse<MonthlyCashFlowData[]>, DateRangeWithAccountParams, AxiosError>({
      fetcher: ({ startDate, endDate, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/monthly-cashflow`, {
            params: { startDate, endDate, financialAccountId }
          })
          .then(({ data }) => data)
    })
  });
