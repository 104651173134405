import CloseIcon from '@/icons/Close.svg?react';

import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';

import { useConfirmationStore } from './use-confirmation-store';

export function ConfirmationDialog() {
  const { isOpen, options, handleConfirm, handleCancel, isLoading } = useConfirmationStore();
  const { isBelowLg } = useBreakpoint('lg');
  if (!options) return null;
  if (isBelowLg)
    return (
      <Drawer onOpenChange={open => !open && handleCancel()} open={isOpen}>
        <DrawerContent>
          <DrawerHeader className="hidden">
            <DrawerTitle>{options.title}</DrawerTitle>
            <DrawerDescription>{options.description}</DrawerDescription>
          </DrawerHeader>
          <div className="flex flex-col p-8 gap-10">
            <div className="flex flex-col gap-2 text-center">
              <div className="text-md font-bold">{options.title}</div>
              <div className="text-xs text-neutral-500">{options.description}</div>
            </div>
            <div className="flex justify-between gap-4">
              <Button
                className="px-10 min-w-14 w-auto"
                isLoading={isLoading}
                onClick={async e => {
                  e.stopPropagation();
                  await handleCancel();
                }}
                size="md"
                variant="outline-black"
              >
                {options.cancelText || 'Cancel'}
              </Button>
              <Button
                className={cn('w-full')}
                isLoading={isLoading}
                onClick={async e => {
                  e.stopPropagation();
                  await handleConfirm();
                }}
                variant={options.confirmVariant}
              >
                {options.confirmText || 'Confirm'}
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    );
  return (
    <AlertDialog onOpenChange={open => !open && handleCancel()} open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          {options.modalTitle}
          <CloseIcon
            onClick={async () => {
              await handleCancel();
            }}
          />
        </AlertDialogHeader>
        <AlertDialogTitle>{options.title}</AlertDialogTitle>
        <AlertDialogDescription>{options.description}</AlertDialogDescription>
        <AlertDialogFooter className="flex justify-between">
          <AlertDialogCancel disabled={isLoading} onClick={handleCancel}>
            {isLoading ? 'Processing...' : options.cancelText || 'Cancel'}
          </AlertDialogCancel>
          <AlertDialogAction
            className={isLoading ? 'opacity-50 cursor-not-allowed' : ''}
            disabled={isLoading}
            onClick={handleConfirm}
            variant={options.confirmVariant}
          >
            {isLoading ? 'Processing...' : options.confirmText || 'Confirm'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
