import { useEffect, useState } from 'react';
import amplitudeModule from '@/utils/pixels/amplitude/amplitude';

function useAmplitudeFlag<T>(variantName: string, defaultFlags: T, dependencies: any[] = []) {
  const [flags, setFlags] = useState(defaultFlags);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchVariant() {
      setIsLoading(true);
      try {
        const fetchedFlags = await amplitudeModule.variant(variantName, defaultFlags);
        setFlags(fetchedFlags as T); // Ensure fetched flags match the type T
      } catch (error) {
        console.error('Failed to fetch Amplitude variant:', error);
        setFlags(defaultFlags); // Use default flags if fetch fails
      } finally {
        setIsLoading(false);
      }
    }

    fetchVariant();
  }, dependencies);

  return { flags, isLoading };
}

export default useAmplitudeFlag;
