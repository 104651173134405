import sniffingDog from '@/assets/gifs/sniffing1.gif';
import MainLayout from '@/layouts/main-layout';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/taxes')({
  component: () => (
    <MainLayout
      headerProps={{
        className: 'hidden lg:flex'
      }}
    >
      <TaxesPage />
    </MainLayout>
  )
});

function TaxesPage() {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div>
        <img alt="" className="h-28" src={sniffingDog} />
      </div>
      <div className="text-base font-bold text-black mt-7">Coming Up...</div>
      <div className="text-base font-medium text-black mt-7 text-center w-[300px]">
        &milo will keep your books on order, make sure you will never miss a tax deduction, and no tax-season surprises.
      </div>
    </div>
  );
}
