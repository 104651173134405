import ConversationLayout from '@/layouts/conversation-layout';
import ChatView from '@/sections/chat';
import type { IConversation } from '@repo/features/conversation';
import { getConversationQueries } from '@repo/features/conversation';
import { createFileRoute } from '@tanstack/react-router';
import { differenceInSeconds, parseISO } from 'date-fns';
import { produce } from 'immer';

import axiosInstance from '@/lib/axios.ts';

const conversationQueries = getConversationQueries(axiosInstance);

export const Route = createFileRoute('/_guarded/conversation/$id')({
  component: () => (
    <ConversationLayout>
      <MiloConversationPage />
    </ConversationLayout>
  ),
  loader: async ({
    context: { queryClient },
    params: { id },
    location: {
      state: { msgs }
    }
  }) => {
    const queryData = await queryClient.ensureQueryData(conversationQueries.byId.getOptions({ id }));
    const secondsCreatedAgo = queryData.createdAt
      ? differenceInSeconds(new Date(), parseISO(queryData.createdAt))
      : 1000;
    const updatedQueryData = produce(queryData, (draft: IConversation) => {
      if (secondsCreatedAgo < 3) {
        draft.thread.messages = [...(msgs || []), ...draft.thread.messages];
      }
    });
    queryClient.setQueryData(conversationQueries.byId.getKey({ id }), updatedQueryData);
  }
});

function MiloConversationPage() {
  const { id } = Route.useParams();

  return <ChatView conversationId={id} />;
}
