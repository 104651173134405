import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { useWizard } from '@/components/form-wizard';

export type NextStepSectionProps = {
  children: React.ReactNode;
  startAnimation?: boolean;
  className?: string;
  onClick?: () => void;
};
const AnimatedButton = motion(Button);
export default function NextStepSection({ startAnimation, className, children, onClick }: NextStepSectionProps) {
  let {
    goToNextStep,
    activeStep: { disableNext, disableNextOnErrors },
    isLoading
  } = useWizard(
    useShallow(state => ({
      activeStep: state.activeStep,
      isLoading: state.isLoading,
      goToNextStep: state.goToNextStep
    }))
  );
  const {
    formState: { isValid },
    trigger,
    getValues
  } = useFormContext();
  disableNext = isLoading || disableNext || (disableNextOnErrors && !isValid);
  return (
    <div
      className={cn('w-full lg:w-auto flex justify-center px-12 pt-6 pb-10 lg:justify-end', className)}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        if (disableNext) {
          trigger();
        }
      }}
    >
      <AnimatedButton
        animate={startAnimation ? { opacity: 1 } : false}
        className="w-full lg:w-auto"
        disabled={disableNext}
        initial={{ opacity: 0 }}
        size="lg"
        transition={{ duration: 0.4, ease: 'easeInOut' }}
        type="submit"
      >
        {children}
      </AnimatedButton>
    </div>
  );
}
