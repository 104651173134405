import LinkToPulseButton from '@/sections/chat/ui-actions/components/link-to-pulse-button';
import PlaidConnectButton from '@/sections/chat/ui-actions/components/plaid-connect-button';
import type { UIAction } from '@repo/features/conversation';

export type ChatUIActionsProps = {
  className?: string;
  uiActions?: UIAction[];
};

const uiActionComponents = {
  CONNECT_BANK_ACCOUNT_PLAID: PlaidConnectButton,
  LINK_TO_PULSE: LinkToPulseButton
};

export default function ChatUIActions({ uiActions, className }: ChatUIActionsProps) {
  const actions = uiActions?.map((action, index) => {
    if (action.type === 'CONNECT_BANK_ACCOUNT_PLAID') {
      const Comp = uiActionComponents[action.type];
      return <Comp key={`${action.type}_${index}`}>Connect</Comp>;
    }

    if (action.type === 'LINK_TO_PULSE') {
      const Comp = uiActionComponents[action.type];
      return <Comp key={`${action.type}_${index}`}>Go to Pulse</Comp>;
    }
    return (
      <div className="text-red-600" key={index}>
        Unsupported Action
      </div>
    );
  });
  return <div className={className}>{actions}</div>;
}
