import type { LottieProps } from 'react-lottie';
import Lottie from 'react-lottie';

import animation from './digging-dog.json';

export type LoaderProps = {} & Omit<LottieProps, 'options'>;

export default function DiggingDog(props: LoaderProps) {
  return (
    <Lottie
      options={{
        animationData: animation
      }}
      {...props}
    />
  );
}
