import { useMediaQuery } from '@uidotdev/usehooks';

// screens: {
//   sm: '640px',
//     md: '768px',
//     lg: '1024px',
//     xl: '1280px',
//     '2xl': '1536px',
// },
export default function useResponsiveness() {
  const xl2 = useMediaQuery('only screen and (min-width : 1536px)');
  const xl = useMediaQuery('only screen and (min-width : 1280px)');
  const lg = useMediaQuery('only screen and (min-width : 1024px)');
  const md = useMediaQuery('only screen and (min-width : 768px)');
  const sm = useMediaQuery('only screen and (min-width : 640px)');
  const xs = useMediaQuery('only screen and (min-width : 320px)');

  //order is important!!!
  if (xl2) return '2xl';
  if (xl) return 'xl';
  if (lg) return 'lg';
  if (md) return 'md';
  if (sm) return 'sm';
  if (xs) return 'xs';
}
