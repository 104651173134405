/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teal API
 * OpenAPI spec version: 0.1
 */
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  BalanceSheet,
  CashFlowReport,
  CashFlowTotals,
  FinancialSummary,
  GeneralLedgerReport,
  GetBalanceSheetParams,
  GetCashFlowReportParams,
  GetCashFlowTotalsByLedgerParams,
  GetCashFlowTotalsByPeriodParams,
  GetFinancialSummariesByPeriodParams,
  GetGeneralLedgerReportParams,
  GetGrossProfitByPeriodParams,
  GetIncomeStatementParams,
  GetLedgerStatementParams,
  GetNetProfitByPeriodParams,
  GetTopExpensesParams,
  GrossProfit,
  IncomeStatement,
  LedgerStatementPage,
  NetProfit,
  TealErrorResponse,
  TopExpenses,
} from "../types";
import { createTealInstance } from "../axiosInstance";

/**
 * Retrieves the income statement for the business. Returns a list of ledger
    summaries grouped by ledger `sub_type` and profit calculations.


Related
    guide: [Income statement](https://docs.teal.dev/guides/reports/income_statement)
 * @summary Retrieve the Income Statement
 */
export const getIncomeStatement = (
  params: GetIncomeStatementParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<IncomeStatement>({
    url: `/v0/reports/income-statement`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetIncomeStatementQueryKey = (
  params: GetIncomeStatementParams,
) => {
  return [`/v0/reports/income-statement`, ...(params ? [params] : [])] as const;
};

export const getGetIncomeStatementQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIncomeStatementQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncomeStatement>>
  > = ({ signal }) => getIncomeStatement(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncomeStatement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIncomeStatementQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncomeStatement>>
>;
export type GetIncomeStatementQueryError = TealErrorResponse;

export function useGetIncomeStatement<
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIncomeStatement>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetIncomeStatement<
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIncomeStatement>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetIncomeStatement<
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve the Income Statement
 */

export function useGetIncomeStatement<
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetIncomeStatementQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve the Income Statement
 */
export const prefetchGetIncomeStatement = async <
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetIncomeStatementQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetIncomeStatementSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIncomeStatementQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncomeStatement>>
  > = ({ signal }) => getIncomeStatement(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getIncomeStatement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIncomeStatementSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncomeStatement>>
>;
export type GetIncomeStatementSuspenseQueryError = TealErrorResponse;

export function useGetIncomeStatementSuspense<
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetIncomeStatementSuspense<
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetIncomeStatementSuspense<
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve the Income Statement
 */

export function useGetIncomeStatementSuspense<
  TData = Awaited<ReturnType<typeof getIncomeStatement>>,
  TError = TealErrorResponse,
>(
  params: GetIncomeStatementParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getIncomeStatement>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetIncomeStatementSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves the balance sheet for the business. Returns a list of ledger
summaries grouped by ledger `type`. Requires a Tier 2 subscription.
 * @summary Retrieve the Balance Sheet
 */
export const getBalanceSheet = (
  params: GetBalanceSheetParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<BalanceSheet>({
    url: `/v0/reports/balance-sheet`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetBalanceSheetQueryKey = (params: GetBalanceSheetParams) => {
  return [`/v0/reports/balance-sheet`, ...(params ? [params] : [])] as const;
};

export const getGetBalanceSheetQueryOptions = <
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBalanceSheetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBalanceSheet>>> = ({
    signal,
  }) => getBalanceSheet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBalanceSheet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBalanceSheetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBalanceSheet>>
>;
export type GetBalanceSheetQueryError = TealErrorResponse;

export function useGetBalanceSheet<
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBalanceSheet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBalanceSheet<
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBalanceSheet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBalanceSheet<
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve the Balance Sheet
 */

export function useGetBalanceSheet<
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetBalanceSheetQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve the Balance Sheet
 */
export const prefetchGetBalanceSheet = async <
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetBalanceSheetQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetBalanceSheetSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBalanceSheetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBalanceSheet>>> = ({
    signal,
  }) => getBalanceSheet(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getBalanceSheet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBalanceSheetSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBalanceSheet>>
>;
export type GetBalanceSheetSuspenseQueryError = TealErrorResponse;

export function useGetBalanceSheetSuspense<
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBalanceSheetSuspense<
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetBalanceSheetSuspense<
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve the Balance Sheet
 */

export function useGetBalanceSheetSuspense<
  TData = Awaited<ReturnType<typeof getBalanceSheet>>,
  TError = TealErrorResponse,
>(
  params: GetBalanceSheetParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getBalanceSheet>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetBalanceSheetSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a ledger statement for an existing ledger containing the opening
and closing balances with all the line entries within the period.
 * @summary Retrieve a ledger statement
 */
export const getLedgerStatement = (
  params: GetLedgerStatementParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<LedgerStatementPage>({
    url: `/v0/reports/ledger-statement`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetLedgerStatementQueryKey = (
  params: GetLedgerStatementParams,
) => {
  return [`/v0/reports/ledger-statement`, ...(params ? [params] : [])] as const;
};

export const getGetLedgerStatementQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLedgerStatementQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLedgerStatement>>
  > = ({ signal }) => getLedgerStatement(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLedgerStatement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgerStatementQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgerStatement>>
>;
export type GetLedgerStatementQueryError = TealErrorResponse;

export function useGetLedgerStatement<
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLedgerStatement>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerStatement<
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLedgerStatement>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerStatement<
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a ledger statement
 */

export function useGetLedgerStatement<
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgerStatementQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve a ledger statement
 */
export const prefetchGetLedgerStatement = async <
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetLedgerStatementQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetLedgerStatementSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLedgerStatementQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLedgerStatement>>
  > = ({ signal }) => getLedgerStatement(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getLedgerStatement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgerStatementSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgerStatement>>
>;
export type GetLedgerStatementSuspenseQueryError = TealErrorResponse;

export function useGetLedgerStatementSuspense<
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerStatementSuspense<
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerStatementSuspense<
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a ledger statement
 */

export function useGetLedgerStatementSuspense<
  TData = Awaited<ReturnType<typeof getLedgerStatement>>,
  TError = TealErrorResponse,
>(
  params: GetLedgerStatementParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerStatement>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgerStatementSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves the cash flow report for the business. Returns an object with all
ledgers whose balanced changed during the period and the starting and ending
balances for each cash flow ledger.
 * @summary Retrieve the Cash Flow Report
 */
export const getCashFlowReport = (
  params: GetCashFlowReportParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<CashFlowReport>({
    url: `/v0/reports/cash-flow`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetCashFlowReportQueryKey = (
  params: GetCashFlowReportParams,
) => {
  return [`/v0/reports/cash-flow`, ...(params ? [params] : [])] as const;
};

export const getGetCashFlowReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCashFlowReportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCashFlowReport>>
  > = ({ signal }) => getCashFlowReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCashFlowReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCashFlowReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashFlowReport>>
>;
export type GetCashFlowReportQueryError = TealErrorResponse;

export function useGetCashFlowReport<
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCashFlowReport>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowReport<
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCashFlowReport>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowReport<
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve the Cash Flow Report
 */

export function useGetCashFlowReport<
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCashFlowReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve the Cash Flow Report
 */
export const prefetchGetCashFlowReport = async <
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetCashFlowReportQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCashFlowReportSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCashFlowReportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCashFlowReport>>
  > = ({ signal }) => getCashFlowReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCashFlowReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCashFlowReportSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashFlowReport>>
>;
export type GetCashFlowReportSuspenseQueryError = TealErrorResponse;

export function useGetCashFlowReportSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowReportSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowReportSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve the Cash Flow Report
 */

export function useGetCashFlowReportSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowReport>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCashFlowReportSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a list of the cash flow data across all cash flow ledgers
segmented by period.
 * @summary List cash flow totals by period
 */
export const getCashFlowTotalsByPeriod = (
  params: GetCashFlowTotalsByPeriodParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<CashFlowTotals>({
    url: `/v0/reports/cash-flow-totals`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetCashFlowTotalsByPeriodQueryKey = (
  params: GetCashFlowTotalsByPeriodParams,
) => {
  return [`/v0/reports/cash-flow-totals`, ...(params ? [params] : [])] as const;
};

export const getGetCashFlowTotalsByPeriodQueryOptions = <
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCashFlowTotalsByPeriodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>
  > = ({ signal }) => getCashFlowTotalsByPeriod(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCashFlowTotalsByPeriodQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>
>;
export type GetCashFlowTotalsByPeriodQueryError = TealErrorResponse;

export function useGetCashFlowTotalsByPeriod<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowTotalsByPeriod<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowTotalsByPeriod<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List cash flow totals by period
 */

export function useGetCashFlowTotalsByPeriod<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCashFlowTotalsByPeriodQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List cash flow totals by period
 */
export const prefetchGetCashFlowTotalsByPeriod = async <
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetCashFlowTotalsByPeriodQueryOptions(
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCashFlowTotalsByPeriodSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCashFlowTotalsByPeriodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>
  > = ({ signal }) => getCashFlowTotalsByPeriod(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCashFlowTotalsByPeriodSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>
>;
export type GetCashFlowTotalsByPeriodSuspenseQueryError = TealErrorResponse;

export function useGetCashFlowTotalsByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowTotalsByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowTotalsByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List cash flow totals by period
 */

export function useGetCashFlowTotalsByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCashFlowTotalsByPeriodSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a list of cash flow data segmented by ledger.
 * @summary List cash flow totals by ledger
 */
export const getCashFlowTotalsByLedger = (
  params: GetCashFlowTotalsByLedgerParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<CashFlowTotals>({
    url: `/v0/reports/ledger-cash-flow-totals`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetCashFlowTotalsByLedgerQueryKey = (
  params: GetCashFlowTotalsByLedgerParams,
) => {
  return [
    `/v0/reports/ledger-cash-flow-totals`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCashFlowTotalsByLedgerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCashFlowTotalsByLedgerQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>
  > = ({ signal }) => getCashFlowTotalsByLedger(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCashFlowTotalsByLedgerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>
>;
export type GetCashFlowTotalsByLedgerQueryError = TealErrorResponse;

export function useGetCashFlowTotalsByLedger<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowTotalsByLedger<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowTotalsByLedger<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List cash flow totals by ledger
 */

export function useGetCashFlowTotalsByLedger<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCashFlowTotalsByLedgerQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List cash flow totals by ledger
 */
export const prefetchGetCashFlowTotalsByLedger = async <
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetCashFlowTotalsByLedgerQueryOptions(
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetCashFlowTotalsByLedgerSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCashFlowTotalsByLedgerQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>
  > = ({ signal }) => getCashFlowTotalsByLedger(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCashFlowTotalsByLedgerSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>
>;
export type GetCashFlowTotalsByLedgerSuspenseQueryError = TealErrorResponse;

export function useGetCashFlowTotalsByLedgerSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowTotalsByLedgerSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetCashFlowTotalsByLedgerSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List cash flow totals by ledger
 */

export function useGetCashFlowTotalsByLedgerSuspense<
  TData = Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
  TError = TealErrorResponse,
>(
  params: GetCashFlowTotalsByLedgerParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getCashFlowTotalsByLedger>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCashFlowTotalsByLedgerSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Lists the top expenses for the business within the period.
 * @summary List top expenses
 */
export const getTopExpenses = (
  params: GetTopExpensesParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<TopExpenses>({
    url: `/v0/reports/top-expenses`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetTopExpensesQueryKey = (params: GetTopExpensesParams) => {
  return [`/v0/reports/top-expenses`, ...(params ? [params] : [])] as const;
};

export const getGetTopExpensesQueryOptions = <
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTopExpenses>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopExpensesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTopExpenses>>> = ({
    signal,
  }) => getTopExpenses(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTopExpenses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTopExpensesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTopExpenses>>
>;
export type GetTopExpensesQueryError = TealErrorResponse;

export function useGetTopExpenses<
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTopExpenses>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTopExpenses>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTopExpenses<
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTopExpenses>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTopExpenses>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTopExpenses<
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTopExpenses>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List top expenses
 */

export function useGetTopExpenses<
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTopExpenses>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTopExpensesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List top expenses
 */
export const prefetchGetTopExpenses = async <
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTopExpenses>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetTopExpensesQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetTopExpensesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTopExpenses>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopExpensesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTopExpenses>>> = ({
    signal,
  }) => getTopExpenses(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getTopExpenses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTopExpensesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTopExpenses>>
>;
export type GetTopExpensesSuspenseQueryError = TealErrorResponse;

export function useGetTopExpensesSuspense<
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTopExpenses>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTopExpensesSuspense<
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTopExpenses>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTopExpensesSuspense<
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTopExpenses>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List top expenses
 */

export function useGetTopExpensesSuspense<
  TData = Awaited<ReturnType<typeof getTopExpenses>>,
  TError = TealErrorResponse,
>(
  params: GetTopExpensesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTopExpenses>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTopExpensesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Lists net profit calculations by period.
 * @summary List net profit by period
 */
export const getNetProfitByPeriod = (
  params: GetNetProfitByPeriodParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<NetProfit>({
    url: `/v0/reports/net-profit-by-period`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetNetProfitByPeriodQueryKey = (
  params: GetNetProfitByPeriodParams,
) => {
  return [
    `/v0/reports/net-profit-by-period`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetNetProfitByPeriodQueryOptions = <
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetNetProfitByPeriodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNetProfitByPeriod>>
  > = ({ signal }) => getNetProfitByPeriod(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNetProfitByPeriod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNetProfitByPeriodQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNetProfitByPeriod>>
>;
export type GetNetProfitByPeriodQueryError = TealErrorResponse;

export function useGetNetProfitByPeriod<
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getNetProfitByPeriod>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNetProfitByPeriod<
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getNetProfitByPeriod>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNetProfitByPeriod<
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List net profit by period
 */

export function useGetNetProfitByPeriod<
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetNetProfitByPeriodQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List net profit by period
 */
export const prefetchGetNetProfitByPeriod = async <
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetNetProfitByPeriodQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetNetProfitByPeriodSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetNetProfitByPeriodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getNetProfitByPeriod>>
  > = ({ signal }) => getNetProfitByPeriod(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getNetProfitByPeriod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNetProfitByPeriodSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNetProfitByPeriod>>
>;
export type GetNetProfitByPeriodSuspenseQueryError = TealErrorResponse;

export function useGetNetProfitByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNetProfitByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetNetProfitByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List net profit by period
 */

export function useGetNetProfitByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getNetProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetNetProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getNetProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetNetProfitByPeriodSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Lists gross profit calculations by period.
 * @summary List gross profit by period
 */
export const getGrossProfitByPeriod = (
  params: GetGrossProfitByPeriodParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<GrossProfit>({
    url: `/v0/reports/gross-profit-by-period`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetGrossProfitByPeriodQueryKey = (
  params: GetGrossProfitByPeriodParams,
) => {
  return [
    `/v0/reports/gross-profit-by-period`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetGrossProfitByPeriodQueryOptions = <
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGrossProfitByPeriodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGrossProfitByPeriod>>
  > = ({ signal }) => getGrossProfitByPeriod(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGrossProfitByPeriodQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGrossProfitByPeriod>>
>;
export type GetGrossProfitByPeriodQueryError = TealErrorResponse;

export function useGetGrossProfitByPeriod<
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrossProfitByPeriod<
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrossProfitByPeriod<
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List gross profit by period
 */

export function useGetGrossProfitByPeriod<
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetGrossProfitByPeriodQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List gross profit by period
 */
export const prefetchGetGrossProfitByPeriod = async <
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetGrossProfitByPeriodQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetGrossProfitByPeriodSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGrossProfitByPeriodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGrossProfitByPeriod>>
  > = ({ signal }) => getGrossProfitByPeriod(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGrossProfitByPeriodSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGrossProfitByPeriod>>
>;
export type GetGrossProfitByPeriodSuspenseQueryError = TealErrorResponse;

export function useGetGrossProfitByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrossProfitByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGrossProfitByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List gross profit by period
 */

export function useGetGrossProfitByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetGrossProfitByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGrossProfitByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetGrossProfitByPeriodSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Lists financial summaries by period.
 * @summary List financial summaries by period
 */
export const getFinancialSummariesByPeriod = (
  params: GetFinancialSummariesByPeriodParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<FinancialSummary>({
    url: `/v0/reports/financial-summaries-by-period`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetFinancialSummariesByPeriodQueryKey = (
  params: GetFinancialSummariesByPeriodParams,
) => {
  return [
    `/v0/reports/financial-summaries-by-period`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetFinancialSummariesByPeriodQueryOptions = <
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetFinancialSummariesByPeriodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>
  > = ({ signal }) => getFinancialSummariesByPeriod(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFinancialSummariesByPeriodQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>
>;
export type GetFinancialSummariesByPeriodQueryError = TealErrorResponse;

export function useGetFinancialSummariesByPeriod<
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFinancialSummariesByPeriod<
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFinancialSummariesByPeriod<
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List financial summaries by period
 */

export function useGetFinancialSummariesByPeriod<
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFinancialSummariesByPeriodQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List financial summaries by period
 */
export const prefetchGetFinancialSummariesByPeriod = async <
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetFinancialSummariesByPeriodQueryOptions(
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetFinancialSummariesByPeriodSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetFinancialSummariesByPeriodQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>
  > = ({ signal }) => getFinancialSummariesByPeriod(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFinancialSummariesByPeriodSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>
>;
export type GetFinancialSummariesByPeriodSuspenseQueryError = TealErrorResponse;

export function useGetFinancialSummariesByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFinancialSummariesByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetFinancialSummariesByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List financial summaries by period
 */

export function useGetFinancialSummariesByPeriodSuspense<
  TData = Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
  TError = TealErrorResponse,
>(
  params: GetFinancialSummariesByPeriodParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getFinancialSummariesByPeriod>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetFinancialSummariesByPeriodSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a list of ledger statements for all ledgers and sub-ledgers in the
chart of accounts within the period. Instances with a large chart of accounts
or many transactions may encounter slower processing times.
 * @summary Retrieve the General Ledger Report
 */
export const getGeneralLedgerReport = (
  params: GetGeneralLedgerReportParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<GeneralLedgerReport>({
    url: `/v0/reports/general-ledger`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetGeneralLedgerReportQueryKey = (
  params: GetGeneralLedgerReportParams,
) => {
  return [`/v0/reports/general-ledger`, ...(params ? [params] : [])] as const;
};

export const getGetGeneralLedgerReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGeneralLedgerReportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeneralLedgerReport>>
  > = ({ signal }) => getGeneralLedgerReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGeneralLedgerReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeneralLedgerReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeneralLedgerReport>>
>;
export type GetGeneralLedgerReportQueryError = TealErrorResponse;

export function useGetGeneralLedgerReport<
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGeneralLedgerReport>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGeneralLedgerReport<
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGeneralLedgerReport>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGeneralLedgerReport<
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve the General Ledger Report
 */

export function useGetGeneralLedgerReport<
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetGeneralLedgerReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve the General Ledger Report
 */
export const prefetchGetGeneralLedgerReport = async <
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetGeneralLedgerReportQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetGeneralLedgerReportSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGeneralLedgerReportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGeneralLedgerReport>>
  > = ({ signal }) => getGeneralLedgerReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getGeneralLedgerReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGeneralLedgerReportSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGeneralLedgerReport>>
>;
export type GetGeneralLedgerReportSuspenseQueryError = TealErrorResponse;

export function useGetGeneralLedgerReportSuspense<
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGeneralLedgerReportSuspense<
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetGeneralLedgerReportSuspense<
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve the General Ledger Report
 */

export function useGetGeneralLedgerReportSuspense<
  TData = Awaited<ReturnType<typeof getGeneralLedgerReport>>,
  TError = TealErrorResponse,
>(
  params: GetGeneralLedgerReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getGeneralLedgerReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetGeneralLedgerReportSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}
