import { memo } from 'react';
import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function SearchIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M14.71 13.235L11.04 9.56501C11.75 8.54501 12.14 7.32501 12.14 6.07501C12.14 2.72501 9.42001 0.00500488 6.07001 0.00500488C2.72001 0.00500488 0.0100098 2.725 0.0100098 6.065C0.0100098 9.405 2.73001 12.125 6.07001 12.125C7.32001 12.125 8.54001 11.735 9.56001 11.025L13.23 14.695C13.43 14.895 13.7 14.995 13.96 14.995C14.22 14.995 14.49 14.895 14.7 14.695C15.1 14.285 15.1 13.635 14.7 13.225L14.71 13.235ZM10.07 6.07501C10.07 8.27501 8.28001 10.065 6.08001 10.065C3.88001 10.065 2.09001 8.27501 2.09001 6.07501C2.09001 3.87501 3.88001 2.085 6.08001 2.085C8.28001 2.085 10.07 3.87501 10.07 6.07501Z" />
    </svg>
  );
}

export default memo(SearchIcon);
