import { create } from 'zustand';

interface ConfirmationDialogOptions {
  title: string;
  modalTitle?: string;
  description: string;
  confirmText?: string;
  cancelText?: string;
  confirmVariant?: 'primary' | 'destructive';
  onConfirm?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
}

interface DialogState {
  isOpen: boolean;
  options: ConfirmationDialogOptions | null;
  resolve: ((value: boolean) => void) | null;
  isLoading: boolean;
  confirm: (options: ConfirmationDialogOptions) => Promise<boolean>;
  handleConfirm: () => Promise<void>;
  handleCancel: () => Promise<void>;
}

export const useConfirmationStore = create<DialogState>((set, get) => ({
  isOpen: false,
  options: null,
  resolve: null,
  isLoading: false,

  confirm: (options: ConfirmationDialogOptions) => {
    return new Promise<boolean>((resolve) => {
      set({
        isOpen: true,
        options,
        resolve,
      });
    });
  },

  handleConfirm: async () => {
    const { resolve, options } = get();

    try {
      set({ isLoading: true });

      if (options?.onConfirm) {
        await options.onConfirm();
      }

      if (resolve) {
        resolve(true);
      }
    } catch (error) {
      console.error('Confirmation handler failed:', error);
      if (resolve) {
        resolve(false);
      }
    } finally {
      set({
        isOpen: false,
        options: null,
        resolve: null,
        isLoading: false,
      });
    }
  },

  handleCancel: async () => {
    const { resolve, options } = get();

    try {
      set({ isLoading: true });

      if (options?.onCancel) {
        await options.onCancel();
      }

      if (resolve) {
        resolve(false);
      }
    } catch (error) {
      console.error('Cancel handler failed:', error);
      if (resolve) {
        resolve(false);
      }
    } finally {
      set({
        isOpen: false,
        options: null,
        resolve: null,
        isLoading: false,
      });
    }
  },
}));
