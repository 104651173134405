import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getMode, getResolver } from '@/components/form-wizard/helpers/form';
import { buildHashSteps, resolveHashStep, updateHash } from '@/components/form-wizard/helpers/hash';
import { useWizardStore } from '@/components/form-wizard/wizard/store';

import type { WizardProps } from '../types';

function Wizard({ steps, onCompleted, onStepChanged, enableHash, header, wrapper, className, footer }: WizardProps) {
  const store = useWizardStore();
  const { activeStep, values } = store;
  const hashes = useMemo(() => {
    return enableHash ? buildHashSteps(steps) : {};
  }, [enableHash, steps]);

  const defaultValues = useMemo(() => {
    return values[activeStep.id] || activeStep.initialValues || {};
  }, [activeStep, values]);

  const methods = useForm({
    defaultValues,
    mode: getMode(activeStep),
    resolver: getResolver(activeStep, values),
    shouldUnregister: true
  });

  useEffect(() => {
    store.setSteps(steps);
    store.setCallbacks({ onCompleted, onStepChanged });
    store.setActiveStep(resolveHashStep(hashes) || steps[0]);
  }, []);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!enableHash) return;

    const handleHashChange = () => {
      const step = resolveHashStep(hashes);
      if (step.id === activeStep.id) return;
      store.setActiveStep(step);
    };

    window.addEventListener('hashchange', handleHashChange);
    updateHash(hashes, activeStep);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [activeStep, enableHash, hashes]);

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={methods.handleSubmit(store.handleNext)}>
        {header}
        {wrapper || activeStep.component}
        {footer}
      </form>
    </FormProvider>
  );
}

export default Wizard;
