import type { ComponentPropsWithoutRef } from 'react';
import CareLoveSupport from '@/assets/icons/suggestion/care-love-support';
import NumberAnalysis from '@/assets/icons/suggestion/number-analysis';
import Paperwork from '@/assets/icons/suggestion/paperwork';
import TaxCompliment from '@/assets/icons/suggestion/tax-compliment';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import ActionsObserver from '@/lib/actions-observer';
import { cn } from '@/lib/utils';
import { useBoolean } from '@/hooks/use-boolean';
import useSendMessage from '@/components/milo-input/use-send-message';

export type SuggestionCategory = 'manage_finances' | 'know_your_finances' | 'customer_care' | 'tax_ready' | 'setup';
export type Suggestion = {
  category: SuggestionCategory;
  name: string;
  description: string;
  firstMsg: string;
};
export type SuggestionProps = {
  suggestion: Suggestion;
  conversationId?: string;
  className?: string;
  onClick?: () => void;
} & VariantProps<typeof SuggestionVariants>;

export type ActivityItemTypes = 'ask' | 'operations' | 'update' | 'setup';

const activityMap: Record<SuggestionCategory, ActivityItemTypes> = {
  tax_ready: 'ask',
  customer_care: 'ask',
  know_your_finances: 'ask',
  manage_finances: 'operations',
  setup: 'setup'
};

const SuggestionVariants = cva(
  'bg-neutral-200 flex max-w-72 rounded-xl py-5 px-6 hover:bg-primary-300 cursor-pointer',
  {
    variants: {
      variant: {
        default: ''
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export default function Suggestion({
  suggestion,
  variant = 'default',
  conversationId,
  className,
  onClick
}: SuggestionProps) {
  const loading = useBoolean(false);
  const navigate = useNavigate();
  const { location } = useRouterState();
  const { sendMessage } = useSendMessage({ conversationId: conversationId });
  return (
    <div
      className={cn(SuggestionVariants({ variant, className }), {
        'pointer-events-none': loading.value
      })}
      onClick={() => {
        if (onClick) {
          onClick();
          return;
        } else {
          loading.onTrue();
          ActionsObserver.notify('SUGGESTION_CLICKED', { suggestion });
          sendMessage(
            {
              message: suggestion.firstMsg,
              activityType: activityMap[suggestion.category],
              activitySubject: suggestion.name,
              activityCategory: suggestion.category
            },
            cData => {
              if (location.pathname !== `/conversation/${cData.conversationId}`) {
                navigate({
                  to: `/conversation/$id`,
                  params: {
                    id: cData.conversationId
                  },
                  state: {
                    msgs: cData.msgs
                  }
                });
              }
            }
          );
        }
      }}
    >
      <div
        className={cn({
          'pointer-events-none': loading.value
        })}
      >
        <div className={cn('text-xs text-left')}>{suggestion.description}</div>
      </div>
    </div>
  );
}
