import { AxiosError, AxiosInstance, AxiosProgressEvent } from 'axios';
import { router } from 'react-query-kit';

export const getFileQueries = (axiosInstance: AxiosInstance) =>
  router('files', {
    getSignedUrl: router.query<string, { id: number | string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/files/${variables.id}`).then(res => res.data)
    }),
    signAndUpload: router.mutation<
      string,
      {
        file: File;
        onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
      },
      AxiosError
    >({
      mutationFn: async ({ file, onUploadProgress }) => {
        const {
          data: { signedUrl, fileId }
        } = await axiosInstance.post<{ signedUrl: string; fileId: string }>(`/files/get-signed-url`, {
          filePath: file.name,
          fileContentType: file.type
        });
        await axiosInstance.put(signedUrl, file, {
          headers: { 'Content-Type': file.type },
          onUploadProgress
        });
        return fileId;
      }
    }),
    upload: router.mutation<
      void,
      {
        signedUrl: string;
        file: File;
        onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
      },
      AxiosError
    >({
      mutationFn: async ({ signedUrl, file, onUploadProgress }) => {
        await axiosInstance.put(signedUrl, file, {
          headers: { 'Content-Type': file.type },
          onUploadProgress
        });
      }
    }),
    delete: router.mutation<string, { id: string | number }, AxiosError>({
      mutationFn: async variables => axiosInstance.delete<string>(`/files/${variables.id}`).then(res => res.data)
    })
  });
