// src/utils/AmplitudeModule.ts
import * as Amplitude from '@amplitude/analytics-browser';
import { Experiment, ExperimentClient, Variant } from '@amplitude/experiment-js-client';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

type EventInput = string | Amplitude.Types.BaseEvent;
type EventProperties = Record<string, any> | undefined;
type EventOptions = Amplitude.Types.EventOptions | undefined;

class AmplitudeModule {
  private experimentInstance: ExperimentClient | null = null;
  private isInitialized = false;
  private fetchFlagsPromise = Promise.resolve();
  private eventBuffer: Map<string, number> = new Map();

  // Initialize Amplitude with the API key and optional plugins
  async init(apiKey: string, environment: string, sessionReplayEnabled = true) {
    if (!apiKey) {
      console.warn('AmplitudeModule: API Key is required for initialization.');
      return;
    }

    Amplitude.init(apiKey, { autocapture: true });

    if (sessionReplayEnabled) {
      Amplitude.add(sessionReplayPlugin({ debugMode: environment === 'development' }));
    }
    this.isInitialized = true;
    // Initialize Amplitude Experiment (for A/B testing)
    this.experimentInstance = Experiment.initializeWithAmplitudeAnalytics(apiKey);
  }

  private checkInitialization() {
    if (!this.isInitialized) {
      console.warn('AmplitudeModule: Module is not initialized. Call init() first.');
      return false;
    }
    return true;
  }

  private isDuplicateEvent(eventInput: EventInput): boolean {
    const eventName = typeof eventInput === 'string' ? eventInput : eventInput.event_type;
    const currentTime = Date.now();
    const lastEventTime = this.eventBuffer.get(eventName);

    if (lastEventTime && currentTime - lastEventTime < 300) {
      return true;
    }

    this.eventBuffer.set(eventName, currentTime);
    return false;
  }

  // Track events with optional properties and options
  track(eventInput: EventInput, eventProperties: EventProperties = {}, eventOptions: EventOptions = {}) {
    const debugMode = window?.location?.search && window?.location?.search?.includes('debugAmplitude=true');
    if (this.isDuplicateEvent(eventInput as string)) {
      if (debugMode) {
        console.log('AmplitudeModule: duplication in track, dropping event', eventInput);
      }
      return;
    }

    if (debugMode) {
      console.log('AmplitudeModule: track', eventInput, eventProperties, eventOptions);
    }
    if (!this.checkInitialization()) return;
    Amplitude.track(eventInput, eventProperties, eventOptions);
  }

  // Identify a user with email and optional properties
  identify(userEmail: string, userProperties: Record<string, string | boolean>) {
    if (!this.checkInitialization()) return;

    Amplitude.setUserId(userEmail);
    const identify = new Amplitude.Identify();

    Object.entries(userProperties).forEach(([key, value]) => {
      identify.set(key, value);
    });

    Amplitude.identify(identify);
    this.fetchFlagsPromise = this.experimentInstance!.start();
  }

  // Set additional properties on a user
  setPropertiesOnUser(properties: Record<string, string | boolean>) {
    if (!this.checkInitialization()) return;

    const identify = new Amplitude.Identify();
    Object.entries(properties).forEach(([key, value]) => {
      identify.set(key, value);
    });
    Amplitude.identify(identify);

    this.fetchFlagsPromise = this.experimentInstance!.fetch(properties) as Promise<any>;
  }

  // Retrieve an experiment variant for a given key
  async variant<T>(key: string, defaultVal: T): Promise<T> {
    if (!this.checkInitialization()) return defaultVal;
    await this.fetchFlagsPromise;
    const variant = this.experimentInstance?.variant(key, defaultVal as Variant);
    if (variant?.value) {
      return { ...defaultVal, ...(variant?.payload ?? {}) } as T;
    }
    return defaultVal;
  }
}

// Export a singleton instance of the AmplitudeModule
const amplitudeModule = new AmplitudeModule();
export default amplitudeModule;
