// @ts-nocheck
let initialized = false;
let debug = false;

/**
 * Utilities
 */

const warn = ({ args }: { args?: any[] }) => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...['[react-facebook-pixel]'].concat(args));
};

const log = ({ args }: { args?: any[] }) => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...['[react-facebook-pixel]'].concat(args));
};

const verifyInit = () => {
  if (!initialized) {
    warn({ args: ['Pixel not initialized before using call ReactPixel.init with required params'] });
  }
  return initialized;
};

//
const defaultOptions = {
  autoConfig: true,
  debug: false
};

//
export default {
  init(pixelId: string, advancedMatching = {}, options = defaultOptions) {
    initialized = typeof window !== 'undefined' && !!window.fbq;

    /* eslint-disable */
    /* ts-ignore */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    /* eslint-enable */
    /* eslint-enable */

    if (!pixelId) {
      warn({ args: ['Please insert pixel id for initializing'] });
    } else {
      if (!options.autoConfig) {
        window.fbq('set', 'autoConfig', false, pixelId); // eslint-disable-line no-undef
      }

      window.fbq('init', pixelId, advancedMatching); // eslint-disable-line no-undef

      initialized = true;
      debug = options.debug;
    }
  },

  pageView() {
    if (!verifyInit()) {
      return;
    }

    window.fbq('track', 'PageView'); // eslint-disable-line no-undef

    if (debug) {
      log({ args: ["called fbq('track', 'PageView');"] });
    }
  },

  track(event: string, data?: Data | any) {
    if (!verifyInit()) {
      return;
    }

    window.fbq('track', event, data); // eslint-disable-line no-undef

    if (debug) {
      log({ args: [`called fbq('track', '${event}');`] });

      if (data) {
        log({ args: ['with data', data] });
      }
    }
  },

  trackSingle(pixel: string, event: string, data?: Data | any) {
    if (!verifyInit()) {
      return;
    }

    window.fbq('trackSingle', pixel, event, data); // eslint-disable-line no-undef

    if (debug) {
      log({ args: [`called fbq('trackSingle', '${pixel}', '${event}');`] });

      if (data) {
        log({ args: ['with data', data] });
      }
    }
  },

  trackCustom(event: string, data?: Data | any) {
    if (!verifyInit()) {
      return;
    }

    window.fbq('trackCustom', event, data); // eslint-disable-line no-undef

    if (debug) {
      log({ args: [`called fbq('trackCustom', '${event}');`] });

      if (data) {
        log({ args: ['with data', data] });
      }
    }
  },

  trackSingleCustom(pixel, event, data) {
    if (!verifyInit()) {
      return;
    }

    window.fbq('trackSingle', pixel, event, data); // eslint-disable-line no-undef

    if (debug) {
      log({ args: [`called fbq('trackSingleCustom', '${pixel}', '${event}');`] });

      if (data) {
        log({ args: ['with data', data] });
      }
    }
  },

  grantConsent() {
    if (!verifyInit()) {
      return;
    }

    window.fbq('consent', 'grant');

    if (debug) {
      log({ args: [`called fbq('consent', 'grant');`] });
    }
  },

  revokeConsent() {
    if (!verifyInit()) {
      return;
    }

    window.fbq('consent', 'revoke');

    if (debug) {
      log({ args: [`called fbq('consent', 'revoke');`] });
    }
  },

  fbq(...args) {
    if (!verifyInit()) {
      return;
    }

    window.fbq(...args); // eslint-disable-line no-undef

    if (debug) {
      log({ args: [`called fbq('${args.slice(0, 2).join("', '")}')`] });

      if (args[2]) {
        log({ args: ['with data', args[2]] });
      }
    }
  }
};
