import { memo } from 'react';

import { cn } from '@/lib/utils';

function HomeIcon({ className, active, ...other }: React.ComponentProps<'svg'> & { active?: boolean }) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M9.26049 0.202772C9.53085 -0.0675907 9.96919 -0.0675907 10.2396 0.202772L18.5473 8.51046C18.7452 8.70846 18.8045 9.00624 18.6973 9.26493C18.5902 9.52363 18.3377 9.69231 18.0577 9.69231H16.9039V15.4615C16.9039 16.8635 15.7674 18 14.3654 18H5.13464C3.73268 18 2.59617 16.8635 2.59617 15.4615V9.69231H1.44233C1.16232 9.69231 0.909875 9.52363 0.802719 9.26493C0.695563 9.00624 0.754794 8.70846 0.952793 8.51046L9.26049 0.202772ZM7.6731 16.6154H11.8269V11.7692C11.8269 11.132 11.3103 10.6154 10.6731 10.6154H8.82694C8.18969 10.6154 7.6731 11.132 7.6731 11.7692V16.6154ZM13.2116 16.6154V11.7692C13.2116 10.3673 12.0751 9.23077 10.6731 9.23077H8.82694C7.42499 9.23077 6.28848 10.3673 6.28848 11.7692V16.6154H5.13464C4.49738 16.6154 3.98079 16.0988 3.98079 15.4615V9C3.98079 8.61765 3.67083 8.30769 3.28848 8.30769H3.11371L9.75002 1.67138L16.3863 8.30769H16.2116C15.8292 8.30769 15.5193 8.61765 15.5193 9V15.4615C15.5193 16.0988 15.0027 16.6154 14.3654 16.6154H13.2116Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default memo(HomeIcon);
