import { useState } from 'react';
import { getTransactionsQueries } from '@repo/features/transactions';
import { getVendorQueries } from '@repo/features/vendors';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi, useNavigate, useParams } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';
import { Input } from '@/components/ui/input';

export type AddNewVendorProps = {};
const routeApi = getRouteApi('/_guarded/library/transactions/$id/vendor');
const transactionQueries = getTransactionsQueries(axiosInstance);
const vendorQueries = getVendorQueries(axiosInstance);

export default function AddNewVendor({}: AddNewVendorProps) {
  const { id } = routeApi.useParams();
  const queryClient = useQueryClient();
  const { isBelowLg } = useBreakpoint('lg');
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const onOpenChange = (_open: boolean) => {
    if (!_open) {
      navigate({
        to: '..'
      });
    }
  };
  const { mutateAsync: createVendor } = vendorQueries.create.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: vendorQueries.getKey()
      });
    }
  });
  const { mutateAsync: updateTransaction, isPending } = transactionQueries.patch.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: transactionQueries.getKey()
      });
      onOpenChange(false);
    }
  });
  const onSave = async () => {
    const newVendor = await createVendor({
      data: {
        name: value,
        legalName: value
      }
    });
    await updateTransaction({
      id,
      data: {
        vendor: newVendor._id
      }
    });
  };
  if (!isBelowLg) {
    return (
      <Dialog onOpenChange={onOpenChange} open>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Add New Vendor</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col justify-center flex-1 p-10 gap-8">
            <Input
              className="text-xs placeholder:text-neutral-500 placeholder:text-xs"
              onChange={({ target }) => {
                setValue(target.value);
              }}
              placeholder="Enter text"
              value={value}
            />
            <div className="flex-1" />
            <Button className="w-full" disabled={!value} size="md">
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer onOpenChange={onOpenChange} open>
      <DrawerContent className="flex flex-col">
        <DrawerHeader className="hidden">
          <DrawerTitle>New Vendor</DrawerTitle>
          <DrawerDescription>Add New Vendor</DrawerDescription>
        </DrawerHeader>
        <div className="flex flex-col justify-center flex-1 p-8 gap-8">
          <div className="text-center">
            <div className="text-md font-bold">Add New Vendor</div>
          </div>
          <Input
            className="text-xs placeholder:text-neutral-500 placeholder:text-xs"
            onChange={({ target }) => {
              setValue(target.value);
            }}
            placeholder="Enter text"
            value={value}
          />
          <div className="flex-1" />
        </div>
        <DrawerFooter className="p-8 pt-0">
          <Button className="w-full" disabled={!value} isLoading={isPending} onClick={onSave} size="md">
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
