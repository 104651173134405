import * as React from 'react';
import { Fragment, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { useAuth } from '@/auth/hooks';
import { getCashFlowDrawerSuggestions } from '@/mock/suggestions';
import { useAppStateStore } from '@/stores/app-state';
import { fCurrency } from '@/utils/format-number';
import { fDate } from '@/utils/format-time';
import { getAccountingDataQueries } from '@repo/features/accounting-data';
import { getFinancialQueries } from '@repo/features/financial-data';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { format, subMonths } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';
import DayPicker from '@/components/ui/daypicker';
import { Drawer, DrawerContent, DrawerOverlay, DrawerTitle, DrawerTrigger } from '@/components/ui/drawer';
import { Separator } from '@/components/ui/separator';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import ConnectAccountButton from '@/components/connect-account-button';
import DrawerSearchHeader from '@/components/drawer-search-header';

import { CashflowNumberChart } from './number-chart';

export interface CashBalanceDrawerProps {
  isOpen?: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

const routeApi = getRouteApi('/_guarded/finances');
const financialQueries = getFinancialQueries(axiosInstance);
const accountingQueries = getAccountingDataQueries(axiosInstance);

function LoadingValue() {
  return <span className="text-gray-400">Loading...</span>;
}
function NotAvailable() {
  return <span className="text-gray-400">N/A</span>;
}

function AccountItem({ name, amount }: { name: string; amount: number }) {
  return (
    <div className="flex justify-between items-center">
      <div className="text-3xs font-semibold">{name}</div>
      <div className="text-md font-bold">{fCurrency(amount)}</div>
    </div>
  );
}

export default function CashFlowDrawer({ isOpen, onOpenChange }: CashBalanceDrawerProps) {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [isActive, accountsConnected, financialState, isPlaidModalOpen] = useAppStateStore(
    useShallow(state => [state.isActive, state.accountsConnected, state.financialState, state.isPlaidModalOpen])
  );
  const [date, setDate] = useState<Date>(new Date());
  const {
    data: accountsReceivable = [],
    isLoading: isLoadingAR,
    isError: isARError
  } = useQuery({
    queryKey: ['accountingArExpected', date],
    queryFn: () =>
      accountingQueries.accountingArExpected.fetcher({
        date: format(date, 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5
  });

  const {
    data: accountsPayable = [],
    isLoading: isLoadingAP,
    isError: isAPError
  } = useQuery({
    queryKey: ['accountingApExpected', date],
    queryFn: () =>
      accountingQueries.accountingApExpected.fetcher({
        date: format(date, 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5
  });

  const {
    data: accountBalances,
    isLoading: isLoadingBalances,
    isError: isBalancesError
  } = useQuery({
    queryKey: ['financialBalanceStatus', date],
    queryFn: () =>
      financialQueries.balanceStatus.fetcher({
        date: format(date, 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5
  });

  const { data: expectedBalanceResponse, isError } = useQuery({
    queryKey: ['financialExpectedBalance', date],
    queryFn: () =>
      financialQueries.expectedBalance.fetcher({
        date: format(date, 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5 // Cache the result for 5 minutes
  });

  // Extract expected end-of-month balance from the API data
  // Using the expected_end_of_period from the first result in the array

  const {
    data: cashBalanceData,
    isLoading: isLoadingCash,
    isError: isCashError
  } = useQuery({
    queryKey: ['financialCashBalance', date],
    queryFn: () =>
      financialQueries.cashBalance.fetcher({
        date: format(date, 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5
  });

  const accounts = isLoadingBalances
    ? []
    : (accountBalances?.data.map(account => ({
        ledger_id: account.financial_account_id,
        name: account.financial_account_name,
        amount: account.balance
      })) ?? []);

  // These will be implemented in next iteration
  const accountReceivableAmount = accountsReceivable.reduce((sum, item) => sum + item.balance, 0) ?? 0;
  const accountPayableAmount = accountsPayable.reduce((sum, item) => sum + item.balance, 0) ?? 0;

  const { data: monthlyCashFlow = { data: [] }, isLoading } = useQuery({
    queryKey: ['monthlyCashflow', subMonths(date, 6), format(date, 'yyyy-MM-dd')],
    queryFn: () =>
      financialQueries.monthlyCashflow.fetcher({
        startDate: format(subMonths(date, 6), 'yyyy-MM-dd'),
        endDate: format(date, 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5
  });

  const cashflowChartData =
    monthlyCashFlow.data.map(item => ({
      name: item.month_name_year,
      inflow: item.money_in,
      outflow: item.money_out
    })) || [];
  const suggestions = getCashFlowDrawerSuggestions({ isActive, accountsConnected });

  useEffect(() => {
    if (isPlaidModalOpen && isOpen) {
      document.body.style.pointerEvents = 'auto';
    }
  }, [isPlaidModalOpen, isOpen]);

  const renderHeader = (
    <DrawerSearchHeader
      onBackClick={() => {
        onOpenChange(false);
      }}
    />
  );

  const renderIntro = (
    <div className="flex flex-col gap-8">
      <div className="">
        <DrawerTitle className="text-xl font-semibold">Cash Flow</DrawerTitle>
      </div>
      <DayPicker
        className="w-full lg:w-80"
        onSelect={d => {
          setDate(d!);
        }}
        selected={date}
      />
      <div className="text-sm font-medium">
        Here&apos;s a look at your cash situation. There are some interesting trends we should discuss. Take a look at
        the key numbers below, and shoot me a message here with any questions. I&apos;m here to simplify and translate
        these figures for you!
      </div>
    </div>
  );

  const renderCurrentBalance = (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold">Current Balance</div>
      <div className="text-sm font-medium">
        Here is what you have TODAY({fDate(new Date())}) across all connected accounts:
      </div>
      <div className="text-xl font-semibold">
        {!accountsConnected ? (
          <NotAvailable />
        ) : isLoadingCash ? (
          <LoadingValue />
        ) : isCashError ? (
          'Error loading balance'
        ) : (
          fCurrency(cashBalanceData?.data.sum_total_balance ?? 0)
        )}
      </div>
    </div>
  );

  const renderAccounts = (
    <>
      <div className="flex flex-col gap-4">
        <div className="text-lg font-bold">Accounts</div>
        <div className="text-sm font-medium">Here is your current balance broken down by accounts:</div>
        <div>
          <ConnectAccountButton
            successCB={() => {
              queryClient.invalidateQueries({ queryKey: ['financialBalanceStatus'] });
              queryClient.invalidateQueries({ queryKey: ['financialCashBalance'] });
            }}
            variant="primary"
          >
            Connect new account
          </ConnectAccountButton>
        </div>
      </div>
      {isLoadingBalances ? (
        <div className="py-4">
          <LoadingValue />
        </div>
      ) : isBalancesError ? (
        <div className="text-sm text-red-500">Error loading account balances</div>
      ) : accounts.length > 0 ? (
        <div className="grid grid-cols-1 gap-y-6">
          {accounts.map(record => (
            <Fragment key={record.ledger_id}>
              <AccountItem amount={record.amount} name={record.name} />
              <Separator />
            </Fragment>
          ))}
        </div>
      ) : (
        <div className="text-sm text-gray-500">No accounts found</div>
      )}
    </>
  );

  const renderChart = (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold">CashFlow Trend</div>
      <div className="text-sm font-medium">This is what your account trend looks like over the past 6 months</div>
      <Suspense fallback={null}>
        <CashflowNumberChart data={cashflowChartData} />
      </Suspense>
    </div>
  );

  const renderExpectedBalance = (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold">Expected Balance</div>
      <div className="text-sm font-medium">
        {isLoadingCash ? (
          <LoadingValue />
        ) : !accountPayableAmount && !accountReceivableAmount ? (
          `I don't see any recorded invoices coming up from customers (account receivables) or vendor bills due till end of ${fDate(new Date(), 'MMMM')}`
        ) : (
          `Expected balance for end of ${fDate(new Date(), 'MMMM')}:`
        )}
      </div>
      <div className="text-xl font-semibold">
        {!accountsConnected ? (
          <NotAvailable />
        ) : isLoadingCash ? (
          <LoadingValue />
        ) : (
          fCurrency(expectedBalanceResponse?.data['0']?.expected_end_of_period ?? 0)
        )}
      </div>
    </div>
  );
  const renderExpectedIn = (
    <div className="flex flex-col gap-4">
      <div className="text-md font-bold">Expected In</div>
      <div className="text-sm font-medium">
        Taking into account what customer owe you based on recorded invoices (account receivables) due till end of{' '}
        {fDate(new Date(), 'MMMM')}
      </div>
      <div>
        <Table className="border-[1.5px] border-neutral-300 text-xs font-medium max-w-[700px]">
          <TableHeader className="font-semibold">
            <TableRow className="bg-neutral-200">
              <TableHead className="font-bold text-black">Customer</TableHead>
              <TableHead className="font-bold text-black">Total</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoadingAR ? (
              <TableRow>
                <TableCell className="text-center" colSpan={2}>
                  <LoadingValue />
                </TableCell>
              </TableRow>
            ) : (
              accountsReceivable.map(ar => (
                <TableRow key={ar.customer_name}>
                  <TableCell className="font-medium">{ar.customer_name}</TableCell>
                  <TableCell>{fCurrency(ar.balance)}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter className="font-semibold bg-neutral-200">
            <TableRow>
              <TableCell colSpan={1}>Total</TableCell>
              <TableCell>{isLoadingAR ? <LoadingValue /> : fCurrency(accountReceivableAmount)}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );

  const renderExpectedOut = (
    <div className="flex flex-col gap-4">
      <div className="text-md font-bold">Expected Out</div>
      <div className="text-sm font-medium">
        Taking into account what you owe, upcoming bills recorded (account payable) due till end of{' '}
        {fDate(new Date(), 'MMMM')}
      </div>
      <div className="max-w-[700px]">
        <Table className="border-[1.5px] border-neutral-300 text-xs font-medium">
          <TableHeader className="font-semibold">
            <TableRow className="bg-neutral-200">
              <TableHead className="font-bold text-black">Supplier</TableHead>
              <TableHead className="font-bold text-black">Total</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoadingAP ? (
              <TableRow>
                <TableCell className="text-center" colSpan={2}>
                  <LoadingValue />
                </TableCell>
              </TableRow>
            ) : (
              accountsPayable.map(ap => (
                <TableRow key={ap.vendor_name}>
                  <TableCell>{ap.vendor_name}</TableCell>
                  <TableCell>{fCurrency(ap.balance)}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter className="font-semibold bg-neutral-200">
            <TableRow>
              <TableCell colSpan={1}>Total</TableCell>
              <TableCell>{isLoadingAP ? <LoadingValue /> : fCurrency(accountPayableAmount)}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
  return (
    <Drawer
      direction="right"
      onClose={() => {
        document.body.style.pointerEvents = 'auto';
      }}
      onOpenChange={onOpenChange}
      onRelease={() => {
        console.log('onRelease');
      }}
      open={isOpen}
    >
      <DrawerContent
        className="lg:rounded-tl-lg lg:rounded-bl-lg h-full w-full lg:w-[43.75rem] right-0 left-auto rounded-none border-none focus-visible:outline-none"
        hideHandle
      >
        {renderHeader}
        <div className="h-auto flex flex-col w-full overflow-y-auto relative px-10 pb-10 lg:px-14 lg:pb-14 gap-14">
          {renderIntro}
          <Separator />
          {renderCurrentBalance}
          {renderAccounts}
          {accountsConnected ? renderChart : null}
          <Separator />
          {renderExpectedBalance}
          {accountsConnected && accountsReceivable.length > 0 ? renderExpectedIn : null}
          {accountsConnected && accountsPayable.length > 0 ? renderExpectedOut : null}
        </div>
      </DrawerContent>
    </Drawer>
  );
}
