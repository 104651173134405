import { cn } from '@/lib/utils';

export type BetaIndicatorProps = {} & React.HTMLAttributes<HTMLDivElement>;

export default function BetaIndicator({ className }: BetaIndicatorProps) {
  return (
    <div className={cn('inline-flex items-center justify-center rounded-sm bg-[#FF6F67] px-2.5 py-0.5', className)}>
      <span className="mr-[-0.3125rem] text-right font-archivo text-[0.375rem] font-bold uppercase tracking-[0.3125rem] text-white">
        beta
      </span>
    </div>
  );
}
