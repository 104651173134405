import JumpingDog from '@/assets/lottie/jumping-dog';
import { useAuth } from '@/auth/hooks';
import { useAppStateStore } from '@/stores/app-state';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';
import ConnectAccountButton from '@/components/connect-account-button';

export type ConnectAccountVariantProps = {};

export default function ConnectAccountVariant({}: ConnectAccountVariantProps) {
  const { user } = useAuth();
  const [activate] = useAppStateStore(useShallow(state => [state.activate]));
  return (
    <div className="flex flex-col px-12 gap-4 lg:gap-12 lg:px-0">
      <div className="rotate-y-180 w-20 lg:w-32">
        <JumpingDog
          style={{
            transform: 'rotateY(180deg)'
          }}
        />
      </div>

      <div className="text-[1.75rem] leading-[120%] lg:text-3xl lg:leading-[110%] text-white">
        <div className="mt-4">
          <div>
            <MotionTextRevealContainer transition={{ duration: 0.5 }} value={`Hey ${user?.firstName || ''}`} />
          </div>
          <div>
            <MotionTextRevealContainer
              transition={{ duration: 0.5 }}
              value="Connect your financial accounts to unleash &Milo powers, get real profit and smarter taxes"
            />
          </div>
        </div>

        <div>
          <div className="text-4xs text-white opacity-75 mt-2 lg:mt-6">
            Connection is secured with Plaid.We do not store your passwords & account information.
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-5 lg:gap-6 lg:flex-row-reverse lg:px-0">
        <ConnectAccountButton className="w-full" variant="secondary">
          Securely Connect Now
        </ConnectAccountButton>
        <Button
          className="w-full"
          onClick={() => {
            activate(true);
          }}
          size="lg"
          variant="outline"
        >
          Remind me later
        </Button>
      </div>
    </div>
  );
}
