import React from 'react';
import ChatCloud from '@/assets/icons/chat-cloud';
import ChevronRightIcon from '@/assets/icons/chevron-right-icon';
import axiosInstance from '@/lib/axios.ts';
import { fToNow } from '@/utils/format-time';
import { getActivityQueries } from '@repo/features/activity';
import type { IConversation } from '@repo/features/conversation';
import { useNavigate } from '@tanstack/react-router';
import { parseISO } from 'date-fns';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

export type ActivityCardProps = {
  conversation: IConversation;
};
const activityQueries = getActivityQueries(axiosInstance);
const ActivityCard: React.FC<ActivityCardProps> = ({ conversation }) => {
  const { data: activityItem, isLoading: isActivityLoading } = activityQueries.byId.useQuery({
    variables: { id: conversation.activityItem },
    enabled: !!conversation.activityItem
  });
  const navigate = useNavigate();
  const lastMessage = conversation.thread.messages[conversation.thread.messages.length - 1];
  const lastMessageSentAgo = lastMessage.createdAt ? fToNow(parseISO(lastMessage.createdAt)) : null;

  if (isActivityLoading) return null;

  return (
    <div
      className="w-full px-10 py-7 cursor-pointer flex justify-between items-center gap-6 hover:bg-primary-200"
      onClick={() =>
        navigate({
          to: `/conversation/$id`,
          params: {
            id: conversation._id
          }
        })
      }
    >
      <div className="flex flex-col gap-3 overflow-hidden">
        <div className="text-xs font-semibold truncate">{activityItem?.subject || ''}</div>
        <div className="">
          {lastMessageSentAgo ? (
            <div className="text-4xs text-neutral-500">Last message {lastMessageSentAgo}</div>
          ) : null}
        </div>
      </div>
      <div>
        <ChevronRightIcon className="fill-black stroke-black" />
      </div>
    </div>
  );
};

// Fallback component to display on error
const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Oops! There is an error with this item</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const ActivityCardWithBoundary: React.FC<ActivityCardProps> = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ActivityCard {...props} />
    </ErrorBoundary>
  );
};

export default ActivityCardWithBoundary;
