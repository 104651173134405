import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import {
  FinancialAccount,
  RegisterCustomFinancialAccountsRequest,
  RegisterPlaidFinancialAccountsRequest
} from './types';

export const getFinancialAccountsQueries = (axiosInstance: AxiosInstance) =>
  router('/financial-accounts', {
    getAll: router.query<{ data: FinancialAccount[] }, void, AxiosError>({
      fetcher: () => axiosInstance.get(`/financial-accounts`).then(({ data }) => data)
    }),
    uploadTransactions: router.mutation<
      { data: { success: boolean; issues: object[] } },
      { financialAccountId: string; fileId: string },
      AxiosError
    >({
      mutationFn: async vars =>
        axiosInstance
          .put(`/financial-accounts/${vars.financialAccountId}/transactions`, { fileId: vars.fileId })
          .then(res => res.data)
    }),
    plaid: {
      connect: router.query<{ data: string }, undefined, AxiosError>({
        fetcher: () => axiosInstance.put('/financial-accounts/plaid/connect').then(({ data }) => data)
      }),
      register: router.mutation<void, RegisterPlaidFinancialAccountsRequest, AxiosError>({
        mutationFn: vars => axiosInstance.post('/financial-accounts/plaid/register', vars).then(({ data }) => data)
      })
    },
    custom: {
      register: router.mutation<any, RegisterCustomFinancialAccountsRequest, AxiosError>({
        mutationFn: vars => axiosInstance.post('/financial-accounts/custom/register', vars).then(({ data }) => data)
      })
    },

    delete: router.mutation<{}, { financialAccountId: string }, void>({
      mutationFn: ({ financialAccountId }) =>
        axiosInstance.delete(`/financial-accounts/${financialAccountId}`).then(({ data }) => data)
    }),

    disable: router.mutation<{}, { financialAccountId: string }, void>({
      mutationFn: ({ financialAccountId }) =>
        axiosInstance.post(`/financial-accounts/${financialAccountId}/disable`).then(({ data }) => data)
    }),

    enable: router.mutation<{}, { financialAccountId: string }, void>({
      mutationFn: ({ financialAccountId }) =>
        axiosInstance.post(`/financial-accounts/${financialAccountId}/enable`).then(({ data }) => data)
    })
  });
