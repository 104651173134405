import { create } from 'zustand';

import type { StepConfig as Step, WizardValues } from '../types';

interface WizardStore {
  // State
  steps: Step[];
  activeStep: Step;
  values: WizardValues;
  isLoading: boolean;
  stepNumber: number;
  totalSteps: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  callbacks: {
    onCompleted?: (values: WizardValues) => void;
    onStepChanged?: (prevStep: Step, nextStep: Step, stepNumber: number, values: WizardValues) => void;
  };
  progress: number;

  // Actions
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToStep: (index: number) => void;
  setSteps: (steps: Step[]) => void;
  setActiveStep: (step: Step, triggerCallback?: boolean) => void;
  setValues: (values: WizardValues) => void;
  setIsLoading: (loading: boolean) => void;
  updateStep: (key: string, value: any) => void;
  handleNext: (stepValues: any) => Promise<void>;
  handlePrevious: (stepValues: any) => Promise<void>;
  setCallbacks: (callbacks: {
    onCompleted?: (values: WizardValues) => void;
    onStepChanged?: (prevStep: Step, nextStep: Step, stepNumber: number, values: WizardValues) => void;
  }) => void;
  calculateProgress: () => number;
  updateProgress: () => void;
}

// Helper function for the store
async function _getProceedingStep(remainingSteps: Step[], values: WizardValues, direction: number) {
  let proceedingStep;
  for (let idx = 0; idx < remainingSteps.length; ++idx) {
    const step = remainingSteps[idx];
    if (step.shouldSkip === undefined) {
      proceedingStep = step;
      break;
    }
    const shouldSkip = await step.shouldSkip(values, direction);
    if (!shouldSkip) {
      proceedingStep = step;
      break;
    }
  }
  return proceedingStep;
}

export const useWizardStore = create<WizardStore>((set, get) => ({
  steps: [],
  activeStep: {} as Step,
  values: {},
  isLoading: false,
  stepNumber: 1,
  totalSteps: 0,
  isFirstStep: true,
  isLastStep: false,
  callbacks: {},
  progress: 0,

  calculateProgress: () => {
    const state = get();

    // Filter out ignored steps
    const progressSteps = state.steps.filter(step => !step.ignoreProgress);
    const totalSteps = progressSteps.length;

    if (totalSteps === 0) return 0;

    // Count only completed non-ignored steps
    // const completedSteps = state.stepNumber - (totalSteps - progressSteps.length);
    const completedSteps = Object.keys(state.values).reduce((count, stepId) => {
      const step = state.steps.find(s => s.id === stepId);
      // Only count if step exists, has values, and is not ignored
      if (step && !step.ignoreProgress && state.values[stepId]) {
        return count + 1;
      }
      return count;
    }, 0);

    // Check if current step should be counted
    // const currentStep = state.steps.find(s => s.id === state.activeStep.id);
    // const shouldCountCurrentStep = !!currentStep && !currentStep.ignoreProgress && !!state.values[currentStep.id];
    // Calculate progress
    const progress = (completedSteps / totalSteps) * 100;

    return Math.min(Math.round(progress), 100);
  },

  updateProgress: () => {
    const progress = get().calculateProgress();
    set({ progress });
  },

  goToNextStep: async (formValues?: any) => {
    await get().handleNext(formValues);
  },

  goToPreviousStep: async (formValues?: any) => {
    await get().handlePrevious(formValues);
  },

  goToStep: (index: number) => {
    get().setActiveStep(get().steps[index]);
  },

  setSteps: steps => {
    set({ steps, totalSteps: steps.length });
  },

  setActiveStep: (step, triggerCallback = false) => {
    const state = get();
    const currentIndex = state.steps.findIndex(s => s.id === step.id);
    const prevStep = state.activeStep;

    if (triggerCallback && state.callbacks.onStepChanged) {
      state.callbacks.onStepChanged(prevStep, step, currentIndex + 1, state.values);
    }

    set({
      activeStep: step,
      stepNumber: currentIndex + 1,
      isFirstStep: currentIndex === 0,
      isLastStep: currentIndex === state.totalSteps - 1
    });
  },

  setValues: values => {
    set({ values });
    get().updateProgress();
  },
  setIsLoading: loading => {
    set({ isLoading: loading });
  },
  updateStep: (key, value) => {
    set(state => ({
      activeStep: { ...state.activeStep, [key]: value }
    }));
  },

  setCallbacks: callbacks => {
    set({ callbacks });
  },

  handleNext: async stepValues => {
    const state = get();
    try {
      if (state.activeStep.onSubmit) {
        set({ isLoading: true });
        stepValues = await state.activeStep.onSubmit(stepValues, state.values);
        set({ isLoading: false });
      }

      const wizardValues = {
        ...state.values,
        [state.activeStep.id]: { ...stepValues } as WizardValues
      };

      set({ values: wizardValues });
      state.updateProgress();

      const currentIndex = state.steps.findIndex(s => s.id === state.activeStep.id);
      const nextStep = await _getProceedingStep(state.steps.slice(currentIndex + 1), wizardValues, 1);

      if (!nextStep) {
        if (state.callbacks.onCompleted) {
          let result = {};
          Object.keys(wizardValues).forEach(stepId => {
            result = { ...result, ...wizardValues[stepId] } as WizardValues;
          });
          state.callbacks.onCompleted(result);
        }
        return;
      }

      state.setActiveStep(nextStep, true);
    } catch (error) {
      console.error(error);
      set({ isLoading: false });
    }
  },

  handlePrevious: async stepValues => {
    const state = get();
    let wizardValues = state.values;

    if (state.activeStep.keepValuesOnPrevious ?? true) {
      wizardValues = {
        ...state.values,
        [state.activeStep.id]: { ...stepValues } as WizardValues
      };
      set({ values: wizardValues });
    }

    const currentIndex = state.steps.findIndex(s => s.id === state.activeStep.id);
    const previousStep = await _getProceedingStep(state.steps.slice(0, currentIndex).reverse(), wizardValues, -1);

    if (!previousStep) return;

    state.setActiveStep(previousStep, true);
  }
}));
