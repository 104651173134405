import aimIcon from '@/assets/icons/temporary/aim.svg';
import cardIcon from '@/assets/icons/temporary/card.svg';
import graphIcon from '@/assets/icons/temporary/graph.svg';
import invoicesIcon from '@/assets/icons/temporary/invoices.svg';
import reportIcon from '@/assets/icons/temporary/report.svg';
import JumpingDog from '@/assets/lottie/jumping-dog';
import { useAuth } from '@/auth/hooks';
import type { HomeActionProperty } from '@/sections/home/home-action';
import HomeAction from '@/sections/home/home-action';

import { useBreakpoint } from '@/hooks/use-breakpoint';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';

export type DefaultVariantProps = {};

const defaultActions: HomeActionProperty[] = [
  {
    label: 'Issue an invoice',
    activitySubject: 'Issue an invoice',
    icon: invoicesIcon,
    firstMessage: 'Please create an invoice for me.',
    activityType: 'operations'
  },
  {
    label: 'Books and Taxes',
    activitySubject: 'Books and Taxes',
    icon: reportIcon,
    firstMessage: 'How can Milo help me to optimize my taxes?',
    activityType: 'setup'
  },
  {
    label: 'Who Owes Me Money?',
    activitySubject: 'Who Owes Me Money?',
    icon: cardIcon,
    firstMessage: 'Who Owes Me Money?',
    activityType: 'ask'
  },
  {
    label: 'What Is My Top Spend?',
    activitySubject: 'What Is My Top Spend?',
    icon: graphIcon,
    firstMessage: 'What Is My Top Spend?',
    activityType: 'ask'
  },
  {
    label: 'What Is My Bottom Line?',
    activitySubject: 'What Is My Bottom Line?',
    icon: aimIcon,
    firstMessage: 'What Is My Bottom Line?',
    activityType: 'ask'
  }
];

export default function DefaultVariant({}: DefaultVariantProps) {
  const { user } = useAuth();
  const { isBelowLg } = useBreakpoint('lg');
  return (
    <div className="flex flex-col px-12 gap-4 lg:gap-12 lg:px-0">
      <div className="rotate-y-180 w-20 lg:w-32">
        <JumpingDog
          style={{
            transform: 'rotateY(180deg)'
          }}
        />
      </div>

      <div className="text-2xl lg:text-3xl text-white">
        <div>
          <MotionTextRevealContainer transition={{ duration: 0.5 }} value={`Hey ${user?.firstName || ''}`} />
        </div>
        <div>
          <MotionTextRevealContainer transition={{ duration: 0.5 }} value="How can I help you today?" />
        </div>
      </div>
      <div className="flex mt-6">
        {isBelowLg ? (
          <ScrollArea aria-orientation="horizontal">
            <div className="w-max flex gap-6 px-12 lg:px-0">
              {defaultActions.map(action => (
                <HomeAction action={action} key={action.label} />
              ))}
            </div>
            <ScrollBar className="hidden" orientation="horizontal" />
          </ScrollArea>
        ) : (
          <div className="w-max flex flex-wrap gap-6 px-12 lg:px-0">
            {defaultActions.map(action => (
              <HomeAction action={action} key={action.label} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
