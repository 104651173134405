import React, { useMemo } from 'react';
import { useAuth } from '@/auth/hooks';
import MobileOnboardingHeader from '@/sections/onboarding/mobile-onboarding-header';
import BusinessEntityTypeStep from '@/sections/onboarding/questionnaire/steps/business-entity-type-step';
import BusinessExperienceStep from '@/sections/onboarding/questionnaire/steps/business-experience-step';
import TasksStep from '@/sections/onboarding/questionnaire/steps/business-tasks-step';
import CompanyNameStep from '@/sections/onboarding/questionnaire/steps/company-name-step';
import ConnectAccountStep from '@/sections/onboarding/questionnaire/steps/connect-account-step';
import IndustryStep from '@/sections/onboarding/questionnaire/steps/industry-step';
import IntroStep from '@/sections/onboarding/questionnaire/steps/intro-step';
import LoadingStep from '@/sections/onboarding/questionnaire/steps/loading-step';
import RevenueStep from '@/sections/onboarding/questionnaire/steps/revenue-step';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { FLAGS } from '@/utils/pixels/amplitude/flags';
import useAmplitudeFlag from '@/utils/pixels/amplitude/userAmplitudeFlag';
import { getAuthQueries } from '@repo/features/auth';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'framer-motion';
import z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { authAxiosInstance } from '@/lib/axios';
import type { StepConfig } from '@/components/form-wizard';
import { useWizard, Wizard } from '@/components/form-wizard';

function Wrapper() {
  const activeStep = useWizard(useShallow(state => state.activeStep));
  if (activeStep.id === 'loading') return activeStep.component;
  return (
    <AnimatePresence mode="popLayout">
      <motion.div
        animate="animateState"
        className="overflow-hidden h-full flex flex-col"
        exit="exitState"
        initial="initialState"
        key={activeStep.id}
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 15,
          mass: 1
        }}
        variants={{
          initialState: {
            x: '100vw'
          },
          animateState: {
            x: 0
          },
          exitState: {
            x: '-100vw',
            zIndex: -10
          }
        }}
      >
        {activeStep.component}
      </motion.div>
    </AnimatePresence>
  );
}
const authQueries = getAuthQueries(authAxiosInstance);

const OnboardingScreen: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const amplitudeFlag = useAmplitudeFlag(FLAGS.ONBOARDING.name, FLAGS.ONBOARDING.defaultValue);
  const { mutateAsync: updateOrganization } = authQueries.updateOrganization.useMutation();
  const { mutateAsync: updateOrgMetadata } = authQueries.updateOrganizationCompanyMetadata.useMutation();
  const { mutateAsync: updateFeatureFlags } = authQueries.updateOrganizationFlags.useMutation();
  const { user, selectedOrgId } = useAuth();
  const organization = user?.organizations.find(org => org.organization._id === selectedOrgId)?.organization;

  const steps = useMemo(
    () =>
      [
        {
          id: 'intro',
          component: <IntroStep />,
          hidePrevious: true,
          ignoreProgress: true
        },
        {
          id: 'companyName',
          component: <CompanyNameStep />,
          canSkip: amplitudeFlag.flags.canSkip, //
          initialValues: { companyName: '' },
          validationSchema: z.object({
            companyName: z.string().min(1, 'Required')
          }),
          onSubmit: async stepValues => {
            if (!stepValues?.companyName) {
              return;
            }
            Amplitude.setPropertiesOnUser({ OnBoard_Questionnaire_Company: stepValues.companyName });
            await Promise.all([
              await updateOrganization({
                id: selectedOrgId!,
                data: {
                  name: stepValues.companyName
                }
              }),
              await updateOrgMetadata({
                id: selectedOrgId!,
                data: {
                  legal: {
                    name: stepValues.companyName
                  }
                }
              })
            ]);
          },
          hidePrevious: true,
          disableNextOnErrors: true
        },
        {
          id: 'businessExperience',
          canSkip: amplitudeFlag.flags.canSkip,
          component: <BusinessExperienceStep />,
          onSubmit: async stepValues => {
            if (!stepValues?.businessExperience) {
              return;
            }
            Amplitude.setPropertiesOnUser({ OnBoard_Questionnaire_BizAge: stepValues.businessExperience });
            await updateOrgMetadata({
              id: selectedOrgId!,
              data: {
                businessExperience: stepValues.businessExperience
              }
            });
          },
          initialValues: {
            businessExperience: ''
          },
          validationSchema: z.object({
            businessExperience: z.string().min(1, 'Required')
          }),
          hidePrevious: true,
          disableNextOnErrors: true
        },
        {
          id: 'industry',
          component: <IndustryStep />,
          canSkip: amplitudeFlag.flags.canSkip,
          onSubmit: async stepValues => {
            if (!stepValues.industry) {
              return;
            }
            Amplitude.setPropertiesOnUser({ OnBoard_Questionnaire_Industry: stepValues.industry });
            await updateOrgMetadata({
              id: selectedOrgId!,
              data: {
                industry: stepValues.industry
              }
            });
          },
          initialValues: {
            industry: ''
          },
          validationSchema: z.object({
            industry: z.string().min(1, 'Required')
          }),
          hidePrevious: true,
          disableNextOnErrors: true
        },
        {
          id: 'revenueRange',
          component: <RevenueStep />,
          canSkip: amplitudeFlag.flags.canSkip,
          onSubmit: async stepValues => {
            if (!stepValues.revenueRange) {
              return;
            }
            Amplitude.setPropertiesOnUser({ OnBoard_Questionnaire_Revenue: stepValues.revenueRange });
            await updateOrgMetadata({
              id: selectedOrgId!,
              data: {
                revenueRange: stepValues.revenueRange
              }
            });
          },
          initialValues: {
            revenueRange: ''
          },
          validationSchema: z.object({
            revenueRange: z.string().min(1, 'Required')
          }),
          disableNextOnErrors: true,
          hidePrevious: true
        },
        {
          id: 'businessEntityType',
          onSubmit: async stepValues => {
            if (!stepValues.businessEntityType) {
              return;
            }
            Amplitude.setPropertiesOnUser({
              OnBoard_Questionnaire_BizEntity: stepValues.businessEntityType ?? "I'm not sure"
            });
            if (stepValues.businessEntityType) {
              await updateOrgMetadata({
                id: selectedOrgId!,
                data: {
                  businessEntityType: stepValues.businessEntityType
                }
              });
            }
          },
          component: <BusinessEntityTypeStep />,
          canSkip: amplitudeFlag.flags.canSkip,
          initialValues: {},
          disableNextOnErrors: true,
          hidePrevious: true
        },
        {
          id: 'businessMiloNeeds',
          canSkip: amplitudeFlag.flags.canSkip,
          component: <TasksStep />,

          initialValues: {
            miloNeeds: []
          },
          validationSchema: z.object({
            miloNeeds: z.array(z.string()).min(1, 'Required')
          }),
          disableNextOnErrors: true,
          hidePrevious: true,
          onSubmit: async (stepValues, allValues) => {
            if (!stepValues.miloNeeds) {
              return;
            }
            Amplitude.setPropertiesOnUser({ OnBoard_Questionnaire_MiloNeeds: stepValues.miloNeeds });
            await updateOrgMetadata({
              id: selectedOrgId!,
              data: {
                miloNeeds: stepValues.miloNeeds
              }
            });
            await onSubmit(allValues);
            Amplitude.track('OnBoard_Questionnaire_Complete');
            Amplitude.setPropertiesOnUser({ OnBoard_Questionnaire_Completed: true });
            return allValues;
          }
        },
        {
          id: 'connectAccount',
          component: <ConnectAccountStep />,
          canSkip: amplitudeFlag.flags.canSkipConnectAccount,
          hidePrevious: true,
          onSubmit: async () => {
            Amplitude.track('OnBoard_Questionnaire_Complete');
            Amplitude.setPropertiesOnUser({ OnBoard_Questionnaire_Completed: true });
          }
        },
        {
          id: 'loading',
          component: <LoadingStep />,
          ignoreProgress: true
        }
      ] satisfies StepConfig[],
    [onSubmit, amplitudeFlag.flags.canSkip, amplitudeFlag.flags.canSkipConnectAccount, selectedOrgId]
  );

  async function onSubmit(values: any) {
    await updateFeatureFlags({
      id: selectedOrgId!,
      data: {
        organizationSetup: {
          ...(organization?.featureFlags.organizationSetup! ?? {}),
          isOnboardingCompleted: true
        }
      }
    });

    // ActionsObserver.notify('ONBOARDING_USER_UPDATE', { onboardingData: values });
  }

  return (
    <Wizard
      className="grid grid-rows-[auto_1fr_auto] lg:grid-rows-[auto_1fr] h-full w-full"
      header={<MobileOnboardingHeader progress={100} />}
      onCompleted={async values => {
        await queryClient.invalidateQueries({ queryKey: authQueries.me.getKey() });
        await navigate({
          to: '/'
        });
      }}
      steps={steps}
      wrapper={<Wrapper />}
    />
  );
};

export default OnboardingScreen;
