import { memo } from 'react';

// ----------------------------------------------------------------------

function File({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg className={className} fill="#000" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        clipRule="evenodd"
        d="M3.38465 1.38462C2.7474 1.38462 2.23081 1.90121 2.23081 2.53846V15.4615C2.23081 16.0988 2.7474 16.6154 3.38465 16.6154H12.6154C13.2527 16.6154 13.7693 16.0988 13.7693 15.4615V6H10.7693C9.87712 6 9.15388 5.27677 9.15388 4.38462V1.38462H3.38465ZM10.5385 2.36369L12.7902 4.61538H10.7693C10.6418 4.61538 10.5385 4.51207 10.5385 4.38462V2.36369ZM0.846191 2.53846C0.846191 1.13651 1.9827 0 3.38465 0H9.84619C10.0298 0 10.2059 0.0729394 10.3357 0.202772L14.9511 4.81816C15.0809 4.94799 15.1539 5.12408 15.1539 5.30769V15.4615C15.1539 16.8635 14.0174 18 12.6154 18H3.38465C1.9827 18 0.846191 16.8635 0.846191 15.4615V2.53846Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default memo(File);
