import ChevronDownIcon from '@/icons/ChevronDown.svg?react';
import ChevronRightIcon from '@/icons/ChevronRight.svg?react';
import PaidIcon from '@/icons/Paid.svg?react';
import ReceivedIcon from '@/icons/Received.svg?react';
import TransactionTag from '@/sections/library/transaction/transaction-tag';
import { fCurrency } from '@/utils/format-number';
import { fDateTime } from '@/utils/format-time';
import type { ICustomer } from '@repo/features/customers';
import type { FinancialAccount } from '@repo/features/financial-accounts';
import type { ITransaction } from '@repo/features/transactions';
import type { IVendor } from '@repo/features/vendors';
import { useNavigate } from '@tanstack/react-router';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export type TransactionMobileRowProps = {
  transaction: ITransaction;
} & VariantProps<typeof transactionVariants>;
const transactionVariants = cva('flex p-10 gap-6 items-center justify-between w-full bg-white rounded-xl', {
  variants: {
    variant: {
      default:
        'grid grid-cols-[1fr_1fr_1fr_11.5rem_11.5rem_7.5rem_11.5rem_auto] items-center justify-center cursor-pointer',
      mobile: '',
      compact: ''
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});
export default function TransactionRow({ transaction, variant }: TransactionMobileRowProps) {
  const navigate = useNavigate();
  const onClick = () => navigate({ to: '/library/transactions/$id', params: { id: transaction._id } });
  const renderTypeIcon = <div>{transaction.amount > 0 ? <ReceivedIcon /> : <PaidIcon />}</div>;
  const amount = transaction.amount > 0 ? `+${fCurrency(transaction.amount)}` : fCurrency(transaction.amount);
  const provider = transaction?.vendor
    ? (transaction.vendor as IVendor).name
    : transaction.customer
      ? (transaction.customer as ICustomer).name
      : 'Unknown';
  const tagVariant =
    transaction?.suggestedAccountingLedger && transaction?.suggestedAccountingLedger?.score < 1
      ? 'uncertain'
      : 'default';
  const renderAmount = <div className="text-xs font-bold">{amount}</div>;
  const renderProvider = <div className="text-sm font-bold text-ellipsis">{provider}</div>;
  const renderAccountName = (
    <div className="text-4xs font-medium">{(transaction.financialAccount as FinancialAccount).name}</div>
  );
  const renderTransactionTime = (
    <div className="text-4xs text-neutral-500 text-ellipsis lg:text-black">{fDateTime(transaction.time)}</div>
  );
  const renderType = (
    <TransactionTag variant="personal">{transaction.personal ? 'Personal' : 'Business'}</TransactionTag>
  );
  const renderCategory = (
    <TransactionTag alertPosition={variant === 'default' ? 'right' : 'left'} variant={tagVariant}>
      {transaction?.suggestedAccountingLedger?.name || 'Uncategorized'}
    </TransactionTag>
  );
  if (variant === 'default') {
    return (
      <div className={cn(transactionVariants({ variant }))} onClick={onClick}>
        <div className="flex items-center">
          <div className="flex items-center gap-3">
            {renderTypeIcon}
            <div className="text-3xs font-semibold">{transaction.amount > 0 ? 'Received' : 'Paid'}</div>
          </div>
        </div>
        <div className="flex items-center">{renderAmount}</div>
        <div className="flex items-center">{renderProvider}</div>
        <div className="flex items-center">{renderAccountName}</div>
        <div className="flex items-center">{renderTransactionTime}</div>
        <div className="flex items-center">{renderType}</div>
        <div className="flex items-center">{renderCategory}</div>
        <div>
          <ChevronRightIcon />
        </div>
      </div>
    );
  }
  return (
    <div className={cn(transactionVariants({ variant }))} onClick={onClick}>
      <div className="flex items-center gap-4">
        {variant === 'mobile' && renderTypeIcon}
        <div>
          {renderProvider}
          {renderAccountName}
          {renderTransactionTime}
        </div>
      </div>
      <div className="flex items-center justify-center gap-4">
        <div className="flex flex-col gap-2 items-end">
          {renderAmount}
          {renderCategory}
        </div>
        {variant === 'mobile' && (
          <div>
            <ChevronDownIcon />
          </div>
        )}
      </div>
    </div>
  );
}
