import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import {IActivityItem, IStorableActivityItem} from './types';

export const getActivityQueries = (axiosInstance: AxiosInstance) =>
  router('activity-feed', {
    byId: router.query<IActivityItem, { id: number | string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/activities/${variables.id}`).then(res => res.data)
    }),
    all: router.query<IActivityItem[], undefined, AxiosError>({
      fetcher: () => axiosInstance.get(`/activities/all`).then(res => res.data)
    }),
    allByOrg: router.query<IActivityItem[], { id: number | string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/activities/${variables.id}/all`).then(res => res.data)
    }),
    create: router.mutation<IActivityItem, { data: IStorableActivityItem }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.post<IActivityItem>('/activities', variables.data).then(res => res.data)
    }),
    update: router.mutation<IActivityItem, { data: Partial<IActivityItem>; id: string }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.put<IActivityItem>(`/activities/${variables.id}`, variables.data).then(res => res.data)
    })
  });
