// import ChevronRightIcon from '@/assets/icons/chevron-right-icon';
import React, { useState } from 'react';
import working1 from '@/assets/gifs/working1.gif';
import { useAuth } from '@/auth/hooks';
import { useProfileStore } from '@/sections/profile/use-profile-store';
import { getActivityQueries } from '@repo/features/activity';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';
import { Textarea } from '@/components/ui/textarea';

const activityQueries = getActivityQueries(axiosInstance);
export default function SupportDrawer({ className }: { className?: string }) {
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState<boolean>(false);

  const { selectedOrgId } = useAuth();
  const { isBelowLg } = useBreakpoint('lg');
  const { toggleMenu, isOpen, activeMenu, openMenu, closeMenu } = useProfileStore();

  const { mutateAsync: createActivity, isPending } = activityQueries.create.useMutation({
    onSuccess: () => {
      setIsSent(true);
    }
  });

  const send = async () => {
    await createActivity({
      data: {
        type: 'ask',
        initiated: 'founder',
        subject: 'Need assistance with my account',
        orgId: selectedOrgId!,
        firstMessage: {
          msg: {
            role: 'user',
            content: message,
            channel: 'direct_chat',
            createdAt: new Date().toISOString()
          },
          shouldSendMessage: true,
          agentActive: false
        }
      }
    });
  };
  const successMessage = (
    <>
      <div className="flex justify-center">
        <img alt="Working &Milo" className="h-[4.125rem]" src={working1} />
      </div>
      <div className="flex flex-col gap-2 text-center">
        <div className="text-md font-bold">Thanks For Your Message!</div>
        <div className="text-sm text-neutral-500">
          One of our representatives will be in touch within 24 hours, and Milo is probably already on it.
        </div>
      </div>
    </>
  );

  if (!isBelowLg) {
    return (
      <Dialog
        onOpenChange={_open => {
          if (_open) {
            openMenu('support');
          } else {
            closeMenu();
          }
        }}
        open={isOpen ? activeMenu === 'support' : false}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Talk to Us</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col justify-center flex-1 p-10 gap-8">
            {isSent ? (
              successMessage
            ) : (
              <>
                <div className="text-center">
                  <div className="text-md font-bold">Need Help? We’re Here For You</div>
                  <div className="text-sm text-neutral-500">
                    Just send us a message! One of our representatives will get back to you within 24h.
                  </div>
                </div>
                <Textarea
                  className="text-xs placeholder:text-neutral-500 placeholder:text-xs"
                  minRows={8}
                  onChange={({ target }) => {
                    setMessage(target.value);
                  }}
                  placeholder="Enter your message"
                  value={message}
                />
                <div className="flex-1" />
                {!isSent && (
                  <Button className="w-full" disabled={!message} isLoading={isPending} onClick={() => send()} size="md">
                    Send Message
                  </Button>
                )}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer
      onOpenChange={_open => {
        if (_open) {
          openMenu('support');
        } else {
          closeMenu();
        }
      }}
      open={isOpen ? activeMenu === 'support' : false}
    >
      <DrawerContent className="flex flex-col">
        <DrawerHeader className="hidden">
          <DrawerTitle>Support</DrawerTitle>
          <DrawerDescription>Need Help? We’re Here For You</DrawerDescription>
        </DrawerHeader>
        <div className="flex flex-col justify-center flex-1 p-8 gap-8">
          {isSent ? (
            successMessage
          ) : (
            <>
              <div className="text-center">
                <div className="text-md font-bold">Need Help? We’re Here For You</div>
                <div className="text-sm text-neutral-500">
                  Just send us a message! One of our representatives will get back to you within 24h.
                </div>
              </div>
              <Textarea
                className="text-xs placeholder:text-neutral-500 placeholder:text-xs"
                minRows={8}
                onChange={({ target }) => {
                  setMessage(target.value);
                }}
                placeholder="Enter your message"
                value={message}
              />
              <div className="flex-1" />
            </>
          )}
        </div>
        <DrawerFooter className="p-8 pt-0">
          {!isSent && (
            <Button className="w-full" disabled={!message} isLoading={isPending} onClick={() => send()} size="md">
              Send Message
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
