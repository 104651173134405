export const FLAGS = {
  ONBOARDING: {
    name: 'onboarding',
    defaultValue: {
      introSplashShow: false,
      onboardingForm: true,
      canSkip: false,
      canSkipConnectAccount: true
    }
  }
} as const;
