import React from 'react';
import ChevronRightIcon from '@/icons/ChevronRight.svg?react';
import VIcon from '@/icons/V.svg?react';

import { cn } from '@/lib/utils';

const MenuRow = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & {
    inset?: boolean;
    icon?: React.ReactNode;
    chevron?: boolean;
    checkmark?: boolean;
    disabled?: boolean;
    description?: React.ReactNode;
    onClick?: () => void;
  }
>(
  (
    {
      className,
      inset,
      icon = null,
      disabled = false,
      chevron = false,
      checkmark = false,
      description,
      children,
      ...props
    },
    ref
  ) => (
    <div
      className={cn(
        'relative select-none gap-2 px-2 outline-none transition-colors focus:text-accent-foreground [&>svg]:size-4 [&>svg]:shrink-0 ',
        'flex justify-between cursor-pointer items-center py-7 pl-8 pr-9 rounded-none text-2xs font-semibold hover:bg-primary-200 focus:bg-primary-200 hover:text-black focus:text-black',
        inset && 'pl-8',
        disabled && 'pointer-events-none opacity-50 text-neutral-500',
        className
      )}
      ref={ref}
      {...props}
    >
      <div className="flex items-center gap-5">
        {icon}
        <div className="flex flex-col gap-2">
          <div>{children}</div>
          {description ? <div className="text-4xs text-neutral-500 font-normal">{description}</div> : null}
        </div>
      </div>
      {chevron ? <ChevronRightIcon className="size-10 stroke-black" /> : null}
      {checkmark ? <VIcon className="size-6 stroke-black" /> : null}
    </div>
  )
);

export default MenuRow;
