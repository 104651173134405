import typographyPlugin from '@tailwindcss/typography';
import type { Config } from 'tailwindcss';
import tailwindCss3d from 'tailwindcss-3d';
import animatePlugin from 'tailwindcss-animate';

const config = {
  darkMode: ['class'],
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './src/routes/*.{js,ts,jsx,tsx}',
    '../../packages/ui/src/**/*.{ts,tsx}'
  ],
  theme: {
    extend: {
      fontFamily: {
        archivo: ['var(--font-archivo)']
      },
      fontSize: {
        '3xl': ['3.75rem', '110%'],
        '2xl': ['2.375rem', '120%'],
        xl: ['2.125rem', '130%'],
        lg: ['1.25rem', '140%'],
        md: ['1.125rem', '150%'],
        base: ['1rem', '120%'],
        sm: ['0.9375rem', '150%'],
        xs: ['0.875rem', '150%'],
        '2xs': ['0.8125rem', '120%'],
        '3xs': ['0.75rem', '100%'],
        '4xs': ['0.6875rem', '150%'],
        '5xs': ['0.5625rem', '100%'],
        '6xs': ['0.5rem', '100%']
      },
      colors: {
        primary: {
          DEFAULT: 'hsl(var(--primary-500))',
          100: 'hsl(var(--primary-100))',
          200: 'hsl(var(--primary-200))',
          300: 'hsl(var(--primary-300))',
          400: 'hsl(var(--primary-400))',
          500: 'hsl(var(--primary-500))',
          600: 'hsl(var(--primary-600))'
        },
        secondary: {
          DEFAULT: 'hsl(var(--secondary-500))',
          500: 'hsl(var(--secondary-500))'
        },
        neutral: {
          200: 'hsl(var(--neutral-200))',
          300: 'hsl(var(--neutral-300))',
          400: 'hsl(var(--neutral-400))',
          500: 'hsl(var(--neutral-500))'
        },
        fun: {
          cyan: 'hsl(var(--fun-cyan))',
          orange: 'hsl(var(--fun-orange))',
          green: 'hsl(var(--fun-green))',
          yellow: 'hsl(var(--fun-yellow))',
          red: 'hsl(var(--fun-red))',
          pink: 'hsl(var(--fun-pink))',
          turquoise: 'hsl(var(--fun-turquoise))'
        },
        border: 'hsl(var(--border))',
        input: 'hsl(var(--input))',
        ring: 'hsl(var(--ring))',
        background: 'hsl(var(--background))',
        foreground: 'hsl(var(--foreground))',
        destructive: {
          DEFAULT: 'hsl(var(--destructive))',
          foreground: 'hsl(var(--destructive-foreground))'
        },
        muted: {
          DEFAULT: 'hsl(var(--muted))',
          foreground: 'hsl(var(--muted-foreground))'
        },
        accent: {
          DEFAULT: 'hsl(var(--accent))',
          foreground: 'hsl(var(--accent-foreground))'
        },
        popover: {
          DEFAULT: 'hsl(var(--popover))',
          foreground: 'hsl(var(--popover-foreground))'
        },
        card: {
          DEFAULT: 'hsl(var(--card))',
          foreground: 'hsl(var(--card-foreground))'
        },
        white: {
          DEFAULT: '#FFFFFF',
          foreground: '#000'
        },
        sidebar: {
          DEFAULT: 'hsl(var(--sidebar-background))',
          foreground: 'hsl(var(--sidebar-foreground))',
          primary: 'hsl(var(--sidebar-primary))',
          'primary-foreground': 'hsl(var(--sidebar-primary-foreground))',
          accent: 'hsl(var(--sidebar-accent))',
          'accent-foreground': 'hsl(var(--sidebar-accent-foreground))',
          active: 'hsl(var(--sidebar-active))',
          border: 'hsl(var(--sidebar-border))',
          ring: 'hsl(var(--sidebar-ring))'
        }
      },
      spacing: {
        px: '1px',
        0: '0',
        0.5: '0.09375rem', // 1.5px
        1: '0.1875rem', // 3px
        1.5: '0.28125rem', // 4.5px
        2: '0.375rem', // 6px
        2.5: '0.46875rem', // 7.5px
        3: '0.5625rem', // 9px
        3.5: '0.65625rem', // 10.5px
        4: '0.75rem', // 12px
        5: '0.9375rem', // 15px
        6: '1.125rem', // 18px
        7: '1.3125rem', // 21px
        8: '1.5rem', // 24px
        9: '1.6875rem', // 27px
        10: '1.875rem', // 30px
        11: '2.0625rem', // 33px
        12: '2.25rem', // 36px
        14: '2.625rem', // 42px
        16: '3rem', // 48px
        18: '3.375rem', // 48px
        20: '3.75rem', // 60px
        21: '3.9375rem', // 63px
        24: '4.5rem', // 72px
        28: '5.25rem', // 84px
        32: '6rem', // 96px
        34: '6.375rem', // 96px
        36: '6.75rem', // 108px
        40: '7.5rem', // 120px
        44: '8.25rem', // 132px
        48: '9rem', // 144px
        52: '9.75rem', // 156px
        56: '10.5rem', // 168px
        60: '11.25rem', // 180px
        64: '12rem', // 192px
        72: '13.5rem', // 216px
        80: '15rem', // 240px
        96: '18rem' // 288px
      },
      borderRadius: {
        lg: 'var(--radius)',
        md: 'calc(var(--radius) - 2px)',
        sm: 'calc(var(--radius) - 4px)'
      },
      keyframes: {
        'accordion-down': {
          from: {
            height: '0'
          },
          to: {
            height: 'var(--radix-accordion-content-height)'
          }
        },
        'accordion-up': {
          from: {
            height: 'var(--radix-accordion-content-height)'
          },
          to: {
            height: '0'
          }
        },
        'slide-down': {
          from: {
            height: '0'
          },
          to: {
            height: 'var(--radix-collapsible-content-height)'
          }
        },
        'slide-up': {
          from: {
            height: 'var(--radix-collapsible-content-height)'
          },
          to: {
            height: '0'
          }
        }
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'slide-up': 'slide-up 500ms ease-out',
        'slide-down': 'slide-down 500ms ease-out'
      }
    }
  },
  plugins: [typographyPlugin, tailwindCss3d as any, animatePlugin]
} satisfies Config;

export default config;
