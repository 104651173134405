/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teal API
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  ARAPPaymentStatus,
  BodyCreatePayableFile,
  DetailedPayable,
  GetLedgerPayablesParams,
  GetPayableAgingReportParams,
  GetPayableBalancesParams,
  GetPayableStatusParams,
  Payable,
  PayableAgingReport,
  PayableBalanceReport,
  PayableCreate,
  PayableFile,
  PayableLine,
  PayableLineUpdate,
  PayablePage,
  PayableUpdate,
  SinglePayableLineCreate,
  TealErrorResponse,
} from "../types";
import { createTealInstance } from "../axiosInstance";

/**
 * Gets all payables for a ledger.
 * @summary Get Ledger Payables
 */
export const getLedgerPayables = (
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<PayablePage>({
    url: `/v0/ledgers/${apLedgerId}/payables`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetLedgerPayablesQueryKey = (
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
) => {
  return [
    `/v0/ledgers/${apLedgerId}/payables`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetLedgerPayablesQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLedgerPayablesQueryKey(apLedgerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLedgerPayables>>
  > = ({ signal }) => getLedgerPayables(apLedgerId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!apLedgerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLedgerPayables>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgerPayablesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgerPayables>>
>;
export type GetLedgerPayablesQueryError = TealErrorResponse;

export function useGetLedgerPayables<
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params: undefined | GetLedgerPayablesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLedgerPayables>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerPayables<
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLedgerPayables>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerPayables<
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Ledger Payables
 */

export function useGetLedgerPayables<
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgerPayablesQueryOptions(
    apLedgerId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Ledger Payables
 */
export const prefetchGetLedgerPayables = async <
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetLedgerPayablesQueryOptions(
    apLedgerId,
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetLedgerPayablesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLedgerPayablesQueryKey(apLedgerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLedgerPayables>>
  > = ({ signal }) => getLedgerPayables(apLedgerId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!apLedgerId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getLedgerPayables>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgerPayablesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgerPayables>>
>;
export type GetLedgerPayablesSuspenseQueryError = TealErrorResponse;

export function useGetLedgerPayablesSuspense<
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params: undefined | GetLedgerPayablesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerPayablesSuspense<
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerPayablesSuspense<
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Ledger Payables
 */

export function useGetLedgerPayablesSuspense<
  TData = Awaited<ReturnType<typeof getLedgerPayables>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  params?: GetLedgerPayablesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerPayables>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgerPayablesSuspenseQueryOptions(
    apLedgerId,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Creates a payable.
 * @summary Create Payable
 */
export const createPayable = (
  apLedgerId: string,
  payableCreate: PayableCreate,
) => {
  return createTealInstance<Payable>({
    url: `/v0/ledgers/${apLedgerId}/payables`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: payableCreate,
  });
};

export const getCreatePayableMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPayable>>,
    TError,
    { apLedgerId: string; data: PayableCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPayable>>,
  TError,
  { apLedgerId: string; data: PayableCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPayable>>,
    { apLedgerId: string; data: PayableCreate }
  > = (props) => {
    const { apLedgerId, data } = props ?? {};

    return createPayable(apLedgerId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePayableMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPayable>>
>;
export type CreatePayableMutationBody = PayableCreate;
export type CreatePayableMutationError = TealErrorResponse;

/**
 * @summary Create Payable
 */
export const useCreatePayable = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPayable>>,
    TError,
    { apLedgerId: string; data: PayableCreate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPayable>>,
  TError,
  { apLedgerId: string; data: PayableCreate },
  TContext
> => {
  const mutationOptions = getCreatePayableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets a payable.
 * @summary Get Payable
 */
export const getPayable = (
  apLedgerId: string,
  payableId: string,
  signal?: AbortSignal,
) => {
  return createTealInstance<DetailedPayable>({
    url: `/v0/ledgers/${apLedgerId}/payables/${payableId}`,
    method: "GET",
    signal,
  });
};

export const getGetPayableQueryKey = (
  apLedgerId: string,
  payableId: string,
) => {
  return [`/v0/ledgers/${apLedgerId}/payables/${payableId}`] as const;
};

export const getGetPayableQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayable>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPayableQueryKey(apLedgerId, payableId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayable>>> = ({
    signal,
  }) => getPayable(apLedgerId, payableId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(apLedgerId && payableId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPayable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayable>>
>;
export type GetPayableQueryError = TealErrorResponse;

export function useGetPayable<
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayable>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayable>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayable<
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayable>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayable>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayable<
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayable>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable
 */

export function useGetPayable<
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayable>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableQueryOptions(
    apLedgerId,
    payableId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Payable
 */
export const prefetchGetPayable = async <
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayable>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetPayableQueryOptions(
    apLedgerId,
    payableId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetPayableSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayable>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPayableQueryKey(apLedgerId, payableId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayable>>> = ({
    signal,
  }) => getPayable(apLedgerId, payableId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(apLedgerId && payableId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPayable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayable>>
>;
export type GetPayableSuspenseQueryError = TealErrorResponse;

export function useGetPayableSuspense<
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableSuspense<
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableSuspense<
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable
 */

export function useGetPayableSuspense<
  TData = Awaited<ReturnType<typeof getPayable>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableSuspenseQueryOptions(
    apLedgerId,
    payableId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates a payable.
 * @summary Update Payable
 */
export const updatePayable = (
  apLedgerId: string,
  payableId: string,
  payableUpdate: PayableUpdate,
) => {
  return createTealInstance<Payable>({
    url: `/v0/ledgers/${apLedgerId}/payables/${payableId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: payableUpdate,
  });
};

export const getUpdatePayableMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePayable>>,
    TError,
    { apLedgerId: string; payableId: string; data: PayableUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePayable>>,
  TError,
  { apLedgerId: string; payableId: string; data: PayableUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePayable>>,
    { apLedgerId: string; payableId: string; data: PayableUpdate }
  > = (props) => {
    const { apLedgerId, payableId, data } = props ?? {};

    return updatePayable(apLedgerId, payableId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePayableMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePayable>>
>;
export type UpdatePayableMutationBody = PayableUpdate;
export type UpdatePayableMutationError = TealErrorResponse;

/**
 * @summary Update Payable
 */
export const useUpdatePayable = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePayable>>,
    TError,
    { apLedgerId: string; payableId: string; data: PayableUpdate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePayable>>,
  TError,
  { apLedgerId: string; payableId: string; data: PayableUpdate },
  TContext
> => {
  const mutationOptions = getUpdatePayableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a payable.
 * @summary Delete Payable
 */
export const deletePayable = (apLedgerId: string, payableId: string) => {
  return createTealInstance<void>({
    url: `/v0/ledgers/${apLedgerId}/payables/${payableId}`,
    method: "DELETE",
  });
};

export const getDeletePayableMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePayable>>,
    TError,
    { apLedgerId: string; payableId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePayable>>,
  TError,
  { apLedgerId: string; payableId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePayable>>,
    { apLedgerId: string; payableId: string }
  > = (props) => {
    const { apLedgerId, payableId } = props ?? {};

    return deletePayable(apLedgerId, payableId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePayableMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePayable>>
>;

export type DeletePayableMutationError = TealErrorResponse;

/**
 * @summary Delete Payable
 */
export const useDeletePayable = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePayable>>,
    TError,
    { apLedgerId: string; payableId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePayable>>,
  TError,
  { apLedgerId: string; payableId: string },
  TContext
> => {
  const mutationOptions = getDeletePayableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get payable status and payments.
 * @summary Get Payable Status
 */
export const getPayableStatus = (
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<ARAPPaymentStatus>({
    url: `/v0/ledgers/${apLedgerId}/payables/${payableId}/status`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetPayableStatusQueryKey = (
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
) => {
  return [
    `/v0/ledgers/${apLedgerId}/payables/${payableId}/status`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetPayableStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPayableStatusQueryKey(apLedgerId, payableId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPayableStatus>>
  > = ({ signal }) => getPayableStatus(apLedgerId, payableId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(apLedgerId && payableId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPayableStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayableStatus>>
>;
export type GetPayableStatusQueryError = TealErrorResponse;

export function useGetPayableStatus<
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayableStatus>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableStatus<
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayableStatus>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableStatus<
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable Status
 */

export function useGetPayableStatus<
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableStatusQueryOptions(
    apLedgerId,
    payableId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Payable Status
 */
export const prefetchGetPayableStatus = async <
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetPayableStatusQueryOptions(
    apLedgerId,
    payableId,
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetPayableStatusSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPayableStatusQueryKey(apLedgerId, payableId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPayableStatus>>
  > = ({ signal }) => getPayableStatus(apLedgerId, payableId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(apLedgerId && payableId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPayableStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableStatusSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayableStatus>>
>;
export type GetPayableStatusSuspenseQueryError = TealErrorResponse;

export function useGetPayableStatusSuspense<
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableStatusSuspense<
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableStatusSuspense<
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable Status
 */

export function useGetPayableStatusSuspense<
  TData = Awaited<ReturnType<typeof getPayableStatus>>,
  TError = TealErrorResponse,
>(
  apLedgerId: string,
  payableId: string,
  params: GetPayableStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableStatusSuspenseQueryOptions(
    apLedgerId,
    payableId,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Payable File
 */
export const createPayableFile = (
  bodyCreatePayableFile: BodyCreatePayableFile,
) => {
  const formData = new FormData();
  formData.append("ap_ledger_id", bodyCreatePayableFile.ap_ledger_id);
  formData.append("payable_id", bodyCreatePayableFile.payable_id);
  formData.append("file", bodyCreatePayableFile.file);

  return createTealInstance<PayableFile>({
    url: `/v0/payable-files`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  });
};

export const getCreatePayableFileMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPayableFile>>,
    TError,
    { data: BodyCreatePayableFile },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPayableFile>>,
  TError,
  { data: BodyCreatePayableFile },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPayableFile>>,
    { data: BodyCreatePayableFile }
  > = (props) => {
    const { data } = props ?? {};

    return createPayableFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePayableFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPayableFile>>
>;
export type CreatePayableFileMutationBody = BodyCreatePayableFile;
export type CreatePayableFileMutationError = TealErrorResponse;

/**
 * @summary Create Payable File
 */
export const useCreatePayableFile = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPayableFile>>,
    TError,
    { data: BodyCreatePayableFile },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPayableFile>>,
  TError,
  { data: BodyCreatePayableFile },
  TContext
> => {
  const mutationOptions = getCreatePayableFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Payable File
 */
export const getPayableFile = (payableFileId: string, signal?: AbortSignal) => {
  return createTealInstance<Blob>({
    url: `/v0/payable-files/${payableFileId}`,
    method: "GET",
    responseType: "blob",
    signal,
  });
};

export const getGetPayableFileQueryKey = (payableFileId: string) => {
  return [`/v0/payable-files/${payableFileId}`] as const;
};

export const getGetPayableFileQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayableFile>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPayableFileQueryKey(payableFileId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayableFile>>> = ({
    signal,
  }) => getPayableFile(payableFileId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!payableFileId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPayableFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayableFile>>
>;
export type GetPayableFileQueryError = TealErrorResponse;

export function useGetPayableFile<
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayableFile>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayableFile>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableFile<
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayableFile>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayableFile>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableFile<
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayableFile>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable File
 */

export function useGetPayableFile<
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayableFile>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableFileQueryOptions(payableFileId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Payable File
 */
export const prefetchGetPayableFile = async <
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  payableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPayableFile>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetPayableFileQueryOptions(payableFileId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetPayableFileSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableFile>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPayableFileQueryKey(payableFileId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayableFile>>> = ({
    signal,
  }) => getPayableFile(payableFileId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!payableFileId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPayableFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableFileSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayableFile>>
>;
export type GetPayableFileSuspenseQueryError = TealErrorResponse;

export function useGetPayableFileSuspense<
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableFileSuspense<
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableFileSuspense<
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable File
 */

export function useGetPayableFileSuspense<
  TData = Awaited<ReturnType<typeof getPayableFile>>,
  TError = TealErrorResponse,
>(
  payableFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableFileSuspenseQueryOptions(
    payableFileId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Delete Payable File
 */
export const deletePayableFile = (payableFileId: string) => {
  return createTealInstance<void>({
    url: `/v0/payable-files/${payableFileId}`,
    method: "DELETE",
  });
};

export const getDeletePayableFileMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePayableFile>>,
    TError,
    { payableFileId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePayableFile>>,
  TError,
  { payableFileId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePayableFile>>,
    { payableFileId: string }
  > = (props) => {
    const { payableFileId } = props ?? {};

    return deletePayableFile(payableFileId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePayableFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePayableFile>>
>;

export type DeletePayableFileMutationError = TealErrorResponse;

/**
 * @summary Delete Payable File
 */
export const useDeletePayableFile = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePayableFile>>,
    TError,
    { payableFileId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePayableFile>>,
  TError,
  { payableFileId: string },
  TContext
> => {
  const mutationOptions = getDeletePayableFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Payable Aging Report
 */
export const getPayableAgingReport = (
  params: GetPayableAgingReportParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<PayableAgingReport>({
    url: `/v0/reports/payable-aging`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetPayableAgingReportQueryKey = (
  params: GetPayableAgingReportParams,
) => {
  return [`/v0/reports/payable-aging`, ...(params ? [params] : [])] as const;
};

export const getGetPayableAgingReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPayableAgingReportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPayableAgingReport>>
  > = ({ signal }) => getPayableAgingReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPayableAgingReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableAgingReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayableAgingReport>>
>;
export type GetPayableAgingReportQueryError = TealErrorResponse;

export function useGetPayableAgingReport<
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayableAgingReport>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableAgingReport<
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayableAgingReport>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableAgingReport<
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable Aging Report
 */

export function useGetPayableAgingReport<
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableAgingReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Payable Aging Report
 */
export const prefetchGetPayableAgingReport = async <
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetPayableAgingReportQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetPayableAgingReportSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPayableAgingReportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPayableAgingReport>>
  > = ({ signal }) => getPayableAgingReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPayableAgingReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableAgingReportSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayableAgingReport>>
>;
export type GetPayableAgingReportSuspenseQueryError = TealErrorResponse;

export function useGetPayableAgingReportSuspense<
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableAgingReportSuspense<
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableAgingReportSuspense<
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable Aging Report
 */

export function useGetPayableAgingReportSuspense<
  TData = Awaited<ReturnType<typeof getPayableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetPayableAgingReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableAgingReportSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Gets payable balances for ledger.
 * @summary Get Payable Balances
 */
export const getPayableBalances = (
  params: GetPayableBalancesParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<PayableBalanceReport>({
    url: `/v0/reports/payable-balances`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetPayableBalancesQueryKey = (
  params: GetPayableBalancesParams,
) => {
  return [`/v0/reports/payable-balances`, ...(params ? [params] : [])] as const;
};

export const getGetPayableBalancesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPayableBalancesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPayableBalances>>
  > = ({ signal }) => getPayableBalances(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPayableBalances>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableBalancesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayableBalances>>
>;
export type GetPayableBalancesQueryError = TealErrorResponse;

export function useGetPayableBalances<
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayableBalances>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableBalances<
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPayableBalances>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableBalances<
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable Balances
 */

export function useGetPayableBalances<
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableBalancesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Payable Balances
 */
export const prefetchGetPayableBalances = async <
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetPayableBalancesQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetPayableBalancesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPayableBalancesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPayableBalances>>
  > = ({ signal }) => getPayableBalances(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPayableBalances>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPayableBalancesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayableBalances>>
>;
export type GetPayableBalancesSuspenseQueryError = TealErrorResponse;

export function useGetPayableBalancesSuspense<
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableBalancesSuspense<
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetPayableBalancesSuspense<
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Payable Balances
 */

export function useGetPayableBalancesSuspense<
  TData = Awaited<ReturnType<typeof getPayableBalances>>,
  TError = TealErrorResponse,
>(
  params: GetPayableBalancesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getPayableBalances>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPayableBalancesSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Payable Line
 */
export const createPayableLine = (
  singlePayableLineCreate: SinglePayableLineCreate,
) => {
  return createTealInstance<PayableLine>({
    url: `/v0/payable-lines`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: singlePayableLineCreate,
  });
};

export const getCreatePayableLineMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPayableLine>>,
    TError,
    { data: SinglePayableLineCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPayableLine>>,
  TError,
  { data: SinglePayableLineCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPayableLine>>,
    { data: SinglePayableLineCreate }
  > = (props) => {
    const { data } = props ?? {};

    return createPayableLine(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePayableLineMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPayableLine>>
>;
export type CreatePayableLineMutationBody = SinglePayableLineCreate;
export type CreatePayableLineMutationError = TealErrorResponse;

/**
 * @summary Create Payable Line
 */
export const useCreatePayableLine = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPayableLine>>,
    TError,
    { data: SinglePayableLineCreate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPayableLine>>,
  TError,
  { data: SinglePayableLineCreate },
  TContext
> => {
  const mutationOptions = getCreatePayableLineMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Payable Line
 */
export const updatePayableLine = (
  payableLineId: string,
  payableLineUpdate: PayableLineUpdate,
) => {
  return createTealInstance<void>({
    url: `/v0/payable-lines/${payableLineId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: payableLineUpdate,
  });
};

export const getUpdatePayableLineMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePayableLine>>,
    TError,
    { payableLineId: string; data: PayableLineUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePayableLine>>,
  TError,
  { payableLineId: string; data: PayableLineUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePayableLine>>,
    { payableLineId: string; data: PayableLineUpdate }
  > = (props) => {
    const { payableLineId, data } = props ?? {};

    return updatePayableLine(payableLineId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePayableLineMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePayableLine>>
>;
export type UpdatePayableLineMutationBody = PayableLineUpdate;
export type UpdatePayableLineMutationError = TealErrorResponse;

/**
 * @summary Update Payable Line
 */
export const useUpdatePayableLine = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePayableLine>>,
    TError,
    { payableLineId: string; data: PayableLineUpdate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePayableLine>>,
  TError,
  { payableLineId: string; data: PayableLineUpdate },
  TContext
> => {
  const mutationOptions = getUpdatePayableLineMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Payable Line
 */
export const deletePayableLine = (payableLineId: string) => {
  return createTealInstance<void>({
    url: `/v0/payable-lines/${payableLineId}`,
    method: "DELETE",
  });
};

export const getDeletePayableLineMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePayableLine>>,
    TError,
    { payableLineId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePayableLine>>,
  TError,
  { payableLineId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePayableLine>>,
    { payableLineId: string }
  > = (props) => {
    const { payableLineId } = props ?? {};

    return deletePayableLine(payableLineId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePayableLineMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePayableLine>>
>;

export type DeletePayableLineMutationError = TealErrorResponse;

/**
 * @summary Delete Payable Line
 */
export const useDeletePayableLine = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePayableLine>>,
    TError,
    { payableLineId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deletePayableLine>>,
  TError,
  { payableLineId: string },
  TContext
> => {
  const mutationOptions = getDeletePayableLineMutationOptions(options);

  return useMutation(mutationOptions);
};
