import type { LottieProps } from 'react-lottie';
import Lottie from 'react-lottie';

import animation from './music-listening-dog.json';

export type LoaderProps = {} & Omit<LottieProps, 'options'>;

export default function MusicListeningDog(props: LoaderProps) {
  return (
    <Lottie
      options={{
        animationData: animation
      }}
      {...props}
    />
  );
}
