/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teal API
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  ARAPPaymentStatus,
  BodyCreateReceivableAdjustmentFile,
  BodyCreateReceivableFile,
  DetailedReceivable,
  GetLedgerReceivableParams,
  GetReceivableAgingReportParams,
  GetReceivableStatusParams,
  GetReceivablesBalancesParams,
  Receivable,
  ReceivableAdjustment,
  ReceivableAdjustmentCreate,
  ReceivableAdjustmentFile,
  ReceivableAdjustmentUpdate,
  ReceivableAgingReport,
  ReceivableBalanceReport,
  ReceivableCreate,
  ReceivableFile,
  ReceivableLine,
  ReceivableLineUpdate,
  ReceivablePage,
  ReceivableUpdate,
  SingleReceivableLineCreate,
  TealErrorResponse,
} from "../types";
import { createTealInstance } from "../axiosInstance";

/**
 * @summary Get Receivable File
 */
export const getReceivableFile = (
  receivableFileId: string,
  signal?: AbortSignal,
) => {
  return createTealInstance<Blob>({
    url: `/v0/receivable-files/${receivableFileId}`,
    method: "GET",
    responseType: "blob",
    signal,
  });
};

export const getGetReceivableFileQueryKey = (receivableFileId: string) => {
  return [`/v0/receivable-files/${receivableFileId}`] as const;
};

export const getGetReceivableFileQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReceivableFileQueryKey(receivableFileId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivableFile>>
  > = ({ signal }) => getReceivableFile(receivableFileId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!receivableFileId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getReceivableFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivableFile>>
>;
export type GetReceivableFileQueryError = TealErrorResponse;

export function useGetReceivableFile<
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivableFile>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableFile<
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivableFile>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableFile<
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable File
 */

export function useGetReceivableFile<
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableFileQueryOptions(
    receivableFileId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Receivable File
 */
export const prefetchGetReceivableFile = async <
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetReceivableFileQueryOptions(
    receivableFileId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetReceivableFileSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReceivableFileQueryKey(receivableFileId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivableFile>>
  > = ({ signal }) => getReceivableFile(receivableFileId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!receivableFileId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getReceivableFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableFileSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivableFile>>
>;
export type GetReceivableFileSuspenseQueryError = TealErrorResponse;

export function useGetReceivableFileSuspense<
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableFileSuspense<
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableFileSuspense<
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable File
 */

export function useGetReceivableFileSuspense<
  TData = Awaited<ReturnType<typeof getReceivableFile>>,
  TError = TealErrorResponse,
>(
  receivableFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableFileSuspenseQueryOptions(
    receivableFileId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Gets all receivable for a ledger.
 * @summary Get Ledger Receivables
 */
export const getLedgerReceivable = (
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<ReceivablePage>({
    url: `/v0/ledgers/${arLedgerId}/receivables`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetLedgerReceivableQueryKey = (
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
) => {
  return [
    `/v0/ledgers/${arLedgerId}/receivables`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetLedgerReceivableQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLedgerReceivableQueryKey(arLedgerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLedgerReceivable>>
  > = ({ signal }) => getLedgerReceivable(arLedgerId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!arLedgerId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLedgerReceivable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgerReceivableQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgerReceivable>>
>;
export type GetLedgerReceivableQueryError = TealErrorResponse;

export function useGetLedgerReceivable<
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params: undefined | GetLedgerReceivableParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLedgerReceivable>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerReceivable<
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getLedgerReceivable>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerReceivable<
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Ledger Receivables
 */

export function useGetLedgerReceivable<
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgerReceivableQueryOptions(
    arLedgerId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Ledger Receivables
 */
export const prefetchGetLedgerReceivable = async <
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetLedgerReceivableQueryOptions(
    arLedgerId,
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetLedgerReceivableSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLedgerReceivableQueryKey(arLedgerId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLedgerReceivable>>
  > = ({ signal }) => getLedgerReceivable(arLedgerId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!arLedgerId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getLedgerReceivable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLedgerReceivableSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLedgerReceivable>>
>;
export type GetLedgerReceivableSuspenseQueryError = TealErrorResponse;

export function useGetLedgerReceivableSuspense<
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params: undefined | GetLedgerReceivableParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerReceivableSuspense<
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetLedgerReceivableSuspense<
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Ledger Receivables
 */

export function useGetLedgerReceivableSuspense<
  TData = Awaited<ReturnType<typeof getLedgerReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  params?: GetLedgerReceivableParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getLedgerReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLedgerReceivableSuspenseQueryOptions(
    arLedgerId,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Creates a receivable.
 * @summary Create Receivable
 */
export const createReceivable = (
  arLedgerId: string,
  receivableCreate: ReceivableCreate,
) => {
  return createTealInstance<Receivable>({
    url: `/v0/ledgers/${arLedgerId}/receivables`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: receivableCreate,
  });
};

export const getCreateReceivableMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivable>>,
    TError,
    { arLedgerId: string; data: ReceivableCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReceivable>>,
  TError,
  { arLedgerId: string; data: ReceivableCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReceivable>>,
    { arLedgerId: string; data: ReceivableCreate }
  > = (props) => {
    const { arLedgerId, data } = props ?? {};

    return createReceivable(arLedgerId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReceivableMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReceivable>>
>;
export type CreateReceivableMutationBody = ReceivableCreate;
export type CreateReceivableMutationError = TealErrorResponse;

/**
 * @summary Create Receivable
 */
export const useCreateReceivable = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivable>>,
    TError,
    { arLedgerId: string; data: ReceivableCreate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReceivable>>,
  TError,
  { arLedgerId: string; data: ReceivableCreate },
  TContext
> => {
  const mutationOptions = getCreateReceivableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets a receivable.
 * @summary Get Receivable
 */
export const getReceivable = (
  arLedgerId: string,
  receivableId: string,
  signal?: AbortSignal,
) => {
  return createTealInstance<DetailedReceivable>({
    url: `/v0/ledgers/${arLedgerId}/receivables/${receivableId}`,
    method: "GET",
    signal,
  });
};

export const getGetReceivableQueryKey = (
  arLedgerId: string,
  receivableId: string,
) => {
  return [`/v0/ledgers/${arLedgerId}/receivables/${receivableId}`] as const;
};

export const getGetReceivableQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getReceivable>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetReceivableQueryKey(arLedgerId, receivableId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReceivable>>> = ({
    signal,
  }) => getReceivable(arLedgerId, receivableId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(arLedgerId && receivableId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getReceivable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivable>>
>;
export type GetReceivableQueryError = TealErrorResponse;

export function useGetReceivable<
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getReceivable>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivable>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivable<
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getReceivable>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivable>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivable<
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getReceivable>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable
 */

export function useGetReceivable<
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getReceivable>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableQueryOptions(
    arLedgerId,
    receivableId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Receivable
 */
export const prefetchGetReceivable = async <
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getReceivable>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetReceivableQueryOptions(
    arLedgerId,
    receivableId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetReceivableSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivable>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetReceivableQueryKey(arLedgerId, receivableId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReceivable>>> = ({
    signal,
  }) => getReceivable(arLedgerId, receivableId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(arLedgerId && receivableId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getReceivable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivable>>
>;
export type GetReceivableSuspenseQueryError = TealErrorResponse;

export function useGetReceivableSuspense<
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableSuspense<
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableSuspense<
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable
 */

export function useGetReceivableSuspense<
  TData = Awaited<ReturnType<typeof getReceivable>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivable>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableSuspenseQueryOptions(
    arLedgerId,
    receivableId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates a receivable.
 * @summary Update Receivable
 */
export const updateReceivable = (
  arLedgerId: string,
  receivableId: string,
  receivableUpdate: ReceivableUpdate,
) => {
  return createTealInstance<Receivable>({
    url: `/v0/ledgers/${arLedgerId}/receivables/${receivableId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: receivableUpdate,
  });
};

export const getUpdateReceivableMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReceivable>>,
    TError,
    { arLedgerId: string; receivableId: string; data: ReceivableUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReceivable>>,
  TError,
  { arLedgerId: string; receivableId: string; data: ReceivableUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReceivable>>,
    { arLedgerId: string; receivableId: string; data: ReceivableUpdate }
  > = (props) => {
    const { arLedgerId, receivableId, data } = props ?? {};

    return updateReceivable(arLedgerId, receivableId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReceivableMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReceivable>>
>;
export type UpdateReceivableMutationBody = ReceivableUpdate;
export type UpdateReceivableMutationError = TealErrorResponse;

/**
 * @summary Update Receivable
 */
export const useUpdateReceivable = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReceivable>>,
    TError,
    { arLedgerId: string; receivableId: string; data: ReceivableUpdate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReceivable>>,
  TError,
  { arLedgerId: string; receivableId: string; data: ReceivableUpdate },
  TContext
> => {
  const mutationOptions = getUpdateReceivableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a receivable.
 * @summary Delete Receivable
 */
export const deleteReceivable = (arLedgerId: string, receivableId: string) => {
  return createTealInstance<void>({
    url: `/v0/ledgers/${arLedgerId}/receivables/${receivableId}`,
    method: "DELETE",
  });
};

export const getDeleteReceivableMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivable>>,
    TError,
    { arLedgerId: string; receivableId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReceivable>>,
  TError,
  { arLedgerId: string; receivableId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReceivable>>,
    { arLedgerId: string; receivableId: string }
  > = (props) => {
    const { arLedgerId, receivableId } = props ?? {};

    return deleteReceivable(arLedgerId, receivableId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReceivableMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReceivable>>
>;

export type DeleteReceivableMutationError = TealErrorResponse;

/**
 * @summary Delete Receivable
 */
export const useDeleteReceivable = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivable>>,
    TError,
    { arLedgerId: string; receivableId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReceivable>>,
  TError,
  { arLedgerId: string; receivableId: string },
  TContext
> => {
  const mutationOptions = getDeleteReceivableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get receivable status and payments.
 * @summary Get Receivable Status
 */
export const getReceivableStatus = (
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<ARAPPaymentStatus>({
    url: `/v0/ledgers/${arLedgerId}/receivables/${receivableId}/status`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetReceivableStatusQueryKey = (
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
) => {
  return [
    `/v0/ledgers/${arLedgerId}/receivables/${receivableId}/status`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetReceivableStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetReceivableStatusQueryKey(arLedgerId, receivableId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivableStatus>>
  > = ({ signal }) =>
    getReceivableStatus(arLedgerId, receivableId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(arLedgerId && receivableId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getReceivableStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivableStatus>>
>;
export type GetReceivableStatusQueryError = TealErrorResponse;

export function useGetReceivableStatus<
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivableStatus>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableStatus<
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivableStatus>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableStatus<
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable Status
 */

export function useGetReceivableStatus<
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableStatusQueryOptions(
    arLedgerId,
    receivableId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Receivable Status
 */
export const prefetchGetReceivableStatus = async <
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetReceivableStatusQueryOptions(
    arLedgerId,
    receivableId,
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetReceivableStatusSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetReceivableStatusQueryKey(arLedgerId, receivableId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivableStatus>>
  > = ({ signal }) =>
    getReceivableStatus(arLedgerId, receivableId, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(arLedgerId && receivableId),
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getReceivableStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableStatusSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivableStatus>>
>;
export type GetReceivableStatusSuspenseQueryError = TealErrorResponse;

export function useGetReceivableStatusSuspense<
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableStatusSuspense<
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableStatusSuspense<
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable Status
 */

export function useGetReceivableStatusSuspense<
  TData = Awaited<ReturnType<typeof getReceivableStatus>>,
  TError = TealErrorResponse,
>(
  arLedgerId: string,
  receivableId: string,
  params: GetReceivableStatusParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableStatus>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableStatusSuspenseQueryOptions(
    arLedgerId,
    receivableId,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Receivable File
 */
export const createReceivableFile = (
  bodyCreateReceivableFile: BodyCreateReceivableFile,
) => {
  const formData = new FormData();
  formData.append("ar_ledger_id", bodyCreateReceivableFile.ar_ledger_id);
  formData.append("receivable_id", bodyCreateReceivableFile.receivable_id);
  formData.append("file", bodyCreateReceivableFile.file);

  return createTealInstance<ReceivableFile>({
    url: `/v0/receivable-files`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  });
};

export const getCreateReceivableFileMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivableFile>>,
    TError,
    { data: BodyCreateReceivableFile },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReceivableFile>>,
  TError,
  { data: BodyCreateReceivableFile },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReceivableFile>>,
    { data: BodyCreateReceivableFile }
  > = (props) => {
    const { data } = props ?? {};

    return createReceivableFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReceivableFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReceivableFile>>
>;
export type CreateReceivableFileMutationBody = BodyCreateReceivableFile;
export type CreateReceivableFileMutationError = TealErrorResponse;

/**
 * @summary Create Receivable File
 */
export const useCreateReceivableFile = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivableFile>>,
    TError,
    { data: BodyCreateReceivableFile },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReceivableFile>>,
  TError,
  { data: BodyCreateReceivableFile },
  TContext
> => {
  const mutationOptions = getCreateReceivableFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Receivable File
 */
export const deleteReceivableFile = (receivableFileId: string) => {
  return createTealInstance<void>({
    url: `/v0/receivable-files/${receivableFileId}`,
    method: "DELETE",
  });
};

export const getDeleteReceivableFileMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivableFile>>,
    TError,
    { receivableFileId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReceivableFile>>,
  TError,
  { receivableFileId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReceivableFile>>,
    { receivableFileId: string }
  > = (props) => {
    const { receivableFileId } = props ?? {};

    return deleteReceivableFile(receivableFileId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReceivableFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReceivableFile>>
>;

export type DeleteReceivableFileMutationError = TealErrorResponse;

/**
 * @summary Delete Receivable File
 */
export const useDeleteReceivableFile = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivableFile>>,
    TError,
    { receivableFileId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReceivableFile>>,
  TError,
  { receivableFileId: string },
  TContext
> => {
  const mutationOptions = getDeleteReceivableFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Receivable Aging Report
 */
export const getReceivableAgingReport = (
  params: GetReceivableAgingReportParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<ReceivableAgingReport>({
    url: `/v0/reports/receivable-aging`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetReceivableAgingReportQueryKey = (
  params: GetReceivableAgingReportParams,
) => {
  return [`/v0/reports/receivable-aging`, ...(params ? [params] : [])] as const;
};

export const getGetReceivableAgingReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReceivableAgingReportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivableAgingReport>>
  > = ({ signal }) => getReceivableAgingReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReceivableAgingReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableAgingReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivableAgingReport>>
>;
export type GetReceivableAgingReportQueryError = TealErrorResponse;

export function useGetReceivableAgingReport<
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivableAgingReport>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableAgingReport<
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivableAgingReport>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableAgingReport<
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable Aging Report
 */

export function useGetReceivableAgingReport<
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableAgingReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Receivable Aging Report
 */
export const prefetchGetReceivableAgingReport = async <
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetReceivableAgingReportQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetReceivableAgingReportSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReceivableAgingReportQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivableAgingReport>>
  > = ({ signal }) => getReceivableAgingReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getReceivableAgingReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableAgingReportSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivableAgingReport>>
>;
export type GetReceivableAgingReportSuspenseQueryError = TealErrorResponse;

export function useGetReceivableAgingReportSuspense<
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableAgingReportSuspense<
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableAgingReportSuspense<
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable Aging Report
 */

export function useGetReceivableAgingReportSuspense<
  TData = Awaited<ReturnType<typeof getReceivableAgingReport>>,
  TError = TealErrorResponse,
>(
  params: GetReceivableAgingReportParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAgingReport>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableAgingReportSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Gets receivables balances for ledger.
 * @summary Get Receivables Balances
 */
export const getReceivablesBalances = (
  params: GetReceivablesBalancesParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<ReceivableBalanceReport>({
    url: `/v0/reports/receivable-balances`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetReceivablesBalancesQueryKey = (
  params: GetReceivablesBalancesParams,
) => {
  return [
    `/v0/reports/receivable-balances`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetReceivablesBalancesQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReceivablesBalancesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivablesBalances>>
  > = ({ signal }) => getReceivablesBalances(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReceivablesBalances>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivablesBalancesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivablesBalances>>
>;
export type GetReceivablesBalancesQueryError = TealErrorResponse;

export function useGetReceivablesBalances<
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivablesBalances>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivablesBalances<
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivablesBalances>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivablesBalances<
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivables Balances
 */

export function useGetReceivablesBalances<
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivablesBalancesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Receivables Balances
 */
export const prefetchGetReceivablesBalances = async <
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetReceivablesBalancesQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetReceivablesBalancesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReceivablesBalancesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivablesBalances>>
  > = ({ signal }) => getReceivablesBalances(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getReceivablesBalances>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivablesBalancesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivablesBalances>>
>;
export type GetReceivablesBalancesSuspenseQueryError = TealErrorResponse;

export function useGetReceivablesBalancesSuspense<
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivablesBalancesSuspense<
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivablesBalancesSuspense<
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivables Balances
 */

export function useGetReceivablesBalancesSuspense<
  TData = Awaited<ReturnType<typeof getReceivablesBalances>>,
  TError = TealErrorResponse,
>(
  params: GetReceivablesBalancesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivablesBalances>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivablesBalancesSuspenseQueryOptions(
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Receivable Line
 */
export const createReceivableLine = (
  singleReceivableLineCreate: SingleReceivableLineCreate,
) => {
  return createTealInstance<ReceivableLine>({
    url: `/v0/receivable-lines`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: singleReceivableLineCreate,
  });
};

export const getCreateReceivableLineMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivableLine>>,
    TError,
    { data: SingleReceivableLineCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReceivableLine>>,
  TError,
  { data: SingleReceivableLineCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReceivableLine>>,
    { data: SingleReceivableLineCreate }
  > = (props) => {
    const { data } = props ?? {};

    return createReceivableLine(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReceivableLineMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReceivableLine>>
>;
export type CreateReceivableLineMutationBody = SingleReceivableLineCreate;
export type CreateReceivableLineMutationError = TealErrorResponse;

/**
 * @summary Create Receivable Line
 */
export const useCreateReceivableLine = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivableLine>>,
    TError,
    { data: SingleReceivableLineCreate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReceivableLine>>,
  TError,
  { data: SingleReceivableLineCreate },
  TContext
> => {
  const mutationOptions = getCreateReceivableLineMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Receivable Line
 */
export const updateReceivableLine = (
  receivableLineId: string,
  receivableLineUpdate: ReceivableLineUpdate,
) => {
  return createTealInstance<void>({
    url: `/v0/receivable-lines/${receivableLineId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: receivableLineUpdate,
  });
};

export const getUpdateReceivableLineMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReceivableLine>>,
    TError,
    { receivableLineId: string; data: ReceivableLineUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReceivableLine>>,
  TError,
  { receivableLineId: string; data: ReceivableLineUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReceivableLine>>,
    { receivableLineId: string; data: ReceivableLineUpdate }
  > = (props) => {
    const { receivableLineId, data } = props ?? {};

    return updateReceivableLine(receivableLineId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReceivableLineMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReceivableLine>>
>;
export type UpdateReceivableLineMutationBody = ReceivableLineUpdate;
export type UpdateReceivableLineMutationError = TealErrorResponse;

/**
 * @summary Update Receivable Line
 */
export const useUpdateReceivableLine = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReceivableLine>>,
    TError,
    { receivableLineId: string; data: ReceivableLineUpdate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReceivableLine>>,
  TError,
  { receivableLineId: string; data: ReceivableLineUpdate },
  TContext
> => {
  const mutationOptions = getUpdateReceivableLineMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Receivable Line
 */
export const deleteReceivableLine = (receivableLineId: string) => {
  return createTealInstance<void>({
    url: `/v0/receivable-lines/${receivableLineId}`,
    method: "DELETE",
  });
};

export const getDeleteReceivableLineMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivableLine>>,
    TError,
    { receivableLineId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReceivableLine>>,
  TError,
  { receivableLineId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReceivableLine>>,
    { receivableLineId: string }
  > = (props) => {
    const { receivableLineId } = props ?? {};

    return deleteReceivableLine(receivableLineId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReceivableLineMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReceivableLine>>
>;

export type DeleteReceivableLineMutationError = TealErrorResponse;

/**
 * @summary Delete Receivable Line
 */
export const useDeleteReceivableLine = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivableLine>>,
    TError,
    { receivableLineId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReceivableLine>>,
  TError,
  { receivableLineId: string },
  TContext
> => {
  const mutationOptions = getDeleteReceivableLineMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create Receivable Adjustment
 */
export const createReceivableAdjustment = (
  receivableAdjustmentCreate: ReceivableAdjustmentCreate,
) => {
  return createTealInstance<ReceivableAdjustment>({
    url: `/v0/receivable-adjustments`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: receivableAdjustmentCreate,
  });
};

export const getCreateReceivableAdjustmentMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivableAdjustment>>,
    TError,
    { data: ReceivableAdjustmentCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReceivableAdjustment>>,
  TError,
  { data: ReceivableAdjustmentCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReceivableAdjustment>>,
    { data: ReceivableAdjustmentCreate }
  > = (props) => {
    const { data } = props ?? {};

    return createReceivableAdjustment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReceivableAdjustmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReceivableAdjustment>>
>;
export type CreateReceivableAdjustmentMutationBody = ReceivableAdjustmentCreate;
export type CreateReceivableAdjustmentMutationError = TealErrorResponse;

/**
 * @summary Create Receivable Adjustment
 */
export const useCreateReceivableAdjustment = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivableAdjustment>>,
    TError,
    { data: ReceivableAdjustmentCreate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReceivableAdjustment>>,
  TError,
  { data: ReceivableAdjustmentCreate },
  TContext
> => {
  const mutationOptions = getCreateReceivableAdjustmentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Receivable Adjustment
 */
export const updateReceivableAdjustment = (
  receivableAdjustmentId: string,
  receivableAdjustmentUpdate: ReceivableAdjustmentUpdate,
) => {
  return createTealInstance<void>({
    url: `/v0/receivable-adjustments/${receivableAdjustmentId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: receivableAdjustmentUpdate,
  });
};

export const getUpdateReceivableAdjustmentMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReceivableAdjustment>>,
    TError,
    { receivableAdjustmentId: string; data: ReceivableAdjustmentUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReceivableAdjustment>>,
  TError,
  { receivableAdjustmentId: string; data: ReceivableAdjustmentUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReceivableAdjustment>>,
    { receivableAdjustmentId: string; data: ReceivableAdjustmentUpdate }
  > = (props) => {
    const { receivableAdjustmentId, data } = props ?? {};

    return updateReceivableAdjustment(receivableAdjustmentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReceivableAdjustmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReceivableAdjustment>>
>;
export type UpdateReceivableAdjustmentMutationBody = ReceivableAdjustmentUpdate;
export type UpdateReceivableAdjustmentMutationError = TealErrorResponse;

/**
 * @summary Update Receivable Adjustment
 */
export const useUpdateReceivableAdjustment = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReceivableAdjustment>>,
    TError,
    { receivableAdjustmentId: string; data: ReceivableAdjustmentUpdate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateReceivableAdjustment>>,
  TError,
  { receivableAdjustmentId: string; data: ReceivableAdjustmentUpdate },
  TContext
> => {
  const mutationOptions = getUpdateReceivableAdjustmentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Receivable Adjustment
 */
export const deleteReceivableAdjustment = (receivableAdjustmentId: string) => {
  return createTealInstance<void>({
    url: `/v0/receivable-adjustments/${receivableAdjustmentId}`,
    method: "DELETE",
  });
};

export const getDeleteReceivableAdjustmentMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivableAdjustment>>,
    TError,
    { receivableAdjustmentId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReceivableAdjustment>>,
  TError,
  { receivableAdjustmentId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReceivableAdjustment>>,
    { receivableAdjustmentId: string }
  > = (props) => {
    const { receivableAdjustmentId } = props ?? {};

    return deleteReceivableAdjustment(receivableAdjustmentId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReceivableAdjustmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReceivableAdjustment>>
>;

export type DeleteReceivableAdjustmentMutationError = TealErrorResponse;

/**
 * @summary Delete Receivable Adjustment
 */
export const useDeleteReceivableAdjustment = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivableAdjustment>>,
    TError,
    { receivableAdjustmentId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReceivableAdjustment>>,
  TError,
  { receivableAdjustmentId: string },
  TContext
> => {
  const mutationOptions = getDeleteReceivableAdjustmentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create Receivable Adjustment File
 */
export const createReceivableAdjustmentFile = (
  bodyCreateReceivableAdjustmentFile: BodyCreateReceivableAdjustmentFile,
) => {
  const formData = new FormData();
  formData.append(
    "receivable_adjustment_id",
    bodyCreateReceivableAdjustmentFile.receivable_adjustment_id,
  );
  formData.append("file", bodyCreateReceivableAdjustmentFile.file);

  return createTealInstance<ReceivableAdjustmentFile>({
    url: `/v0/receivable-adjustment-files`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  });
};

export const getCreateReceivableAdjustmentFileMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivableAdjustmentFile>>,
    TError,
    { data: BodyCreateReceivableAdjustmentFile },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReceivableAdjustmentFile>>,
  TError,
  { data: BodyCreateReceivableAdjustmentFile },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReceivableAdjustmentFile>>,
    { data: BodyCreateReceivableAdjustmentFile }
  > = (props) => {
    const { data } = props ?? {};

    return createReceivableAdjustmentFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReceivableAdjustmentFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReceivableAdjustmentFile>>
>;
export type CreateReceivableAdjustmentFileMutationBody =
  BodyCreateReceivableAdjustmentFile;
export type CreateReceivableAdjustmentFileMutationError = TealErrorResponse;

/**
 * @summary Create Receivable Adjustment File
 */
export const useCreateReceivableAdjustmentFile = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReceivableAdjustmentFile>>,
    TError,
    { data: BodyCreateReceivableAdjustmentFile },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createReceivableAdjustmentFile>>,
  TError,
  { data: BodyCreateReceivableAdjustmentFile },
  TContext
> => {
  const mutationOptions =
    getCreateReceivableAdjustmentFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Receivable Adjustment File
 */
export const getReceivableAdjustmentFile = (
  receivableAdjustmentFileId: string,
  signal?: AbortSignal,
) => {
  return createTealInstance<Blob>({
    url: `/v0/receivable-adjustment-files/${receivableAdjustmentFileId}`,
    method: "GET",
    responseType: "blob",
    signal,
  });
};

export const getGetReceivableAdjustmentFileQueryKey = (
  receivableAdjustmentFileId: string,
) => {
  return [
    `/v0/receivable-adjustment-files/${receivableAdjustmentFileId}`,
  ] as const;
};

export const getGetReceivableAdjustmentFileQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetReceivableAdjustmentFileQueryKey(receivableAdjustmentFileId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivableAdjustmentFile>>
  > = ({ signal }) =>
    getReceivableAdjustmentFile(receivableAdjustmentFileId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!receivableAdjustmentFileId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableAdjustmentFileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivableAdjustmentFile>>
>;
export type GetReceivableAdjustmentFileQueryError = TealErrorResponse;

export function useGetReceivableAdjustmentFile<
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableAdjustmentFile<
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableAdjustmentFile<
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable Adjustment File
 */

export function useGetReceivableAdjustmentFile<
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableAdjustmentFileQueryOptions(
    receivableAdjustmentFileId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Receivable Adjustment File
 */
export const prefetchGetReceivableAdjustmentFile = async <
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetReceivableAdjustmentFileQueryOptions(
    receivableAdjustmentFileId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetReceivableAdjustmentFileSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetReceivableAdjustmentFileQueryKey(receivableAdjustmentFileId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReceivableAdjustmentFile>>
  > = ({ signal }) =>
    getReceivableAdjustmentFile(receivableAdjustmentFileId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!receivableAdjustmentFileId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReceivableAdjustmentFileSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReceivableAdjustmentFile>>
>;
export type GetReceivableAdjustmentFileSuspenseQueryError = TealErrorResponse;

export function useGetReceivableAdjustmentFileSuspense<
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableAdjustmentFileSuspense<
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetReceivableAdjustmentFileSuspense<
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Get Receivable Adjustment File
 */

export function useGetReceivableAdjustmentFileSuspense<
  TData = Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
  TError = TealErrorResponse,
>(
  receivableAdjustmentFileId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getReceivableAdjustmentFile>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetReceivableAdjustmentFileSuspenseQueryOptions(
    receivableAdjustmentFileId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Delete Receivable Adjustment File
 */
export const deleteReceivableAdjustmentFile = (
  receivableAdjustmentFileId: string,
) => {
  return createTealInstance<void>({
    url: `/v0/receivable-adjustment-files/${receivableAdjustmentFileId}`,
    method: "DELETE",
  });
};

export const getDeleteReceivableAdjustmentFileMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivableAdjustmentFile>>,
    TError,
    { receivableAdjustmentFileId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReceivableAdjustmentFile>>,
  TError,
  { receivableAdjustmentFileId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReceivableAdjustmentFile>>,
    { receivableAdjustmentFileId: string }
  > = (props) => {
    const { receivableAdjustmentFileId } = props ?? {};

    return deleteReceivableAdjustmentFile(receivableAdjustmentFileId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReceivableAdjustmentFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReceivableAdjustmentFile>>
>;

export type DeleteReceivableAdjustmentFileMutationError = TealErrorResponse;

/**
 * @summary Delete Receivable Adjustment File
 */
export const useDeleteReceivableAdjustmentFile = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReceivableAdjustmentFile>>,
    TError,
    { receivableAdjustmentFileId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReceivableAdjustmentFile>>,
  TError,
  { receivableAdjustmentFileId: string },
  TContext
> => {
  const mutationOptions =
    getDeleteReceivableAdjustmentFileMutationOptions(options);

  return useMutation(mutationOptions);
};
