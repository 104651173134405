import { createFileRoute } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

export const Route = createFileRoute('/test')({
  component: () => (
    <div>
      <div className="bg-white flex p-10 flex-col space-y-5">
        <div>
          <Button size="sm">Connect</Button>
        </div>
        <div>
          <Button size="md">Connect</Button>
        </div>
        <div>
          <Button size="lg">Connect</Button>
        </div>
      </div>
      <div className="bg-primary flex p-10 flex-col space-y-5">
        <div>
          <Button size="sm" variant="secondary">
            Connect
          </Button>
        </div>
        <div>
          <Button size="md" variant="secondary">
            Connect
          </Button>
        </div>
        <div>
          <Button size="lg" variant="secondary">
            Connect
          </Button>
        </div>
      </div>
      <div className="bg-primary flex p-10 flex-col space-y-5">
        <div>
          <Button size="sm" variant="outline">
            Connect
          </Button>
        </div>
        <div>
          <Button size="md" variant="outline">
            Connect
          </Button>
        </div>
        <div>
          <Button size="lg" variant="outline">
            Connect
          </Button>
        </div>
      </div>
    </div>
  )
});
