import { useAuthStore } from '@/auth/store.ts';
import { PATH_AFTER_LOGIN } from '@/config-global.ts';
import { createFileRoute, redirect } from '@tanstack/react-router';

import ActionsObserver from '@/lib/actions-observer';
import { SplashScreen } from '@/components/loading-screen';

type CallbackEventType = 'sign_up' | 'login';
type CallbackSearchParams = {
  accessToken: string;
  refreshToken: string;
  event: CallbackEventType;
};

export const Route = createFileRoute('/_guested/auth/callback_')({
  validateSearch: (search: Record<string, unknown>): CallbackSearchParams => {
    // validate and parse the search params into a typed state
    return {
      accessToken: search.accessToken as string,
      refreshToken: search.refreshToken as string,
      event: search.event as CallbackEventType
    };
  },
  beforeLoad: ({ search }) => {
    if (search.event === 'sign_up') {
      ActionsObserver.notify('GOOGLE_SIGN_UP');
    } else {
      ActionsObserver.notify('GOOGLE_SIGN_IN');
    }
  },
  loaderDeps: ({ search }) => ({
    accessToken: search.accessToken,
    refreshToken: search.refreshToken
  }),
  loader: async ({ deps: { accessToken, refreshToken } }) => {
    useAuthStore.getState().setAccessToken(accessToken);
    useAuthStore.getState().setRefreshToken(refreshToken);
    await new Promise(resolve => setTimeout(resolve, 500));
    throw redirect({
      to: PATH_AFTER_LOGIN,
      resetScroll: true
    });
  },
  component: () => null,
  pendingMs: 0,
  pendingComponent: SplashScreen
});
