import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { getConversationQueries } from '../queries';

export const useManageConversationData = (axiosInstance: AxiosInstance) => {
  const queryClient = useQueryClient();
  const conversationQueries = getConversationQueries(axiosInstance);
  const addMessage = useCallback((conversationId: string, msg: any) => {
    const conversations = queryClient.getQueryData(conversationQueries.all.getKey());
    if (!conversations?.some(c => c.thread.messages.some(m => m._id === msg._id))) {
      queryClient.setQueryData(conversationQueries.all.getKey(), prev =>
        prev
          ? prev.map(c =>
              c._id === conversationId
                ? {
                    ...c,
                    thread: {
                      ...c.thread,
                      messages: [...c.thread.messages, msg]
                    }
                  }
                : c
            )
          : prev
      );
    }
    const conversation = queryClient.getQueryData(conversationQueries.byId.getKey({ id: conversationId }));
    if (!conversation?.thread.messages.some(m => m._id === msg._id)) {
      queryClient.setQueryData(conversationQueries.byId.getKey({ id: conversationId }), prev =>
        prev
          ? {
              ...prev,
              thread: {
                ...prev.thread,
                messages: [...prev.thread.messages, msg]
              }
            }
          : prev
      );
    }
  }, []);
  return {
    addMessage
  };
};
