import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function ClipIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 21 24"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M17.68 7.92H20.21V15.52H15.18V19.26H0.02V15.46H0.05V11.73H0.03V11.36H0V0H3.76V4.11H6.55V0.07H11.15V4.11H14.74V7.88H17.68V7.92ZM0 20.21H8.85V24H0V20.21Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default memo(ClipIcon);
