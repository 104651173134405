import { useAuthStore } from '@/auth/store';
import OnboardingLayout from '@/layouts/common/onboarding-layout.tsx';
import OnboardingScreen from '@/sections/onboarding/questionnaire';
import amplitude from '@/utils/pixels/amplitude/amplitude';
import { FLAGS } from '@/utils/pixels/amplitude/flags';
import { getAuthQueries } from '@repo/features/auth';
import { createFileRoute, redirect } from '@tanstack/react-router';

import ActionsObserver from '@/lib/actions-observer';
import { authAxiosInstance } from '@/lib/axios.ts';

const authQueries = getAuthQueries(authAxiosInstance);
export const Route = createFileRoute('/onboarding')({
  beforeLoad: async ({ context: { queryClient } }) => {
    const amplitudeOnboardingFlag = await amplitude.variant(FLAGS.ONBOARDING.name, FLAGS.ONBOARDING.defaultValue);
    const user = await queryClient.ensureQueryData(authQueries.me.getOptions());
    ActionsObserver.notify('USER_SETTLED', { user });

    const organization = user.organizations.find(
      org => org.organization._id === useAuthStore.getState().selectedOrgId
    )?.organization;
    const isOnboardingCompleted = !!organization?.featureFlags?.organizationSetup?.isOnboardingCompleted;
    if (isOnboardingCompleted || !amplitudeOnboardingFlag.onboardingForm) {
      throw redirect({
        to: '/'
      });
    }

    if (!amplitudeOnboardingFlag?.onboardingForm) {
      // @Omri: TODO: update db. Stas, I dont know how to do this in this component. Can you help me?
      // updateOrganization({ id: organization._id, data: { featureFlags: { organizationSetup: { isOnboardingCompleted: true } } } });
    }
  },
  component: () => (
    <OnboardingLayout>
      <OnboardingScreen />
    </OnboardingLayout>
  )
});
