import { useEffect } from 'react';
import sniffing1 from '@/assets/gifs/sniffing1.gif';
import AnswerSelector from '@/sections/onboarding/questionnaire/answer-selector';
import NextStepSection from '@/sections/onboarding/questionnaire/next-step-section';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { useBoolean } from '@/hooks/use-boolean';
import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';
import { useWizard } from '@/components/form-wizard';

const AnimatedButton = motion(Button);

const businessExperienceVariants = [
  { name: 'Just starting', value: '0' },
  { name: '1st year', value: '1' },
  { name: '1-2 Years', value: '1-2' },
  { name: '3-5 Years', value: '3-5' },
  { name: '5+ Years', value: '5+' }
] as const;

export default function BusinessExperienceStep() {
  const { control, getValues } = useFormContext();
  const activeStep = useWizard(useShallow(state => state.activeStep));
  const textRevealed = useBoolean(false);
  const [goToNextStep, stepNumber] = useWizard(useShallow(state => [state.goToNextStep, state.stepNumber]));
  const questionText = 'How long are you in business?';
  const bottomAnimationConfig = {
    animate: textRevealed.value ? { opacity: 1 } : false,
    initial: { opacity: 0 },
    transition: { duration: 0.4, ease: 'easeInOut' }
  };
  useEffect(() => {
    Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_View`, {
      questionText: questionText
    });
  }, []);
  return (
    <>
      <div className="flex flex-1 flex-col gap-8 py-4 px-12 overflow-hidden">
        <div className="flex flex-col gap-4">
          <img className="h-[3rem] w-[9.43rem] lg:hidden" src={sniffing1} />
          <div className="text-2xl">
            <MotionTextRevealContainer
              value={questionText}
              wrapperProps={{
                onAnimationComplete() {
                  textRevealed.onTrue();
                }
              }}
            />
          </div>
        </div>
        <FormField
          control={control}
          name="businessExperience"
          render={({ field }) => (
            <FormItem className="space-y-3 overflow-y-scroll scrollbar-hidden">
              <FormControl>
                <RadioGroup
                  className="flex flex-col space-y-1"
                  defaultValue={field.value}
                  onValueChange={field.onChange}
                >
                  {businessExperienceVariants.map(({ name, value }) => (
                    <FormItem
                      className={cn('flex items-center animate-in fade-in duration-[800ms]', {
                        hidden: !textRevealed.value
                      })}
                      key={name}
                    >
                      <FormControl className="hidden">
                        <RadioGroupItem value={value} />
                      </FormControl>
                      <FormLabel className="w-full">
                        <AnswerSelector selected={field.value === value} variant="secondary">
                          {name}
                        </AnswerSelector>
                      </FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {activeStep.canSkip ? (
          <AnimatedButton
            {...bottomAnimationConfig}
            className="absolute right-9 lg:right-14 lg:top-14 top-7 text-xs font-semibold"
            onClick={() => {
              goToNextStep();
              Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_SkipClick`);
            }}
            size="auto"
            type="button"
            variant="ghost"
          >
            Skip
          </AnimatedButton>
        ) : null}
      </div>
      <NextStepSection
        onClick={() => {
          const values = getValues();
          Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_ButtonClick`, {
            answerText: values?.businessExperience
          });
        }}
        startAnimation={textRevealed.value}
      >
        Next
      </NextStepSection>
    </>
  );
}
