import ConnectAccountVariant from '@/sections/home/variants/connect-account-variant';
import DefaultVariant from '@/sections/home/variants/default-variant';
import InsightsVariant from '@/sections/home/variants/insights-variant';
import { useAppStateStore } from '@/stores/app-state';
import useAmplitudeFlag from '@/utils/pixels/amplitude/userAmplitudeFlag';
import { useShallow } from 'zustand/react/shallow';

import useGetInsights from '@/hooks/use-get-insights';

export default function HomePageView() {
  const { insights } = useGetInsights();
  const [isActive] = useAppStateStore(useShallow(state => [state.isActive]));
  const {
    flags: { showInsights }
  } = useAmplitudeFlag('show-insights', { showInsights: true }, []);
  const hasInsights = insights.length > 0;

  const getHomePageContent = () => {
    if (!isActive) return <ConnectAccountVariant />;
    if (isActive && hasInsights && showInsights) return <InsightsVariant />;
    return <DefaultVariant />;
  };

  return (
    <div className="lg:flex lg:flex-col lg:items-center">
      <div className="lg:w-[50rem]">{getHomePageContent()}</div>
    </div>
  );
}
