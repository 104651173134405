import CloseIcon from '@/assets/icons/close-icon';
import FileIcon from '@/assets/icons/file';
import { getFileDetails, getSignedUrlDetails } from '@repo/features/file';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

export interface FilePreviewProps extends VariantProps<typeof wrapperVariants> {
  progress?: number;
  signedUrl?: string;
  file?: File;
  onDelete?: (file: File) => void;
}

const wrapperVariants = cva('relative flex w-80 items-center justify-between rounded-lg  p-3', {
  variants: {
    variant: {
      default: '',
      dark: 'bg-primary-200 text-black [&_.close-icon]:fill-black',
      light: 'bg-white/20 text-white'
    }
  },
  defaultVariants: {
    variant: 'dark'
  }
});

export default function FilePreview({ progress, file, variant, onDelete, signedUrl }: FilePreviewProps) {
  if (!signedUrl && !file) return null;
  const { extension, name } = file ? getFileDetails(file) : getSignedUrlDetails(signedUrl!);
  return (
    <div className={cn(wrapperVariants({ variant }))}>
      <div className="relative flex size-12 items-center justify-center rounded bg-white shrink-0">
        <FileIcon
          className={cn('z-10 size-5', {
            'fill-white': (progress && progress > 0) || (progress && progress < 1)
          })}
        />
        <div
          className={cn('absolute bottom-0 left-0 w-full rounded bg-accent transition-all duration-300', {
            'bg-primary transition-colors duration-150': progress && progress >= 1
          })}
          style={{ height: `${(progress || 0) * 100}%` }}
        />
      </div>
      <div className="flex flex-1 flex-col gap-1 ml-4 overflow-hidden">
        {progress && signedUrl && progress >= 1 ? (
          <a className="items-start truncate text-sm hover:underline" href={signedUrl} rel="noopener" target="_blank">
            {name}
          </a>
        ) : (
          <div className="text-xs font-semibold truncate">{name}</div>
        )}
        <div className="text-4xs uppercase">{extension}</div>
      </div>
      {onDelete && file && !progress ? (
        <Button
          onClick={() => {
            onDelete(file);
          }}
          size="icon"
          type="button"
          variant="icon"
        >
          <CloseIcon className="close-icon size-3 fill-white cursor-pointer hover:fill-accent" />
        </Button>
      ) : null}
    </div>
  );
}
