import { useState } from 'react';
import { authAxiosInstance } from '@/lib/axios.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { getAuthQueries } from '@repo/features/auth';
import { getRouteApi } from '@tanstack/react-router';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

import { useBoolean } from '@/hooks/use-boolean';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

// ----------------------------------------------------------------------
const formSchema = z.object({
  email: z.string().email('Email is incorrect')
});

const routeApi = getRouteApi('/_guested/auth/forgot-password');
const authQueries = getAuthQueries(authAxiosInstance);

export default function ForgotPasswordView() {
  const searchParams = routeApi.useSearch();
  const [errorMsg, setErrorMsg] = useState(searchParams.errorMessage);
  const showSuccessMessage = useBoolean(false);
  const { mutateAsync: sendResetLink } = authQueries.forgotPassword.useMutation({
    onSuccess: () => {
      showSuccessMessage.onTrue();
    },
    onError: error => {
      if (error.response) setErrorMsg(error.response.data.message);
    }
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: ''
    }
  });
  const email = useWatch({ control: form.control, name: 'email' });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setErrorMsg('');
      await sendResetLink({ data: values });
    } catch (e) {}
  };
  const renderSuccessMessage = (
    <div className="flex flex-col h-full justify-center items-center">
      <div>
        <div className="w-80 text-base flex sm:w-full flex-col">
          <div>We send a reset password email to {email}.</div>
          <div>Please click on the reset password link to set your new password!</div>
        </div>
        <div className="mt-10">
          <div>Haven't received the email?</div>
          <div>
            Please check your spa, folder, or
            <span
              className="ml-1 cursor-pointer underline"
              onClick={() => {
                showSuccessMessage.onFalse();
              }}
            >
              try again!
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderForm = (
    <Form {...form}>
      <form className="flex flex-col gap-12 py-8 px-10" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="text-black text-lg lg:text-xl flex text-center lg:text-left">Reset Your Password</div>
        <div>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div>
          <Button className="w-full" size="lg">
            Send Reset Link
          </Button>
        </div>
      </form>
    </Form>
  );

  return (
    <div className="lg:w-[25rem] w-full">
      {!!errorMsg && (
        <Alert className="mb-5" variant="destructive">
          <AlertTitle>Heads up!</AlertTitle>
          <AlertDescription>{errorMsg}</AlertDescription>
        </Alert>
      )}
      {showSuccessMessage.value ? renderSuccessMessage : renderForm}
    </div>
  );
}
