// ----------------------------------------------------------------------
export type AuthProvider = 'database' | 'google';
export type AuthUserType = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  organizations: Array<UserOrganizationDelegation & { organization: Organization }>;
  displayName?: string;
  photoURL?: string;
  roles: string[];
  accessToken: string;
  provider: AuthProvider;
  utm?: Record<string, unknown>;
};

export type OrganizationFeatureFlags = {
  organizationSetup: {
    completed: boolean;
    setupConversationId: string;
    isOnboardingCompleted: boolean;
  };
};
export type OrganizationAccountingStatus = 'active' | 'archived';
export type OrganizationAccountingMetadata = {
  tealInstanceId: string;
  status: OrganizationAccountingStatus;
};

export type OrganizationEmailSettings = {
  onBehalfOf?: string;
}

export type Organization = {
  _id: string;
  isPersonal: boolean;
  owner: string | IUser;
  name: string;
  domain: string;
  miloEmail: string;
  emailSettings: OrganizationEmailSettings;
  featureFlags: OrganizationFeatureFlags;
  accountingMetadata: OrganizationAccountingMetadata | null;
  companyMetadata: OrganizationCompanyMetadata;
};
export interface IUser {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  refreshToken?: string;
  organizations: Array<UserOrganizationDelegation & { organization: Organization }>;

  inviteDate: Date;
}

export interface OrganizationCompanyPublicInfo {
  email?: string;
  phone?: string;
  website?: string;
  address?: string;
}

export interface OrganizationCompanyLegalInfo {
  name?: string;
  address?: string;
}

enum Industry {
  SocialMediaManagement = 'Social Media Management',
  DigitalMarketingAdvertising = 'Digital Marketing & Advertising',
  WritingEditing = 'Writing and Editing',
  InfluencerMarketing = 'Influencer Marketing',
  PublicRelations = 'Public Relations',
  Animation = 'Animation',
  Design = 'Design',
  Branding = 'Branding',
  Photography = 'Photography',
  ECommerce = 'E-commerce',
  WebAppDevelopment = 'Web & App Development',
  ComputerSoftware = 'Computer Software',
  InformationTechnology = 'Information Technology',
  StaffingRecruiting = 'Staffing and Recruiting',
  ProfessionalTrainingCoaching = 'Professional Training & Coaching',
  HealthWellnessFitness = 'Health, Wellness and Fitness',
  ELearning = 'E-Learning',
  RealEstate = 'Real Estate',
  FinancialServices = 'Financial Services',
  Other = 'Other'
}

export interface OrganizationCompanyMetadata {
  logo?: string;
  organization: string;
  email?: string;
  businessEntityType?: BusinessEntityType;
  industry?: string;
  revenueRange?: string;
  businessExperience?: string;
  miloNeeds?: string[];
  legal: OrganizationCompanyLegalInfo;
  public: OrganizationCompanyPublicInfo;
}

export interface IOrganizationBillingStripeData {
  customerId?: string;
  subscriptionId?: string;
  checkoutSessionId?: string;
}

export interface IOrganizationBilling {
  status: 'active' | 'checking_out' | 'cancelled';
  stripe: IOrganizationBillingStripeData;
}

export enum BusinessEntityType {
  SOLE_PROPIETORSHIP = 'Sole Proprietorship',
  PARTNERSHIP = 'Partnership',
  'C-CORP' = 'C-Corp',
  'S-CORP' = 'S-Corp'
}

export interface UserOrganizationDelegation {
  _id?: string;
  organization: Organization;
  user: string;
  features: {
    isSubscribedToCommunications: boolean;
    isVisible: boolean;
  };
  updatedAt: Date;
  createdAt: Date;
}

// ----------------------------------------------------------------------
