import React from 'react';
import { MatchRoute, useNavigate } from '@tanstack/react-router';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

export type LibraryNavBarProps = {};

export default function LibraryNavBar({}: LibraryNavBarProps) {
  const navigate = useNavigate();
  return (
    <div className="py-4 px-7 overflow-x-scroll scrollbar-hidden border-b border-b-neutral-300 lg:hidden">
      <MatchRoute fuzzy to="/library">
        {match => (
          <>
            {match ? (
              <Tabs
                onValueChange={async value => {
                  await navigate({
                    to: value,
                    from: '/library'
                  });
                }}
                value={(match as any)['**']}
              >
                <TabsList className="justify-start flex gap-4">
                  <TabsTrigger value="transactions">Transactions</TabsTrigger>
                  <TabsTrigger value="invoices">Invoices</TabsTrigger>
                  <TabsTrigger value="customers">Customers</TabsTrigger>
                  <TabsTrigger value="bills">Bills & Receipts</TabsTrigger>
                  <TabsTrigger value="vendors">Vendors</TabsTrigger>
                </TabsList>
              </Tabs>
            ) : null}
          </>
        )}
      </MatchRoute>
    </div>
  );
}
