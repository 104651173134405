import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import { IOrganizationBilling } from '../auth';
import { SubscriptionDetails } from './types';

export const getBillingQueries = (axiosInstance: AxiosInstance) =>
  router('billing', {
    getBilling: router.query<IOrganizationBilling | null, void, AxiosError>({
      fetcher: () => axiosInstance.get(`/billing`).then(res => res.data)
    }),
    getSubscriptionDetails: router.query<SubscriptionDetails | null, void, AxiosError>({
      fetcher: () => axiosInstance.get(`/billing/subscription`).then(res => res.data)
    }),
    initiateCheckoutSession: router.query<string, void, AxiosError>({
      fetcher: () => axiosInstance.post(`/billing/checkout-session`).then(res => res.data)
    }),
    cancelSubscription: router.mutation<void, void, AxiosError>({
      mutationFn: () => axiosInstance.delete(`/billing`).then(res => res.data)
    })
  });
