import { useState } from 'react';
import { authAxiosInstance } from '@/lib/axios.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { getAuthQueries } from '@repo/features/auth';
import { getRouteApi, useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

const authQueries = getAuthQueries(authAxiosInstance);
// ----------------------------------------------------------------------
const formSchema = z
  .object({
    password: z.string().min(6, 'Password must be at least 6 characters.'),
    confirmPassword: z.string()
  })
  .refine(
    values => {
      return values.password === values.confirmPassword;
    },
    {
      message: 'Passwords must match!',
      path: ['confirmPassword']
    }
  );

const routeApi = getRouteApi('/_guested/auth/reset-password/$token');

export default function ResetPasswordView() {
  const [errorMsg, setErrorMsg] = useState('');
  const { firstName } = routeApi.useSearch();
  const { token } = routeApi.useParams();
  const navigate = useNavigate();
  const { mutateAsync: resetPass } = authQueries.resetPassword.useMutation({
    onSuccess: async () => {
      await navigate({ to: '/auth/signin' });
    },
    onError: error => {
      if (error.response) setErrorMsg(error.response.data.message);
    }
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setErrorMsg('');
    await resetPass({ data: { ...values, token } });
  };

  const renderForm = (
    <Form {...form}>
      <form className="flex flex-col gap-12 py-8 px-10" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="text-black text-xl sm:flex text-center lg:text-left">Enter New Password</div>
        <div>
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Password" type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="mt-6">
                <FormControl>
                  <Input placeholder="Confirm Password" type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div>
          <Button className="w-full" size="lg">
            Reset Your Password
          </Button>
        </div>
      </form>
    </Form>
  );

  return (
    <div className="lg:w-[25rem] py-8 px-10 w-full">
      {!!errorMsg && (
        <Alert className="mb-5" variant="destructive">
          <AlertTitle>Heads up!</AlertTitle>
          <AlertDescription>{errorMsg}</AlertDescription>
        </Alert>
      )}
      {renderForm}
    </div>
  );
}
