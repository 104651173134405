import { useEffect, useRef } from 'react';
import type { IMessage } from '@repo/features/conversation';

import { ScrollArea } from '@/components/ui/scroll-area';

import ChatMessageItem from './chat-message-item';

// ----------------------------------------------------------------------

interface ChatMessageListProps {
  messages: IMessage[];
  children?: React.ReactNode;
}

export default function ChatMessageList({ messages = [], children }: ChatMessageListProps) {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'
    });
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }, [messages]);
  return (
    <ScrollArea className="">
      <div className="flex flex-1 lg:justify-center h-full px-10 lg:px-3 py-12" ref={messagesEndRef}>
        <div className="space-y-4 lg:w-[60rem] w-full">
          {messages.map((message, index) => (
            <ChatMessageItem isLast={index === messages.length - 1} key={index} message={message} />
          ))}
          {children}
        </div>
      </div>
    </ScrollArea>
  );
}
