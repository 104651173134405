import { fDate, fDateForApi } from '@/utils/format-time';
import { useGetCashFlowTotalsByPeriod, useGetCashFlowTotalsByPeriodSuspense } from '@repo/teal/rq-hooks/reports';
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';

import type { ChartConfig } from '@/components/ui/chart';
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent
} from '@/components/ui/chart';

const chartConfig = {
  inflow: {
    label: 'Cash In',
    color: 'hsl(var(--primary))'
  },
  outflow: {
    label: 'Cash Out',
    color: 'hsl(var(--neutral-400))'
  }
} satisfies ChartConfig;

export type PulseNumberChartProps = {
  endDate: Date;
  startDate: Date;
};

export function PulseNumberChart({ endDate, startDate }: PulseNumberChartProps) {
  const { data } = useGetCashFlowTotalsByPeriodSuspense({
    end_date: fDateForApi(endDate),
    start_date: fDateForApi(startDate),
    period: 'monthly'
  });
  return (
    <ChartContainer className="lg:w-[60%]" config={chartConfig}>
      <BarChart accessibilityLayer data={data.entries || []}>
        <CartesianGrid horizontal={false} vertical={false} />
        <XAxis
          axisLine={false}
          className="text-4xs"
          dataKey="name"
          tickFormatter={value => value.slice(0, 3)}
          tickLine={false}
          tickMargin={10}
        />
        <ChartTooltip content={<ChartTooltipContent />} />
        <Bar dataKey="inflow" fill="var(--color-inflow)" />
        <Bar dataKey="outflow" fill="var(--color-outflow)" />
        <ChartLegend content={<ChartLegendContent />} />
      </BarChart>
    </ChartContainer>
  );
}
