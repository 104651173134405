import { memo } from 'react';

import { cn } from '@/lib/utils';

function FinancesIcon({ className, active, ...other }: React.ComponentProps<'svg'> & { active?: boolean }) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M10.75 1.53846C6.07682 1.53846 2.28846 5.32682 2.28846 10C2.28846 14.6732 6.07682 18.4615 10.75 18.4615C15.4232 18.4615 19.2115 14.6732 19.2115 10C19.2115 5.32682 15.4232 1.53846 10.75 1.53846ZM0.75 10C0.75 4.47715 5.22715 0 10.75 0C16.2728 0 20.75 4.47715 20.75 10C20.75 15.5228 16.2728 20 10.75 20C5.22715 20 0.75 15.5228 0.75 10ZM10.75 3.07692C11.1748 3.07692 11.5192 3.42132 11.5192 3.84615V5.12824H11.7637C12.8002 5.09846 13.77 5.63995 14.2882 6.53894C14.5004 6.90699 14.374 7.37737 14.006 7.58954C13.6379 7.80172 13.1675 7.67535 12.9554 7.3073C12.7187 6.89683 12.275 6.65033 11.8015 6.66627C11.7929 6.66656 11.7843 6.66671 11.7756 6.66671H9.72436C9.0163 6.66671 8.44231 7.2407 8.44231 7.94876C8.44231 8.65682 9.0163 9.23081 9.72436 9.23081H11.7756C13.3334 9.23081 14.5962 10.4936 14.5962 12.0513C14.5962 13.609 13.3334 14.8718 11.7756 14.8718H11.5192V16.1538C11.5192 16.5787 11.1748 16.9231 10.75 16.9231C10.3252 16.9231 9.98077 16.5787 9.98077 16.1538V14.8718H9.73629C8.6998 14.9016 7.73003 14.3601 7.21178 13.4611C6.9996 13.0931 7.12597 12.6227 7.49403 12.4105C7.86208 12.1984 8.33245 12.3247 8.54463 12.6928C8.78125 13.1032 9.22496 13.3498 9.69848 13.3338C9.7071 13.3335 9.71573 13.3334 9.72436 13.3334H10.7421C10.7447 13.3333 10.7474 13.3333 10.75 13.3333C10.7526 13.3333 10.7553 13.3333 10.7579 13.3334H11.7756C12.4837 13.3334 13.0577 12.7594 13.0577 12.0513C13.0577 11.3433 12.4837 10.7693 11.7756 10.7693H9.72436C8.16663 10.7693 6.90385 9.50648 6.90385 7.94876C6.90385 6.39103 8.16663 5.12824 9.72436 5.12824H9.98077V3.84615C9.98077 3.42132 10.3252 3.07692 10.75 3.07692Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default memo(FinancesIcon);
