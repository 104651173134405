import '../global.css';

import { lazy } from 'react';
import AnimatedOutlet from '@/animated-outlet';
import { SocketProvider } from '@/socket/socket-provider';
import type { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
  useLocation,
  useMatch,
  useMatches
} from '@tanstack/react-router';
import { AnimatePresence } from 'framer-motion';
import ReactQueryRewind from 'react-query-rewind';

import useResponsiveness from '@/hooks/use-responsiveness';
import { ConfirmationDialog } from '@/components/confirmation-dialog';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools
        }))
      );

export interface RouterContext {
  auth: {
    accessToken: string | null;
  };
  queryClient: QueryClient;
}
export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent
});

function RootComponent() {
  const { pathname } = useLocation();
  const responsiveness = useResponsiveness();
  const matches = useMatches();
  const match = useMatch({ from: '__root__' });
  const nextMatchIndex = matches.findIndex(d => d.id === match.id) + 1;
  const nextMatch = matches[nextMatchIndex];
  return (
    <>
      <SocketProvider>
        <ScrollRestoration />
        <Outlet />
        {/*{pathname === '/auth/callback' || nextMatch.status === 'pending' ? (*/}
        {/*  <Outlet />*/}
        {/*) : (*/}
        {/*  <AnimatePresence mode="popLayout">*/}
        {/*    <AnimatedOutlet key={nextMatch.id} />*/}
        {/*  </AnimatePresence>*/}
        {/*)}*/}
        <ConfirmationDialog />
      </SocketProvider>
      {/*<ReactQueryRewind />*/}
      {/*{responsiveness !== 'xs' && <ReactQueryDevtools />}*/}
      {/*{responsiveness !== 'xs' && <TanStackRouterDevtools />}*/}
    </>
  );
}
