import { create } from 'zustand';

type MenuType =
  | 'profile_menu'
  | 'connected_accounts'
  | 'account_settings'
  | 'personal_settings'
  | 'business_profile'
  | 'milo_settings'
  | 'support'
  | 'bank_accounts'
  | 'none';

interface ProfileState {
  activeMenu: MenuType;
  isOpen: boolean;
  openMenu: (menu: MenuType) => void;
  closeMenu: () => void;
  toggleMenu: (menu: MenuType) => void;
}

export const useProfileStore = create<ProfileState>(set => ({
  activeMenu: 'none',
  isOpen: false,

  openMenu: menu => {
    console.log('open menu', menu);
    set({
      activeMenu: menu,
      isOpen: true
    });
  },

  closeMenu: () => {
    console.log('close menu');
    set({
      activeMenu: 'none',
      isOpen: false
    });
  },

  toggleMenu: menu => {
    console.log('toggle menu', menu);
    set(state => {
      if (state.activeMenu === menu && state.isOpen) {
        return {
          activeMenu: 'none',
          isOpen: false
        };
      }
      return {
        activeMenu: menu,
        isOpen: true
      };
    });
  }
}));
