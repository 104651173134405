// src/hooks/useCustomerQueries.ts
import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import { PaginatedResponse } from '../types'; // Define ICustomer interface in your types file
import { ICustomer } from './types';

export const getCustomerQueries = (axiosInstance: AxiosInstance) =>
  router('customer', {
    // Query to fetch all customers
    all: router.query<PaginatedResponse<ICustomer>, { limit?: number }, AxiosError>({
      fetcher: variables => axiosInstance.get('/customers/all', { params: variables }).then(res => res.data)
    }),

    // Query to fetch a single customer by ID
    byId: router.query<ICustomer, { id: string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/customers/${variables.id}`).then(res => res.data)
    }),

    // Mutation to create a new customer
    create: router.mutation<ICustomer, { data: Partial<ICustomer> }, AxiosError>({
      mutationFn: async variables => axiosInstance.post('/customers', variables.data).then(res => res.data)
    }),

    // Mutation to update a customer by ID
    update: router.mutation<ICustomer, { id: string; data: Partial<ICustomer> }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.put(`/customers/${variables.id}`, variables.data).then(res => res.data)
    }),

    // Mutation to delete a customer by ID
    delete: router.mutation<void, { id: string }, AxiosError>({
      mutationFn: async variables => axiosInstance.delete(`/customers/${variables.id}`).then(res => res.data)
    }),

    // Mutation to add a contact to a customer
    addContact: router.mutation<ICustomer, { id: string; data: Partial<ICustomer> }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.post(`/customers/${variables.id}/contact`, variables.data).then(res => res.data)
    }),

    // Mutation to update a specific contact in a customer
    updateContact: router.mutation<ICustomer, { id: string; contactId: string; data: Partial<ICustomer> }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance
          .put(`/customers/${variables.id}/contact/${variables.contactId}`, variables.data)
          .then(res => res.data)
    }),

    // Mutation to delete a specific contact in a customer
    deleteContact: router.mutation<ICustomer, { id: string; contactId: string }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.delete(`/customers/${variables.id}/contact/${variables.contactId}`).then(res => res.data)
    }),

    // Query to search customers by name
    searchByName: router.query<ICustomer[], { name: string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/customers/search/${variables.name}`).then(res => res.data)
    })
  });
