import FinancesIcon from '@/assets/icons/navigation/finances-icon';
import HomeIcon from '@/assets/icons/navigation/home-icon';
import LibraryIcon from '@/assets/icons/navigation/library-icon';
import TaxesIcon from '@/assets/icons/navigation/taxes-icon';
import { useAppStateStore } from '@/stores/app-state';
import { Link, linkOptions } from '@tanstack/react-router';

import { cn } from '@/lib/utils';
import {useShallow} from "zustand/react/shallow";

export type MobileNavBarProps = {
  className?: string;
};

const navConfig = [
  linkOptions({
    label: 'Home',
    icon: <HomeIcon className="size-[19px]" />,
    to: '/'
  }),
  linkOptions({
    label: 'Finances',
    icon: <FinancesIcon className="size-[19px]" />,
    to: '/finances'
  }),
  linkOptions({
    label: 'Taxes',
    icon: <TaxesIcon className="size-[19px]" />,
    to: '/taxes'
  }),
  linkOptions({
    label: 'Library',
    icon: <LibraryIcon className="size-[19px]" />,
    to: '/library/transactions'
  })
];

export default function MobileNavBar({ className }: MobileNavBarProps) {
  const isGlobalChatFocused = useAppStateStore(useShallow(state => state.isGlobalChatFocused));
  return (
    <div
      className={cn('flex h-24 justify-between border-t border-t-neutral-300 bg-white', className, {
        hidden: isGlobalChatFocused
      })}
    >
      {navConfig.map(nav => (
        <Link
          activeProps={{
            className: '!opacity-100'
          }}
          className="flex flex-col justify-center items-center gap-3 min-w-32 opacity-40"
          key={nav.label}
          to={nav.to}
        >
          <div>{nav.icon}</div>
          <div className="text-5xs">{nav.label}</div>
        </Link>
      ))}
    </div>
  );
}
