import { useState } from 'react';
import FeedbackIcon from '@/assets/icons/feedback-icon';
import type { IMessage } from '@repo/features/conversation';

import ActionsObserver from '@/lib/actions-observer';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

export type MessageFeedbackProps = {
  message: IMessage;
};

export default function MessageFeedback({ message }: MessageFeedbackProps) {
  const [feedback, setFeedback] = useState<'good' | 'bad' | undefined>(undefined);
  return (
    <div className="space-x-3 mt-4">
      <Button
        className="size-12 bg-neutral-300"
        disabled={feedback !== undefined}
        onClick={() => {
          ActionsObserver.notify('MESSAGE_FEEDBACK', { message, feedback: 'good' });
          setFeedback('good');
        }}
        size="icon"
        variant="secondary"
      >
        <FeedbackIcon
          className={cn('size-5 stroke-black', {
            'fill-primary stroke-primary': feedback === 'good'
          })}
        />
      </Button>
      <Button
        className="size-12 bg-neutral-300"
        disabled={feedback !== undefined}
        onClick={() => {
          ActionsObserver.notify('MESSAGE_FEEDBACK', { message, feedback: 'bad' });
          setFeedback('bad');
        }}
        size="icon"
        variant="secondary"
      >
        <FeedbackIcon
          className={cn('size-5 stroke-black rotate-180', {
            'fill-primary stroke-white': feedback === 'bad'
          })}
        />
      </Button>
    </div>
  );
}
