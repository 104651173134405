import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function ClipIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 15 18"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M6.66 17.835C3.41 17.835 0.770004 15.195 0.770004 11.945V6.895C0.770004 3.185 3.79 0.165001 7.5 0.165001C11.21 0.165001 14.23 3.185 14.23 6.895V11.525C14.23 13.605 12.53 15.305 10.45 15.305C8.37 15.305 6.67 13.605 6.67 11.525V8.575C6.67 8.115 7.04 7.745 7.5 7.745C7.96 7.745 8.33 8.115 8.33 8.575V11.525C8.33 12.695 9.28 13.635 10.44 13.635C11.6 13.635 12.55 12.685 12.55 11.525V6.895C12.55 4.105 10.28 1.835 7.49 1.835C4.7 1.835 2.44 4.105 2.44 6.895V11.945C2.44 14.275 4.33 16.165 6.66 16.165C7.12 16.165 7.49 16.535 7.49 16.995C7.49 17.455 7.12 17.825 6.66 17.825V17.835Z" />
    </svg>
  );
}

export default memo(ClipIcon);
