import AuthLayout from '@/layouts/auth-layout';
import ForgotPasswordView from '@/sections/auth/forgot-password-view.tsx';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guested/auth/forgot-password')({
  validateSearch: (search): { errorMessage?: string } => {
    return {
      errorMessage: search.errorMessage as string
    };
  },
  component: () => (
    <AuthLayout
      headerProps={{
        backButton: true
      }}
    >
      <ForgotPasswordView />
    </AuthLayout>
  )
});
