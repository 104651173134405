import { useMemo } from 'react';
import { splitStringUsingRegex } from '@/utils/string-helpers';
import type { MotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';

// ----------------------------------------------------------------------

type IProps = MotionProps;

export interface Props extends IProps {
  className?: string;
  value: string;
  wrapperProps?: MotionProps;
}

const charVariants = {
  hidden: { opacity: 0 },
  reveal: { opacity: 1 }
};

export default function MotionTextRevealContainer({ value, className, wrapperProps = {}, ...other }: Props) {
  const chars = useMemo(() => splitStringUsingRegex(value), [value]);
  return (
    <AnimatePresence mode="wait">
      <motion.span
        initial="hidden"
        key={value}
        transition={{ staggerChildren: 0.02 }}
        viewport={{
          amount: 'some'
        }}
        whileInView="reveal"
        {...wrapperProps}
      >
        {chars.map((char, i) => (
          <motion.span key={`${char}_${i}`} variants={charVariants} {...other}>
            {char}
          </motion.span>
        ))}
      </motion.span>
    </AnimatePresence>
  );
}
