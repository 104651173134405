import { useEffect } from 'react';
import jumping1 from '@/assets/gifs/jumping1.gif';
import ChevronRightIcon from '@/assets/icons/chevron-right-icon';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { useBoolean } from '@/hooks/use-boolean';
import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';
import { useWizard } from '@/components/form-wizard';

const AnimatedButton = motion(Button);

export default function CompanyNameStep() {
  const {
    control,
    getValues,
    trigger,
    formState: { isValid }
  } = useFormContext();
  const textRevealed = useBoolean(false);
  const [goToNextStep, stepNumber, activeStep] = useWizard(
    useShallow(state => [state.goToNextStep, state.stepNumber, state.activeStep])
  );
  const bottomAnimationConfig = {
    animate: textRevealed.value ? { opacity: 1 } : false,
    initial: { opacity: 0 },
    transition: { duration: 0.4, ease: 'easeInOut' }
  };
  const questionText = 'What’s your company name?';
  useEffect(() => {
    Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_View`, {
      questionText: questionText
    });
  }, []);
  return (
    <FormField
      control={control}
      name="companyName"
      render={({ field }) => (
        <div className="flex flex-1 flex-col gap-8 py-4 px-12 lg:py-14 lg:px-40">
          <div className="flex flex-col gap-4">
            <img className="w-20 lg:hidden" src={jumping1} />
            <div className="text-2xl">
              <MotionTextRevealContainer
                value={questionText}
                wrapperProps={{
                  onAnimationComplete() {
                    textRevealed.onTrue();
                  }
                }}
              />
            </div>
          </div>
          <div>
            <FormItem
              className={cn('animate-in fade-in duration-[800ms]', {
                hidden: !textRevealed.value
              })}
            >
              <FormControl>
                <Input
                  placeholder="e.g Awesome consulting"
                  rightIcon={
                    <Button
                      className="size-12"
                      disabled={!isValid}
                      onClick={() => {
                        const values = getValues();
                        Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_ButtonClick`, {
                          answerText: values.companyName
                        });
                      }}
                      size="icon"
                      type="submit"
                    >
                      <ChevronRightIcon />
                    </Button>
                  }
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            {activeStep.canSkip ? (
              <AnimatedButton
                {...bottomAnimationConfig}
                className="absolute right-9 lg:right-14 lg:top-14 top-7 text-xs font-semibold"
                onClick={() => {
                  goToNextStep();
                  Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_SkipClick`);
                }}
                size="auto"
                type="button"
                variant="ghost"
              >
                Skip
              </AnimatedButton>
            ) : null}
          </div>
        </div>
      )}
    />
  );
}
