import { useEffect } from 'react';
import { useAuthStore } from '@/auth/store.ts';
import type { IMessage } from '@repo/features/conversation';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { useShallow } from 'zustand/react/shallow';
import * as Sentry from '@sentry/react';

import ActionsObserver from '@/lib/actions-observer';
import useAnalytics from '@/hooks/use-analytics';

// Import the generated route tree
import { routeTree } from './routeTree.gen';

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
  interface HistoryState {
    msgs?: IMessage[];
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false
    }
  }
});

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  context: {
    auth: undefined!,
    queryClient
  }
});

Sentry.init({
  dsn: 'https://a7d1b91ea82fca42d9e7c68fd75f28e7@o4508438306226176.ingest.us.sentry.io/4508438307995648',
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration(), Sentry.tanstackRouterBrowserTracingIntegration(router)],
  environment: APP_ENV,
  enabled: APP_ENV === 'production',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // result in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/agents.stg.andmilo.com/,
    /^https:\/\/chat.stg.andmilo.com/,
    /^https:\/\/auth.stg.andmilo.com/,
    /^https:\/\/agents.andmilo.com/,
    /^https:\/\/chat.andmilo.com/,
    /^https:\/\/auth.andmilo.com/
  ]
});

export default function App() {
  useAnalytics();
  const accessToken = useAuthStore(useShallow(state => state.accessToken));

  useEffect(() => {
    ActionsObserver.notify('ON_APP_INIT');
    const unsubscribe = router.subscribe('onLoad', e => {
      ActionsObserver.notify('PAGE_VIEW', { pathname: e.toLocation.pathname });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider
        context={{
          auth: {
            accessToken
          }
        }}
        router={router}
      />
    </QueryClientProvider>
  );
}
