import { memo } from 'react';

import { cn } from '@/lib/utils';

function LibraryIcon({ className, active, ...other }: React.ComponentProps<'svg'> & { active?: boolean }) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M0.75 1.8C0.75 0.805888 1.55589 0 2.55 0H12.8357C13.8298 0 14.6357 0.805888 14.6357 1.8V2.05714H16.95C17.9441 2.05714 18.75 2.86303 18.75 3.85714V15.1714C18.75 16.7336 17.4836 18 15.9214 18H4.60714C2.4769 18 0.75 16.2731 0.75 14.1429V1.8ZM13.4013 16.4571H4.60714C3.329 16.4571 2.29286 15.421 2.29286 14.1429V1.8C2.29286 1.65798 2.40798 1.54286 2.55 1.54286H12.8357C12.9777 1.54286 13.0929 1.65798 13.0929 1.8V15.1714C13.0929 15.6344 13.2041 16.0714 13.4013 16.4571ZM14.6357 3.6V15.1714C14.6357 15.8815 15.2113 16.4571 15.9214 16.4571C16.6315 16.4571 17.2071 15.8815 17.2071 15.1714V3.85714C17.2071 3.71513 17.092 3.6 16.95 3.6H14.6357ZM4.86429 4.88571C4.86429 4.45967 5.20967 4.11429 5.63571 4.11429H9.75C10.176 4.11429 10.5214 4.45967 10.5214 4.88571C10.5214 5.31176 10.176 5.65714 9.75 5.65714H5.63571C5.20967 5.65714 4.86429 5.31176 4.86429 4.88571ZM4.86429 9C4.86429 8.57395 5.20967 8.22857 5.63571 8.22857H9.75C10.176 8.22857 10.5214 8.57395 10.5214 9C10.5214 9.42605 10.176 9.77143 9.75 9.77143H5.63571C5.20967 9.77143 4.86429 9.42605 4.86429 9ZM4.86429 13.1143C4.86429 12.6882 5.20967 12.3429 5.63571 12.3429H9.75C10.176 12.3429 10.5214 12.6882 10.5214 13.1143C10.5214 13.5403 10.176 13.8857 9.75 13.8857H5.63571C5.20967 13.8857 4.86429 13.5403 4.86429 13.1143Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default memo(LibraryIcon);
