import ChevronDownIcon from '@/icons/ChevronDown.svg?react';
import PlusIcon from '@/icons/Plus.svg?react';
import TransactionRow from '@/sections/library/transaction/transaction-row';
import TransactionSelectButton from '@/sections/library/transaction/transaction-select-button';
import TransactionTag from '@/sections/library/transaction/transaction-tag';
import type { ICustomer } from '@repo/features/customers';
import { getCustomerQueries } from '@repo/features/customers';
import type { ISuggestedAccountingLedger } from '@repo/features/transactions';
import { getTransactionsQueries } from '@repo/features/transactions';
import type { IVendor } from '@repo/features/vendors';
import { getVendorQueries } from '@repo/features/vendors';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute, Outlet, useNavigate } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

const mergeCategoriesWithCurrentLedger = (
  categories: { name: string; count: number }[],
  suggestedAccountingLedger?: ISuggestedAccountingLedger
) => {
  if (!suggestedAccountingLedger) return categories;
  const modified = [...categories] as { name: string; count: number }[];
  const category = categories.find(_category => _category.name === suggestedAccountingLedger.name);
  if (!category) {
    modified.unshift({ name: suggestedAccountingLedger.name, count: 1 });
  }
  return modified;
};

const mergeVendorWithCurrentTransactionVendors = (vendors: IVendor[], suggestedVendor?: IVendor) => {
  if (!suggestedVendor) return vendors;
  const modified = [...vendors];
  const vendor = vendors.find(_vendor => _vendor._id === suggestedVendor._id);
  if (!vendor) {
    modified.unshift(suggestedVendor);
  }
  return modified;
};

const mergeCustomerWithCurrentTransactionCustomers = (customers: ICustomer[], suggestedCustomer?: ICustomer) => {
  if (!suggestedCustomer) return customers;
  const modified = [...customers];
  const customer = customers.find(_customer => _customer._id === suggestedCustomer._id);
  if (!customer) {
    modified.unshift(suggestedCustomer);
  }
  return modified;
};

const transactionsQueries = getTransactionsQueries(axiosInstance);
export const Route = createFileRoute('/_guarded/library/transactions/$id')({
  component: TransactionDrawerPage,
  wrapInSuspense: true
});
const vendorQueries = getVendorQueries(axiosInstance);
const customerQueries = getCustomerQueries(axiosInstance);
function TransactionDrawerPage() {
  const navigate = useNavigate();
  const { id } = Route.useParams();
  const queryClient = useQueryClient();
  const { isBelowLg } = useBreakpoint('lg');
  const { data: transaction } = transactionsQueries.byId.useSuspenseQuery({
    variables: {
      id
    }
  });

  const provider = transaction?.vendor
    ? (transaction.vendor as IVendor | undefined)?.name || 'Unknown'
    : (transaction.customer as ICustomer | undefined)?.name || 'Unknown';
  const tagVariant =
    transaction?.suggestedAccountingLedger && transaction?.suggestedAccountingLedger?.score < 1
      ? 'uncertain'
      : 'default';

  const { data: vendors = [] } = vendorQueries.all.useSuspenseQuery({
    variables: {
      limit: 5
    },
    select: _data => mergeVendorWithCurrentTransactionVendors(_data.records, transaction.vendor as IVendor)
  });
  const { data: customers = [] } = customerQueries.all.useSuspenseQuery({
    variables: {
      limit: 5
    },
    select: _data => mergeCustomerWithCurrentTransactionCustomers(_data.records, transaction.customer as ICustomer)
  });
  const { data: categories = [] } = transactionsQueries.topCategories.useSuspenseQuery({
    variables: {
      limit: 5
    },
    select: _data => mergeCategoriesWithCurrentLedger(_data, transaction.suggestedAccountingLedger)
  });
  const { mutateAsync: updateTransaction } = transactionsQueries.patch.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: transactionsQueries.getKey()
      });
    }
  });

  const renderCategorySection = (
    <Collapsible>
      <div className="py-8 px-10 flex justify-between items-center gap-6 ">
        <div className="text-2xs font-medium">&Milo Category:</div>
        <div className="flex items-center gap-2">
          <TransactionTag className="bg-transparent text-2xs font-semibold normal-case" variant={tagVariant}>
            {transaction?.suggestedAccountingLedger?.name || 'Uncategorized'}
          </TransactionTag>
          <CollapsibleTrigger className="[&[data-state=open]>svg]:rotate-180">
            <ChevronDownIcon />
          </CollapsibleTrigger>
        </div>
      </div>
      <CollapsibleContent className="flex gap-4 px-10 data-[state=open]:pb-8 overflow-y-scroll lg:overflow-y-auto lg:flex-wrap scrollbar-hidden">
        {categories.map(category => (
          <TransactionSelectButton
            key={category.name}
            onClick={async () => {
              if (transaction?.suggestedAccountingLedger?.name !== category.name) {
                await updateTransaction({
                  id,
                  data: {
                    suggestedAccountingLedger: {
                      name: category.name,
                      score: 1
                    }
                  }
                });
              }
            }}
            selected={transaction?.suggestedAccountingLedger?.name === category.name}
            size="sm"
          >
            {category.name}
          </TransactionSelectButton>
        ))}
        <TransactionSelectButton
          onClick={() =>
            navigate({
              to: '/library/transactions/$id/category',
              params: {
                id
              }
            })
          }
          size="sm"
          variant="add-new"
        >
          <PlusIcon className="size-3" /> ADD NEW
        </TransactionSelectButton>
      </CollapsibleContent>
    </Collapsible>
  );

  const renderVendorSection = (
    <Collapsible>
      <div className="py-8 px-10 flex justify-between items-center gap-6 ">
        <div className="text-2xs font-medium">{transaction.amount < 0 ? 'Vendor / Contractor:' : 'Customer:'}</div>
        <div className="flex items-center gap-2">
          <div className="text-2xs font-medium">{provider}</div>
          <CollapsibleTrigger className="[&[data-state=open]>svg]:rotate-180">
            <ChevronDownIcon />
          </CollapsibleTrigger>
        </div>
      </div>
      <CollapsibleContent className="flex gap-4 px-10 data-[state=open]:pb-8 overflow-y-scroll lg:overflow-y-auto lg:flex-wrap scrollbar-hidden">
        {transaction.amount < 0
          ? vendors.map(vendor => (
              <TransactionSelectButton
                key={vendor._id}
                onClick={() => {
                  if ((transaction?.vendor as IVendor | undefined)?._id !== vendor._id) {
                    updateTransaction({
                      id,
                      data: {
                        vendor: vendor._id
                      }
                    });
                  }
                }}
                selected={vendor._id === (transaction.vendor as IVendor | undefined)?._id}
                size="sm"
              >
                {vendor.name}
              </TransactionSelectButton>
            ))
          : customers.map(customer => (
              <TransactionSelectButton
                key={customer._id}
                onClick={() => {
                  if ((transaction?.customer as ICustomer | undefined)?._id !== customer._id) {
                    updateTransaction({
                      id,
                      data: {
                        customer: customer._id
                      }
                    });
                  }
                }}
                selected={customer._id === (transaction.customer as ICustomer | undefined)?._id}
                size="sm"
              >
                {customer.name}
              </TransactionSelectButton>
            ))}
        {transaction.amount < 0 ? (
          <TransactionSelectButton
            onClick={() => navigate({ to: '/library/transactions/$id/vendor', params: { id } })}
            size="sm"
            variant="add-new"
          >
            <PlusIcon className="size-3" />
            ADD NEW
          </TransactionSelectButton>
        ) : (
          <TransactionSelectButton
            onClick={() => navigate({ to: '/library/transactions/$id/customer', params: { id } })}
            size="sm"
            variant="add-new"
          >
            <PlusIcon className="size-3" />
            ADD NEW
          </TransactionSelectButton>
        )}
      </CollapsibleContent>
    </Collapsible>
  );

  const renderSections = (
    <>
      {renderCategorySection}
      {renderVendorSection}
      <div className="py-8 px-10 flex justify-between items-center gap-6">
        <div className="text-2xs font-medium">Payment Match:</div>
        <div className="text-2xs font-medium text-neutral-500">No Match Found</div>
      </div>
    </>
  );

  return (
    <>
      <Drawer direction={isBelowLg ? 'bottom' : 'right'} onClose={() => navigate({ to: '..' })} open>
        <DrawerContent
          className={cn(
            'h-full w-full lg:w-[28.125rem] lg:rounded-none lg:rounded-tl-lg lg:rounded-bl-lg lg:right-0 lg:left-auto',
            {
              'bg-fun-red': transaction && transaction.amount < 0,
              'bg-fun-green': transaction && transaction.amount > 0
            }
          )}
          hideHandle={!isBelowLg}
        >
          <DrawerHeader className="p-8 pt-6 text-left">
            <DrawerTitle className="hidden">Edit profile</DrawerTitle>
            <DrawerDescription className="hidden">
              Make changes to your profile here. Click save when you're done.
            </DrawerDescription>
            <div>{transaction ? <TransactionRow transaction={transaction} variant="compact" /> : null}</div>
          </DrawerHeader>
          <div className="flex h-full flex-col flex-1 bg-white divide-y overflow-y-auto pb-10">
            <div className="py-8 px-10 flex flex-col gap-6">
              <div className="text-2xs leading-[150%] font-medium">
                {transaction.amount > 0 ? 'Income Type:' : 'Expense Type:'}
              </div>
              <div className="inline-flex h-9 rounded-full bg-neutral-300 p-1 w-full">
                <RadioGroup
                  className="group relative inline-grid w-full grid-cols-[1fr_1fr] items-center gap-0 text-4xs font-medium after:absolute after:inset-y-0 after:w-1/2 after:rounded-full after:bg-background after:shadow-sm after:shadow-black/5 after:outline-offset-2 after:after:transition-transform after:duration-300 after:[transition-timing-function:cubic-bezier(0.16,1,0.3,1)] has-[:focus-visible]:after:outline has-[:focus-visible]:after:outline-2 has-[:focus-visible]:after:outline-ring/70 data-[state=on]:after:translate-x-0 data-[state=off]:after:translate-x-full"
                  data-state={transaction?.personal ? 'on' : 'off'}
                  onValueChange={async value => {
                    await updateTransaction({
                      id,
                      data: {
                        personal: value === 'on'
                      }
                    });
                  }}
                  value={transaction?.personal ? 'on' : 'off'}
                >
                  <label className="relative z-10 inline-flex h-full min-w-8 cursor-pointer items-center justify-center whitespace-nowrap px-3 group-data-[state=off]:text-muted-foreground/70">
                    Personal
                    <RadioGroupItem className="sr-only" value="on" />
                  </label>
                  <label className="relative z-10 inline-flex h-full min-w-8 cursor-pointer items-center justify-center whitespace-nowrap px-3 group-data-[state=on]:text-muted-foreground/70">
                    Business
                    <RadioGroupItem className="sr-only" value="off" />
                  </label>
                </RadioGroup>
              </div>
            </div>
            {!transaction.personal && renderSections}
          </div>
        </DrawerContent>
      </Drawer>
      <Outlet />
    </>
  );
}
