import React from 'react';
import { AppSidebar } from '@/layouts/common/app-sidebar';

import { cn } from '@/lib/utils';
import { SidebarProvider } from '@/components/ui/sidebar';

// ----------------------------------------------------------------------

type ConversationLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export default function ConversationLayout({ children, className }: ConversationLayoutProps) {
  return (
    <SidebarProvider>
      <AppSidebar />
      <div className={cn('flex flex-col flex-1 w-full h-dvh overflow-hidden relative', className)}>
        <main className="flex-1 overflow-y-scroll">{children}</main>
      </div>
    </SidebarProvider>
  );
}
