import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function LyingDog({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-4', className)}
      fill="none"
      viewBox="0 0 203 135"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M110.526 14.9473V29.3925H52.8479V14.9473H110.526Z" fill="white" />
      <path d="M58.5659 29.1111V57.9863H29.9824V29.1111H58.5659Z" fill="white" />
      <path d="M110.526 58.0662V72.5114H52.8479V58.0662H110.526Z" fill="white" />
      <path d="M110.389 29.2119V43.8738H41.5737V29.2119H110.389Z" fill="white" />
      <path d="M110.545 36.2131V58.6848H52.8294V36.2131H110.545Z" fill="white" />
      <path d="M110.578 72.728V87.173H52.5079V72.728H110.578Z" fill="#000AEE" />
      <path d="M95.9519 86.9565V101.402H52.3997V86.9565H95.9519Z" fill="#000AEE" />
      <path d="M81.5916 101.185V115.63H67.1933V101.185H81.5916Z" fill="#000AEE" />
      <path d="M67.2908 101.077V130.184H52.8925V101.077H67.2908Z" fill="white" />
      <path d="M96.0654 101.005V130.111H79.8765V101.005H96.0654Z" fill="white" />
      <path d="M153.773 104.125V130.024H110.718V104.125H153.773Z" fill="white" />
      <path d="M81.604 115.832V130.162H63.425V115.832H81.604Z" fill="white" />
      <path d="M53.6917 114.764V130.075H37.3125V114.764H53.6917Z" fill="white" />
      <path d="M115.52 108.3V130.328H96.1018V108.3H115.52Z" fill="white" />
      <path d="M153.847 101.422V115.826H95.7649V101.422H153.847Z" fill="white" />
      <path d="M125.013 86.6587V102.999H94.6978V86.6587H125.013Z" fill="white" />
      <path d="M168.33 87.0508V101.741H153.941V87.0508H168.33Z" fill="white" />
      <path d="M181.62 58.1494V87.0178H167.231V58.1494H181.62Z" fill="white" />
      <path d="M110.477 0.231445V18.9736H96.0883V0.231445H110.477Z" fill="white" />
      <path d="M82.8147 0.369873V15.9462H68.4259V0.369873H82.8147Z" fill="white" />
      <path d="M68.6367 29.1765V43.6204H54.2914V29.1765H68.6367Z" fill="#000AEE" />
      <path d="M202.376 129.353V134.987H9.15527e-05V129.353H202.376Z" fill="white" />
    </svg>
  );
}

export default memo(LyingDog);
