import { AxiosError, AxiosInstance } from 'axios';
import qs from 'qs';
import { router } from 'react-query-kit';

import {
  AuthUserType,
  IUser,
  Organization,
  OrganizationCompanyMetadata,
  OrganizationFeatureFlags,
  UserOrganizationDelegation
} from './types';

export const getAuthQueries = (axiosInstance: AxiosInstance) =>
  router('auth', {
    signIn: router.mutation<
      { accessToken: string; refreshToken: string; user: AuthUserType },
      { data: { email: string; password: string } }
    >({
      mutationFn: async variables => axiosInstance.post(`/auth/signin`, variables.data).then(res => res.data)
    }),
    signUp: router.mutation<
      { accessToken: string; refreshToken: string; user: AuthUserType },
      { data: { email: string; password: string }; query?: Record<string, unknown> }
    >({
      mutationFn: async variables =>
        axiosInstance
          .post(`/auth/signup${qs.stringify(variables.query || {}, { addQueryPrefix: true })}`, variables.data)
          .then(res => res.data)
    }),
    refresh: router.mutation<
      { accessToken: string },
      { data: { refreshToken: string; accessToken: string } },
      AxiosError
    >({
      mutationFn: async ({ data }) => axiosInstance.post(`/auth/refresh`, data).then(res => res.data)
    }),
    logout: router.mutation<void, void, AxiosError>({
      mutationFn: async () => axiosInstance.post(`/auth/logout`)
    }),
    me: router.query<
      Omit<AuthUserType, 'organization'> & {
        organization: Organization;
      },
      void,
      AxiosError
    >({
      fetcher: () => axiosInstance.get(`/me`).then(res => res.data)
    }),
    organizations: router.query<UserOrganizationDelegation[], void, AxiosError>({
      fetcher: () => axiosInstance.get(`/me/organization/all`).then(res => res.data)
    }),
    createOrganization: router.mutation<Organization, { data: { name: string } }>({
      mutationFn: async variables => axiosInstance.post(`/me/organization`, variables.data).then(res => res.data)
    }),
    updateOrganizationCompanyMetadata: router.mutation<
      OrganizationCompanyMetadata,
      { id: string; data: Partial<OrganizationCompanyMetadata> }
    >({
      mutationFn: async variables =>
        axiosInstance.put(`/organizations/${variables.id}/company-metadata`, variables.data).then(res => res.data)
    }),
    updateOrganizationFlags: router.mutation<
      OrganizationFeatureFlags,
      {
        id: string;
        data: OrganizationFeatureFlags;
      }
    >({
      mutationFn: async variables =>
        axiosInstance.put(`/organizations/${variables.id}/features`, variables.data).then(res => res.data)
    }),
    updateOrganization: router.mutation<
      Organization,
      {
        id: string;
        data: { name?: string; emailSettings?: { onBehalfOf?: string } };
      }
    >({
      mutationFn: async variables =>
        axiosInstance.put(`/organizations/${variables.id}`, variables.data).then(res => res.data)
    }),
    updateUser: router.mutation<
        IUser,
        {
          id: string;
          data: Pick<IUser, 'firstName' | 'lastName'>;
        }
    >({
      mutationFn: async variables =>
          axiosInstance.patch(`/me/update`, variables.data).then(res => res.data)
    }),
    switchOrganization: router.mutation<Organization, { data: { organizationId: string } }>({
      mutationFn: async variables => axiosInstance.put(`/me/organization`, variables.data).then(res => res.data)
    }),
    getUserById: router.query<IUser, { id: number | string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/users/${variables.id}`).then(res => res.data)
    }),
    forgotPassword: router.mutation<void, { data: { email: string } }, AxiosError<{ message: string }>>({
      mutationFn: async ({ data }) => axiosInstance.post(`/forgot-password`, data).then(res => res.data)
    }),
    resetPassword: router.mutation<
      void,
      { data: { token: string; password: string; confirmPassword: string } },
      AxiosError<{ message: string }>
    >({
      mutationFn: async ({ data }) => axiosInstance.post(`/reset-password`, data).then(res => res.data)
    }),
    acceptInvite: router.mutation<
      void,
      { data: { tokenId: string; password: string; confirmPassword: string } },
      AxiosError<{ message: string }>
    >({
      mutationFn: async ({ data }) => axiosInstance.get(`/invite/accept?${qs.stringify(data)}`).then(res => res.data)
    })
  });
