import AuthLayout from '@/layouts/auth-layout';
import AcceptInviteView from '@/sections/auth/accept-invite-view.tsx';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guested/auth/invite/$token')({
  validateSearch: (search): { errorMessage?: string } => {
    return {
      errorMessage: search.errorMessage as string
    };
  },
  component: () => (
    <AuthLayout>
      <AcceptInviteView />
    </AuthLayout>
  )
});
