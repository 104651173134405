import AppHeader from '@/layouts/common/app-header';
import { HeaderPortal } from '@/layouts/common/header-portal';
import MainLayout from '@/layouts/main-layout';
import HomePageView from '@/sections/home/home-page-view';
import { createFileRoute } from '@tanstack/react-router';

import { useBreakpoint } from '@/hooks/use-breakpoint';

export const Route = createFileRoute('/_guarded/')({
  component: () => {
    const { isBelowLg } = useBreakpoint('lg');
    return (
      <MainLayout
        className="bg-primary"
        inputProps={{
          variant: isBelowLg ? 'dark' : 'light'
        }}
      >
        <HeaderPortal>
          <AppHeader className="items-end lg:items-center" variant="dark" />
        </HeaderPortal>
        <HomePageView />
      </MainLayout>
    );
  }
});
