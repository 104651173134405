import { AxiosInstance } from 'axios';
import { useImmer } from 'use-immer';

import { getFileQueries } from '../queries';

export const useUploadMultipleFiles = (axiosInstance: AxiosInstance) => {
  const fileQueries = getFileQueries(axiosInstance);
  const { mutateAsync: signAndUpload } = fileQueries.signAndUpload.useMutation();
  const [filesProgress, setFilesProgress] = useImmer<{ [key: string]: number | undefined }>({});
  const upload = async ({ files }: { files: File[] }) => {
    let fileIds: string[] = [];
    if (files.length > 0) {
      const uploadFiles = async (files: File[]) => {
        const promises: Promise<string>[] = [];
        for (const file of files) {
          promises.push(
            signAndUpload({
              file,
              onUploadProgress: progressEvent => {
                setFilesProgress(progresses => {
                  progresses[file.name] = progressEvent.progress;
                });
              }
            })
          );
        }
        fileIds = await Promise.all(promises);
        return fileIds;
      };

      fileIds = await uploadFiles(files);
    }
    return fileIds;
  };

  return {
    upload,
    progress: filesProgress
  };
};
