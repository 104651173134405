import AuthLayout from '@/layouts/auth-layout';
import ResetPasswordView from '@/sections/auth/reset-password-view.tsx';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guested/auth/reset-password/$token')({
  validateSearch: (search): { firstName?: string } => {
    return {
      firstName: search.firstName as string
    };
  },
  component: () => (
    <AuthLayout>
      <ResetPasswordView />
    </AuthLayout>
  )
});
