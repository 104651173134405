import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import {
  AccountingBalancesStatus,
  AccountingExpectedCashEndOfMonth,
  AccountingFinancialSummary,
  AccountingMonthlyCashFlowSummary,
  AccountingPayableAgingByVendor,
  AccountingReceivableAgingByCustomer,
  AccountingTopExpense
} from './types';

export const getAccountingDataQueries = (axiosInstance: AxiosInstance) =>
  router('/reports/accounting', {
    accountingFinancialSummary: router.query<
      AccountingFinancialSummary,
      { startDate: string; endDate: string },
      AxiosError
    >({
      fetcher: ({ startDate, endDate }) =>
        axiosInstance
          .get(`/reports/accounting/financial-summary`, {
            params: { startDate, endDate }
          })
          .then(({ data }) => data)
    }),
    accountingExpectedBalance: router.query<
      AccountingExpectedCashEndOfMonth,
      { startDate: string; endDate: string },
      AxiosError
    >({
      fetcher: ({ startDate, endDate }) =>
        axiosInstance
          .get(`/reports/accounting/expected-balance`, {
            params: { startDate, endDate }
          })
          .then(({ data }) => data)
    }),

    accountingAccountsBalance: router.query<AccountingBalancesStatus, { date: string }, AxiosError>({
      fetcher: ({ date }) =>
        axiosInstance
          .get(`/reports/accounting/accounts-balance`, {
            params: { date }
          })
          .then(({ data }) => data)
    }),
    accountingCashflowByMonth: router.query<
      AccountingMonthlyCashFlowSummary[],
      { startDate: string; endDate: string },
      AxiosError
    >({
      fetcher: ({ startDate, endDate }) =>
        axiosInstance
          .get(`/reports/accounting/cashflow-by-month`, {
            params: { startDate, endDate }
          })
          .then(({ data }) => data)
    }),
    accountingArExpected: router.query<AccountingReceivableAgingByCustomer[], { date: string }, AxiosError>({
      fetcher: ({ date }) =>
        axiosInstance
          .get(`/reports/accounting/ar/expected`, {
            params: { date }
          })
          .then(({ data }) => data?.data)
    }),
    accountingApExpected: router.query<AccountingPayableAgingByVendor[], { date: string }, AxiosError>({
      fetcher: ({ date }) =>
        axiosInstance
          .get(`/reports/accounting/ap/expected`, {
            params: { date }
          })
          .then(({ data }) => data?.data)
    }),
    accountingTopExpenses: router.query<AccountingTopExpense[], { startDate: string; endDate: string }, AxiosError>({
      fetcher: ({ startDate, endDate }) =>
        axiosInstance
          .get(`/reports/accounting/top-expenses`, {
            params: { startDate, endDate }
          })
          .then(({ data }) => data)
    })
  });
