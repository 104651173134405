import { useEffect } from 'react';
import { useAuthStore } from '@/auth/store';
import { isValidToken } from '@/auth/utils';
import { PATH_AFTER_LOGIN } from '@/config-global';
import { getAuthQueries } from '@repo/features/auth';
import { useNavigate } from '@tanstack/react-router';
import ReactGA from 'react-ga4';
import { useShallow } from 'zustand/react/shallow';

import ActionsObserver from '@/lib/actions-observer';
import { authAxiosInstance } from '@/lib/axios.ts';

const authQueries = getAuthQueries(authAxiosInstance);
const useAuth = () => {
  const navigate = useNavigate();
  const [accessToken, refreshToken, setAccessToken, setRefreshToken, selectedOrgId, selectOrganization] = useAuthStore(
    useShallow(store => [
      store.accessToken,
      store.refreshToken,
      store.setAccessToken,
      store.setRefreshToken,
      store.selectedOrgId,
      store.selectOrganization
    ])
  );

  const authenticated = accessToken ? isValidToken(accessToken) : false;
  const { data: user, isFetched } = authQueries.me.useQuery({
    // placeholderData: keepPreviousData,
    enabled: authenticated,
    gcTime: 2 * 60 * 1000
  });

  useEffect(() => {
    if (user && authenticated && !selectedOrgId && isFetched) {
      selectOrganization(user.organizations[0].organization._id);
    }
  }, [user, selectOrganization, selectedOrgId, authenticated, isFetched]);

  useEffect(() => {
    if (user && selectedOrgId && isFetched) {
      ReactGA.set({
        user_id: user._id,
        user_properties: {
          org_id: selectedOrgId
        }
      });
    }
  }, [user, selectedOrgId, isFetched]);
  // SIGN IN
  const { mutateAsync: signIn, isPending: isSignInPending } = authQueries.signIn.useMutation({
    onSuccess: async data => {
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);

      ActionsObserver.notify('DATABASE_SIGN_IN');

      await navigate({ to: PATH_AFTER_LOGIN });
    }
  });

  const delegationWithOrg = user?.organizations.find(o => o.organization._id === selectedOrgId);
  // SIGN UP
  const { mutateAsync: signUp, isPending: isSignUpPending } = authQueries.signUp.useMutation({
    onSuccess: async data => {
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);
      ActionsObserver.notify('DATABASE_SIGN_UP');

      await navigate({ to: PATH_AFTER_LOGIN });
    }
  });

  // const isInitializing = isUserLoading || isSignUpPending || isSignInPending;

  const logout = async () => {
    await navigate({ to: '/logout' });
  };

  const isAccountingConnected =
    user?.organizations.find(o => o.organization._id === selectedOrgId)?.organization.accountingMetadata?.status ===
    'active';

  return {
    isAccountingConnected,
    signIn,
    signUp,
    logout,
    authenticated,
    user,
    accessToken,
    refreshToken,
    selectOrganization,
    selectedOrgId,
    delegationWithOrg,
    isSignInPending,
    isSignUpPending
  };
};

export default useAuth;
