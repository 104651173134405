import BackArrowIcon from '@/assets/icons/back-arrow-icon';
import { fToNow } from '@/utils/format-time';
import { useRouter } from '@tanstack/react-router';
import { parseISO } from 'date-fns';

import { cn } from '@/lib/utils';

export type PageTitleProps = {
  time?: string;
  headerClassName?: string;
  containerClassName?: string;
  showBackButton?: boolean;
  category?: string;
  subject?: string;
};

export default function ConversationPageTitle({
  headerClassName,
  containerClassName,
  subject,
  time,
  category,
  showBackButton = false
}: PageTitleProps) {
  const { history } = useRouter();
  return (
    <div
      className={cn(
        'flex items-center gap-10 px-7 py-5 lg:px-12 lg:py-6 border-b border-b-neutral-300 h-auto lg:h-34 overflow-hidden sticky top-0 ',
        containerClassName
      )}
    >
      {showBackButton ? (
        <div
          className="cursor-pointer"
          onClick={() => {
            history.back();
          }}
        >
          <BackArrowIcon className="size-5" />
        </div>
      ) : null}
      {subject ? (
        <div className="animate-in slide-in-from-right text-ellipsis min-w-0">
          <div className={cn('text-md font-bold lg:text-xl lg:font-semibold truncate', headerClassName)}>{subject}</div>
          <div className="text-3xs font-semibold text-neutral-500 mt-1">
            {time ? <span>{fToNow(parseISO(time))}</span> : null}
            {category ? <span className="ml-3">{`#${category}`}</span> : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
