import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface AuthState {
  selectedOrgId: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

export interface AuthActions {
  selectOrganization: (organization: string | null) => void;
  setAccessToken: (accessToken: string | null) => void;
  setRefreshToken: (refreshToken: string | null) => void;
  reset: () => void;
}

export type AuthStore = AuthState & AuthActions;

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  selectedOrgId: null
};

export const useAuthStore = create<AuthStore>()(
  persist(
    set => ({
      ...initialState,
      setAccessToken: accessToken => {
        set({
          accessToken
        });
      },
      setRefreshToken: refreshToken => {
        set({
          refreshToken
        });
      },
      reset: () => {
        set(initialState);
      },
      selectOrganization: organizationId => {
        set({ selectedOrgId: organizationId });
      }
    }),
    {
      partialize: ({ accessToken, refreshToken, selectedOrgId }) => ({
        accessToken,
        refreshToken,
        selectedOrgId
      }),
      version: 0.2,
      name: 'auth'
    }
  )
);
