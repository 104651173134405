import { format } from 'date-fns';

export type TransactionFiltersProps = {};

export default function TransactionFilters({}: TransactionFiltersProps) {
  return (
    <div className="px-8 py-6 border-b border-neutral-300 lg:p-10 lg:h-34 flex flex-col justify-center">
      <div className="text-base font-bold">All Transactions, {format(new Date(), 'MMMM')}</div>
      <div />
    </div>
  );
}
