import { memo } from 'react';

import { cn } from '@/lib/utils';

function NotificationsIcon({ className, active, ...other }: React.ComponentProps<'svg'> & { active?: boolean }) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="#fff"
      stroke="#fff"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M9.00001 2.38462C8.36275 2.38462 7.84616 2.90121 7.84616 3.53846C7.84616 3.80617 7.69181 4.04989 7.44979 4.16433C5.52584 5.07407 4.25938 6.96889 4.15385 9.09362V11.8462C4.15385 11.874 4.15218 11.9017 4.14884 11.9293C4.06078 12.6568 3.79363 13.3395 3.38366 13.9231H14.6164C14.2064 13.3395 13.9392 12.6568 13.8512 11.9293C13.8478 11.9017 13.8462 11.874 13.8462 11.8462V9.09362C13.7406 6.96889 12.4742 5.07407 10.5502 4.16433C10.3082 4.04989 10.1539 3.80617 10.1539 3.53846C10.1539 2.90121 9.63726 2.38462 9.00001 2.38462ZM11.0769 15.3077H6.92308V15.5385C6.92308 16.6855 7.85295 17.6154 9.00001 17.6154C10.1471 17.6154 11.0769 16.6855 11.0769 15.5385V15.3077ZM5.53847 15.3077V15.5385C5.53847 17.4502 7.08825 19 9.00001 19C10.9118 19 12.4615 17.4502 12.4615 15.5385V15.3077H16.3846C16.699 15.3077 16.974 15.0958 17.0541 14.7918C17.1342 14.4877 16.9994 14.1679 16.7258 14.013C15.9084 13.5501 15.3557 12.7316 15.2308 11.8022V9.07692C15.2308 9.0661 15.2305 9.05528 15.23 9.04446C15.1123 6.53595 13.6895 4.2835 11.4997 3.09407C11.2896 1.904 10.2504 1 9.00001 1C7.74965 1 6.71044 1.904 6.50031 3.09407C4.3105 4.2835 2.88774 6.53595 2.77 9.04446C2.76949 9.05528 2.76924 9.0661 2.76924 9.07692V11.8022C2.6443 12.7316 2.09159 13.5501 1.27425 14.013C1.00066 14.1679 0.865822 14.4877 0.945931 14.7918C1.02604 15.0958 1.30097 15.3077 1.61539 15.3077H5.53847Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.3"
      />
    </svg>
  );
}

export default memo(NotificationsIcon);
