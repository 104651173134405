import BackArrowIcon from '@/assets/icons/back-arrow-icon';
import { Link, useRouter, useRouterState } from '@tanstack/react-router';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import Logo from '@/components/logo';

export type MobileAuthHeaderProps = {
  className?: string;
  backButton?: boolean;
};

export default function AuthHeader({ className, backButton = false }: MobileAuthHeaderProps) {
  const { history } = useRouter();
  const { location } = useRouterState();
  const isSignInPage = location.pathname === '/auth/signin';
  const isSignUpPage = location.pathname === '/auth/signup';
  return (
    <div className={cn('h-21 w-full flex items-center justify-between sticky top-0 px-7 lg:p-14', className)}>
      <Logo
        beta
        className={cn('lg:invisible', {
          hidden: backButton
        })}
      />
      {isSignInPage ? (
        <Link className="text-xs font-semibold" replace search={prev => prev} to="/auth/signup">
          Sign Up
        </Link>
      ) : null}
      {isSignUpPage ? (
        <Link className="text-xs font-semibold" replace search={prev => prev} to="/auth/signin">
          Sign In
        </Link>
      ) : null}
      <Button
        className={cn({
          hidden: !backButton
        })}
        onClick={() => {
          history.back();
        }}
        size="auto"
        variant="icon"
      >
        <BackArrowIcon className={cn('size-5')} />
      </Button>
    </div>
  );
}
