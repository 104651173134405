import { memo } from 'react';

import { cn } from '@/lib/utils';

function ChevronRightIcon({ className, active, ...other }: React.ComponentProps<'svg'> & { active?: boolean }) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="white"
      stroke="white"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M7.61707 12.4829C7.46086 12.3267 7.46086 12.0735 7.61707 11.9173L10.5342 9.0001L7.61707 6.08294C7.46086 5.92673 7.46086 5.67346 7.61707 5.51726C7.77328 5.36105 8.02654 5.36105 8.18275 5.51726L11.3828 8.71725C11.539 8.87346 11.539 9.12673 11.3828 9.28294L8.18275 12.4829C8.02654 12.6392 7.77328 12.6392 7.61707 12.4829Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(ChevronRightIcon);
