import { useGetFinancialSummariesByPeriodSuspense } from '@repo/teal/rq-hooks/reports';
import { format } from 'date-fns';

import StatisticCard from '../../components/statistic-card.tsx';

export type ExpensesMetricProps = {
  startDate: Date;
  endDate?: Date;
  title: string;
};

export default function ExpensesMetric({ startDate, endDate, ...other }: ExpensesMetricProps) {
  const { data: financialData } = useGetFinancialSummariesByPeriodSuspense({
    start_date: format(startDate, 'yyyy-MM-dd'),
    end_date: endDate ? format(endDate, 'yyyy-MM-dd') : format(startDate, 'yyyy-MM-dd'),
    period: 'monthly'
  });
  return <StatisticCard amount={financialData.summary.expense || 0} {...other} />;
}
