import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function BackArrowIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 15 11"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M13.825 4.31752H4.025L5.625 2.71752C6.085 2.25752 6.085 1.50752 5.625 1.05752C5.165 0.597524 4.415 0.597524 3.965 1.05752L0.345 4.66752C-0.115 5.12752 -0.115 5.87752 0.345 6.33752L3.955 9.94752C4.175 10.1675 4.475 10.2875 4.785 10.2875C5.095 10.2875 5.395 10.1675 5.615 9.94752C5.835 9.72752 5.965 9.42752 5.965 9.11752C5.965 8.80752 5.845 8.50752 5.625 8.28752L4.025 6.68752H13.825C14.475 6.68752 15.005 6.15752 15.005 5.50752C15.005 4.85752 14.475 4.32752 13.825 4.32752V4.31752Z" />
    </svg>
  );
}

export default memo(BackArrowIcon);
