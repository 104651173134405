/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Teal API
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type {
  EnhancedTag,
  EnhancedTagPage,
  GetInstanceTagsParams,
  GetTagRulesParams,
  Tag,
  TagClear,
  TagCreate,
  TagGroup,
  TagGroupCreate,
  TagGroupUpdate,
  TagRule,
  TagRuleCreate,
  TagRulePage,
  TagRuleUpdate,
  TagUpdate,
  TealErrorResponse,
} from "../types";
import { createTealInstance } from "../axiosInstance";

/**
 * Retrieves an existing tag group.
 * @summary Retrieve a tag group
 */
export const getTagGroup = (tagGroupId: string, signal?: AbortSignal) => {
  return createTealInstance<TagGroup>({
    url: `/v0/platform/tag-groups/${tagGroupId}`,
    method: "GET",
    signal,
  });
};

export const getGetTagGroupQueryKey = (tagGroupId: string) => {
  return [`/v0/platform/tag-groups/${tagGroupId}`] as const;
};

export const getGetTagGroupQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagGroup>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagGroupQueryKey(tagGroupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagGroup>>> = ({
    signal,
  }) => getTagGroup(tagGroupId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tagGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTagGroup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagGroupQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTagGroup>>
>;
export type GetTagGroupQueryError = TealErrorResponse;

export function useGetTagGroup<
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagGroup>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTagGroup>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagGroup<
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagGroup>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTagGroup>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagGroup<
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagGroup>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a tag group
 */

export function useGetTagGroup<
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagGroup>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagGroupQueryOptions(tagGroupId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve a tag group
 */
export const prefetchGetTagGroup = async <
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagGroup>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetTagGroupQueryOptions(tagGroupId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetTagGroupSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagGroup>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagGroupQueryKey(tagGroupId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagGroup>>> = ({
    signal,
  }) => getTagGroup(tagGroupId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tagGroupId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getTagGroup>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagGroupSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTagGroup>>
>;
export type GetTagGroupSuspenseQueryError = TealErrorResponse;

export function useGetTagGroupSuspense<
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagGroup>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagGroupSuspense<
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagGroup>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagGroupSuspense<
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagGroup>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a tag group
 */

export function useGetTagGroupSuspense<
  TData = Awaited<ReturnType<typeof getTagGroup>>,
  TError = TealErrorResponse,
>(
  tagGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagGroup>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagGroupSuspenseQueryOptions(tagGroupId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Deletes an existing tag group from the platform. This will also remove
all tags this tag group from all line entries and transactions.
 * @summary Delete a tag group
 */
export const deleteTagGroup = (tagGroupId: string) => {
  return createTealInstance<void>({
    url: `/v0/platform/tag-groups/${tagGroupId}`,
    method: "DELETE",
  });
};

export const getDeleteTagGroupMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTagGroup>>,
    TError,
    { tagGroupId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTagGroup>>,
  TError,
  { tagGroupId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTagGroup>>,
    { tagGroupId: string }
  > = (props) => {
    const { tagGroupId } = props ?? {};

    return deleteTagGroup(tagGroupId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTagGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTagGroup>>
>;

export type DeleteTagGroupMutationError = TealErrorResponse;

/**
 * @summary Delete a tag group
 */
export const useDeleteTagGroup = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTagGroup>>,
    TError,
    { tagGroupId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTagGroup>>,
  TError,
  { tagGroupId: string },
  TContext
> => {
  const mutationOptions = getDeleteTagGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates an existing tag group. Unspecified fields will not be updated.
 * @summary Update a tag group
 */
export const updateTagGroup = (
  tagGroupId: string,
  tagGroupUpdate: TagGroupUpdate,
) => {
  return createTealInstance<unknown>({
    url: `/v0/platform/tag-groups/${tagGroupId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: tagGroupUpdate,
  });
};

export const getUpdateTagGroupMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTagGroup>>,
    TError,
    { tagGroupId: string; data: TagGroupUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTagGroup>>,
  TError,
  { tagGroupId: string; data: TagGroupUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTagGroup>>,
    { tagGroupId: string; data: TagGroupUpdate }
  > = (props) => {
    const { tagGroupId, data } = props ?? {};

    return updateTagGroup(tagGroupId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTagGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTagGroup>>
>;
export type UpdateTagGroupMutationBody = TagGroupUpdate;
export type UpdateTagGroupMutationError = TealErrorResponse;

/**
 * @summary Update a tag group
 */
export const useUpdateTagGroup = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTagGroup>>,
    TError,
    { tagGroupId: string; data: TagGroupUpdate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTagGroup>>,
  TError,
  { tagGroupId: string; data: TagGroupUpdate },
  TContext
> => {
  const mutationOptions = getUpdateTagGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List all tag groups.
 * @summary List all tag groups
 */
export const getTagGroups = (signal?: AbortSignal) => {
  return createTealInstance<TagGroup[]>({
    url: `/v0/platform/tag-groups`,
    method: "GET",
    signal,
  });
};

export const getGetTagGroupsQueryKey = () => {
  return [`/v0/platform/tag-groups`] as const;
};

export const getGetTagGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getTagGroups>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagGroupsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagGroups>>> = ({
    signal,
  }) => getTagGroups(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTagGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTagGroups>>
>;
export type GetTagGroupsQueryError = TealErrorResponse;

export function useGetTagGroups<
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getTagGroups>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getTagGroups>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagGroups<
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getTagGroups>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getTagGroups>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagGroups<
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getTagGroups>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all tag groups
 */

export function useGetTagGroups<
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getTagGroups>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagGroupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List all tag groups
 */
export const prefetchGetTagGroups = async <
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagGroups>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetTagGroupsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetTagGroupsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getTagGroups>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagGroupsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagGroups>>> = ({
    signal,
  }) => getTagGroups(signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getTagGroups>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagGroupsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTagGroups>>
>;
export type GetTagGroupsSuspenseQueryError = TealErrorResponse;

export function useGetTagGroupsSuspense<
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getTagGroups>>,
      TError,
      TData
    >
  >;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagGroupsSuspense<
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getTagGroups>>,
      TError,
      TData
    >
  >;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagGroupsSuspense<
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getTagGroups>>,
      TError,
      TData
    >
  >;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all tag groups
 */

export function useGetTagGroupsSuspense<
  TData = Awaited<ReturnType<typeof getTagGroups>>,
  TError = TealErrorResponse,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof getTagGroups>>,
      TError,
      TData
    >
  >;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagGroupsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Creates a tag group for the platform.
 * @summary Create a tag group
 */
export const createTagGroup = (tagGroupCreate: TagGroupCreate) => {
  return createTealInstance<TagGroup>({
    url: `/v0/platform/tag-groups`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: tagGroupCreate,
  });
};

export const getCreateTagGroupMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTagGroup>>,
    TError,
    { data: TagGroupCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTagGroup>>,
  TError,
  { data: TagGroupCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTagGroup>>,
    { data: TagGroupCreate }
  > = (props) => {
    const { data } = props ?? {};

    return createTagGroup(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTagGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTagGroup>>
>;
export type CreateTagGroupMutationBody = TagGroupCreate;
export type CreateTagGroupMutationError = TealErrorResponse;

/**
 * @summary Create a tag group
 */
export const useCreateTagGroup = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTagGroup>>,
    TError,
    { data: TagGroupCreate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createTagGroup>>,
  TError,
  { data: TagGroupCreate },
  TContext
> => {
  const mutationOptions = getCreateTagGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Lists all tag rules.
 * @summary List all tag rules
 */
export const getTagRules = (
  params?: GetTagRulesParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<TagRulePage>({
    url: `/v0/tags/rules`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetTagRulesQueryKey = (params?: GetTagRulesParams) => {
  return [`/v0/tags/rules`, ...(params ? [params] : [])] as const;
};

export const getGetTagRulesQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRules>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagRulesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagRules>>> = ({
    signal,
  }) => getTagRules(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTagRules>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagRulesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTagRules>>
>;
export type GetTagRulesQueryError = TealErrorResponse;

export function useGetTagRules<
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params: undefined | GetTagRulesParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRules>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTagRules>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagRules<
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRules>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTagRules>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagRules<
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRules>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all tag rules
 */

export function useGetTagRules<
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRules>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagRulesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List all tag rules
 */
export const prefetchGetTagRules = async <
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRules>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetTagRulesQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetTagRulesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRules>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagRulesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagRules>>> = ({
    signal,
  }) => getTagRules(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getTagRules>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagRulesSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTagRules>>
>;
export type GetTagRulesSuspenseQueryError = TealErrorResponse;

export function useGetTagRulesSuspense<
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params: undefined | GetTagRulesParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRules>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagRulesSuspense<
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRules>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagRulesSuspense<
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRules>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all tag rules
 */

export function useGetTagRulesSuspense<
  TData = Awaited<ReturnType<typeof getTagRules>>,
  TError = TealErrorResponse,
>(
  params?: GetTagRulesParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRules>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagRulesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Creates a new tag rule for an existing instance.
 * @summary Create a tag rule
 */
export const addTagRule = (tagRuleCreate: TagRuleCreate) => {
  return createTealInstance<TagRule>({
    url: `/v0/tags/rules`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: tagRuleCreate,
  });
};

export const getAddTagRuleMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addTagRule>>,
    TError,
    { data: TagRuleCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addTagRule>>,
  TError,
  { data: TagRuleCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addTagRule>>,
    { data: TagRuleCreate }
  > = (props) => {
    const { data } = props ?? {};

    return addTagRule(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddTagRuleMutationResult = NonNullable<
  Awaited<ReturnType<typeof addTagRule>>
>;
export type AddTagRuleMutationBody = TagRuleCreate;
export type AddTagRuleMutationError = TealErrorResponse;

/**
 * @summary Create a tag rule
 */
export const useAddTagRule = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addTagRule>>,
    TError,
    { data: TagRuleCreate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addTagRule>>,
  TError,
  { data: TagRuleCreate },
  TContext
> => {
  const mutationOptions = getAddTagRuleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves an existing tag rule.
 * @summary Retrieve a tag rule
 */
export const getTagRule = (tagRuleId: string, signal?: AbortSignal) => {
  return createTealInstance<TagRule>({
    url: `/v0/tags/rules/${tagRuleId}`,
    method: "GET",
    signal,
  });
};

export const getGetTagRuleQueryKey = (tagRuleId: string) => {
  return [`/v0/tags/rules/${tagRuleId}`] as const;
};

export const getGetTagRuleQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRule>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagRuleQueryKey(tagRuleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagRule>>> = ({
    signal,
  }) => getTagRule(tagRuleId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tagRuleId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTagRule>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagRuleQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTagRule>>
>;
export type GetTagRuleQueryError = TealErrorResponse;

export function useGetTagRule<
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRule>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTagRule>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagRule<
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRule>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTagRule>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagRule<
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRule>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a tag rule
 */

export function useGetTagRule<
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRule>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagRuleQueryOptions(tagRuleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve a tag rule
 */
export const prefetchGetTagRule = async <
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTagRule>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetTagRuleQueryOptions(tagRuleId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetTagRuleSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRule>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagRuleQueryKey(tagRuleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagRule>>> = ({
    signal,
  }) => getTagRule(tagRuleId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tagRuleId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getTagRule>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagRuleSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTagRule>>
>;
export type GetTagRuleSuspenseQueryError = TealErrorResponse;

export function useGetTagRuleSuspense<
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRule>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagRuleSuspense<
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRule>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagRuleSuspense<
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRule>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a tag rule
 */

export function useGetTagRuleSuspense<
  TData = Awaited<ReturnType<typeof getTagRule>>,
  TError = TealErrorResponse,
>(
  tagRuleId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getTagRule>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagRuleSuspenseQueryOptions(tagRuleId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Deletes an existing tag rule.
 * @summary Delete a tag rule
 */
export const deleteTagRule = (tagRuleId: string) => {
  return createTealInstance<void>({
    url: `/v0/tags/rules/${tagRuleId}`,
    method: "DELETE",
  });
};

export const getDeleteTagRuleMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTagRule>>,
    TError,
    { tagRuleId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTagRule>>,
  TError,
  { tagRuleId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTagRule>>,
    { tagRuleId: string }
  > = (props) => {
    const { tagRuleId } = props ?? {};

    return deleteTagRule(tagRuleId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTagRuleMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTagRule>>
>;

export type DeleteTagRuleMutationError = TealErrorResponse;

/**
 * @summary Delete a tag rule
 */
export const useDeleteTagRule = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTagRule>>,
    TError,
    { tagRuleId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTagRule>>,
  TError,
  { tagRuleId: string },
  TContext
> => {
  const mutationOptions = getDeleteTagRuleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates a tag rule. Unspecified fields will not be updated.
 * @summary Update a tag rule
 */
export const updateTagRule = (
  tagRuleId: string,
  tagRuleUpdate: TagRuleUpdate,
) => {
  return createTealInstance<unknown>({
    url: `/v0/tags/rules/${tagRuleId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: tagRuleUpdate,
  });
};

export const getUpdateTagRuleMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTagRule>>,
    TError,
    { tagRuleId: string; data: TagRuleUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTagRule>>,
  TError,
  { tagRuleId: string; data: TagRuleUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTagRule>>,
    { tagRuleId: string; data: TagRuleUpdate }
  > = (props) => {
    const { tagRuleId, data } = props ?? {};

    return updateTagRule(tagRuleId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTagRuleMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTagRule>>
>;
export type UpdateTagRuleMutationBody = TagRuleUpdate;
export type UpdateTagRuleMutationError = TealErrorResponse;

/**
 * @summary Update a tag rule
 */
export const useUpdateTagRule = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTagRule>>,
    TError,
    { tagRuleId: string; data: TagRuleUpdate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTagRule>>,
  TError,
  { tagRuleId: string; data: TagRuleUpdate },
  TContext
> => {
  const mutationOptions = getUpdateTagRuleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves an existing tag.
 * @summary Retrieve a tag
 */
export const getTag = (tagId: string, signal?: AbortSignal) => {
  return createTealInstance<EnhancedTag>({
    url: `/v0/tags/${tagId}`,
    method: "GET",
    signal,
  });
};

export const getGetTagQueryKey = (tagId: string) => {
  return [`/v0/tags/${tagId}`] as const;
};

export const getGetTagQueryOptions = <
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagQueryKey(tagId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTag>>> = ({
    signal,
  }) => getTag(tagId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tagId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetTagQueryResult = NonNullable<Awaited<ReturnType<typeof getTag>>>;
export type GetTagQueryError = TealErrorResponse;

export function useGetTag<
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTag>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTag<
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTag>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTag<
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a tag
 */

export function useGetTag<
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagQueryOptions(tagId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve a tag
 */
export const prefetchGetTag = async <
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  tagId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetTagQueryOptions(tagId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetTagSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagQueryKey(tagId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTag>>> = ({
    signal,
  }) => getTag(tagId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tagId,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getTag>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTagSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTag>>
>;
export type GetTagSuspenseQueryError = TealErrorResponse;

export function useGetTagSuspense<
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagSuspense<
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetTagSuspense<
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary Retrieve a tag
 */

export function useGetTagSuspense<
  TData = Awaited<ReturnType<typeof getTag>>,
  TError = TealErrorResponse,
>(
  tagId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTagSuspenseQueryOptions(tagId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Deletes an existing tag for an instance. Tags on existing line entries and
transactions will not be deleted.
 * @summary Delete a tag
 */
export const deleteTag = (tagId: string) => {
  return createTealInstance<void>({
    url: `/v0/tags/${tagId}`,
    method: "DELETE",
  });
};

export const getDeleteTagMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTag>>,
    TError,
    { tagId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTag>>,
  TError,
  { tagId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTag>>,
    { tagId: string }
  > = (props) => {
    const { tagId } = props ?? {};

    return deleteTag(tagId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTag>>
>;

export type DeleteTagMutationError = TealErrorResponse;

/**
 * @summary Delete a tag
 */
export const useDeleteTag = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTag>>,
    TError,
    { tagId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteTag>>,
  TError,
  { tagId: string },
  TContext
> => {
  const mutationOptions = getDeleteTagMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates an existing tag. Unspecified fields will not be updated.
 * @summary Update a tag
 */
export const updateTag = (tagId: string, tagUpdate: TagUpdate) => {
  return createTealInstance<unknown>({
    url: `/v0/tags/${tagId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: tagUpdate,
  });
};

export const getUpdateTagMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTag>>,
    TError,
    { tagId: string; data: TagUpdate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTag>>,
  TError,
  { tagId: string; data: TagUpdate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTag>>,
    { tagId: string; data: TagUpdate }
  > = (props) => {
    const { tagId, data } = props ?? {};

    return updateTag(tagId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTag>>
>;
export type UpdateTagMutationBody = TagUpdate;
export type UpdateTagMutationError = TealErrorResponse;

/**
 * @summary Update a tag
 */
export const useUpdateTag = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTag>>,
    TError,
    { tagId: string; data: TagUpdate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateTag>>,
  TError,
  { tagId: string; data: TagUpdate },
  TContext
> => {
  const mutationOptions = getUpdateTagMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * List of tags belonging to an instance.
 * @summary List all tags
 */
export const getInstanceTags = (
  params?: GetInstanceTagsParams,
  signal?: AbortSignal,
) => {
  return createTealInstance<EnhancedTagPage>({
    url: `/v0/tags`,
    method: "GET",
    params,
    signal,
  });
};

export const getGetInstanceTagsQueryKey = (params?: GetInstanceTagsParams) => {
  return [`/v0/tags`, ...(params ? [params] : [])] as const;
};

export const getGetInstanceTagsQueryOptions = <
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInstanceTagsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInstanceTags>>> = ({
    signal,
  }) => getInstanceTags(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInstanceTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInstanceTagsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInstanceTags>>
>;
export type GetInstanceTagsQueryError = TealErrorResponse;

export function useGetInstanceTags<
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params: undefined | GetInstanceTagsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInstanceTags>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetInstanceTags<
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getInstanceTags>>,
          TError,
          TData
        >,
        "initialData"
      >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetInstanceTags<
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all tags
 */

export function useGetInstanceTags<
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInstanceTagsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary List all tags
 */
export const prefetchGetInstanceTags = async <
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  queryClient: QueryClient,
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetInstanceTagsQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetInstanceTagsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInstanceTagsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInstanceTags>>> = ({
    signal,
  }) => getInstanceTags(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getInstanceTags>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInstanceTagsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInstanceTags>>
>;
export type GetInstanceTagsSuspenseQueryError = TealErrorResponse;

export function useGetInstanceTagsSuspense<
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params: undefined | GetInstanceTagsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetInstanceTagsSuspense<
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetInstanceTagsSuspense<
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };
/**
 * @summary List all tags
 */

export function useGetInstanceTagsSuspense<
  TData = Awaited<ReturnType<typeof getInstanceTags>>,
  TError = TealErrorResponse,
>(
  params?: GetInstanceTagsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getInstanceTags>>,
        TError,
        TData
      >
    >;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInstanceTagsSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Creates a new tag for an existing instance and tag group.
 * @summary Create a tag
 */
export const addInstanceTag = (tagCreate: TagCreate) => {
  return createTealInstance<Tag>({
    url: `/v0/tags`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: tagCreate,
  });
};

export const getAddInstanceTagMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addInstanceTag>>,
    TError,
    { data: TagCreate },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addInstanceTag>>,
  TError,
  { data: TagCreate },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addInstanceTag>>,
    { data: TagCreate }
  > = (props) => {
    const { data } = props ?? {};

    return addInstanceTag(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddInstanceTagMutationResult = NonNullable<
  Awaited<ReturnType<typeof addInstanceTag>>
>;
export type AddInstanceTagMutationBody = TagCreate;
export type AddInstanceTagMutationError = TealErrorResponse;

/**
 * @summary Create a tag
 */
export const useAddInstanceTag = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addInstanceTag>>,
    TError,
    { data: TagCreate },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addInstanceTag>>,
  TError,
  { data: TagCreate },
  TContext
> => {
  const mutationOptions = getAddInstanceTagMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Removes a tag from a list of transactions.
 * @summary Remove a tag from a list of transactions
 */
export const clearTags = (tagId: string, tagClear: TagClear) => {
  return createTealInstance<unknown>({
    url: `/v0/tags/${tagId}/clear`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: tagClear,
  });
};

export const getClearTagsMutationOptions = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof clearTags>>,
    TError,
    { tagId: string; data: TagClear },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof clearTags>>,
  TError,
  { tagId: string; data: TagClear },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof clearTags>>,
    { tagId: string; data: TagClear }
  > = (props) => {
    const { tagId, data } = props ?? {};

    return clearTags(tagId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ClearTagsMutationResult = NonNullable<
  Awaited<ReturnType<typeof clearTags>>
>;
export type ClearTagsMutationBody = TagClear;
export type ClearTagsMutationError = TealErrorResponse;

/**
 * @summary Remove a tag from a list of transactions
 */
export const useClearTags = <
  TError = TealErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof clearTags>>,
    TError,
    { tagId: string; data: TagClear },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof clearTags>>,
  TError,
  { tagId: string; data: TagClear },
  TContext
> => {
  const mutationOptions = getClearTagsMutationOptions(options);

  return useMutation(mutationOptions);
};
