import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export type TransactionSelectButtonProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  selected?: boolean;
} & VariantProps<typeof selectButtonVariants>;

const selectButtonVariants = cva(
  'inline-flex shrink-0 justify-center items-center gap-2 rounded-md bg-transparent uppercase text-[0.5625rem] font-bold',
  {
    variants: {
      size: {
        sm: 'h-8 border border-black px-3 hover:bg-primary-200 data-[selected=true]:bg-black data-[selected=true]:text-white',
        md: 'h-10'
      },
      variant: {
        default: '',
        'add-new': 'border-dashed'
      }
    },
    defaultVariants: {
      size: 'md',
      variant: 'default'
    }
  }
);

export default function TransactionSelectButton({
  onClick,
  size,
  variant = 'default',
  className,
  selected,
  children
}: TransactionSelectButtonProps) {
  return (
    <button
      className={cn(className, '', selectButtonVariants({ size, variant }))}
      data-selected={selected}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
}
