import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function ShieldIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      stroke="black"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        clipRule="evenodd"
        d="M8.10785 0.644834C8.32612 0.451722 8.65414 0.451722 8.87241 0.644834C10.5569 2.13515 12.7554 2.91111 15.0022 2.8083C15.2677 2.79615 15.5071 2.96693 15.5821 3.2219C16.3311 5.76989 16.0148 8.5127 14.7055 10.8233C13.3961 13.1339 11.2057 14.8147 8.63498 15.4815C8.53998 15.5062 8.44027 15.5062 8.34528 15.4815C5.77456 14.8147 3.58414 13.1339 2.2748 10.8233C0.965465 8.5127 0.649147 5.76989 1.39816 3.2219C1.47312 2.96693 1.71255 2.79615 1.97804 2.8083C4.22481 2.91111 6.42335 2.13515 8.10785 0.644834ZM2.39215 3.97118C1.88263 6.09688 2.19566 8.34323 3.27868 10.2544C4.4093 12.2497 6.2845 13.7127 8.49013 14.3257C10.6958 13.7127 12.571 12.2497 13.7016 10.2544C14.7846 8.34323 15.0976 6.09688 14.5881 3.97118C12.3719 3.96707 10.222 3.21207 8.49013 1.83027C6.75823 3.21207 4.60836 3.96707 2.39215 3.97118ZM11.2058 6.05359C11.4311 6.27889 11.4311 6.64418 11.2058 6.86948L8.12886 9.94641C7.90355 10.1717 7.53827 10.1717 7.31296 9.94641L5.7745 8.40795C5.5492 8.18264 5.5492 7.81736 5.7745 7.59205C5.99981 7.36675 6.36509 7.36675 6.5904 7.59205L7.72091 8.72257L10.3899 6.05359C10.6152 5.82829 10.9805 5.82829 11.2058 6.05359Z"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default memo(ShieldIcon);
