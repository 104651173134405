export function getSignedUrlDetails(url: string) {
  let extension = undefined;
  let name = undefined;
  const regex = /\/\d+-([^\/?#]+)\.([^\/?#]+)(?:[?#]|$)/i;
  const matches = url.match(regex);

  if (matches && matches.length > 2) {
    name = matches[1];
    extension = matches[2];
  }
  return {
    name: name + `.${extension}`,
    extension
  };
}

export function getFileDetails(file: File) {
  let extension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
  let name = file.name;
  return {
    name,
    extension
  };
}
