import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function Google({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-4', className)}
      fill="#333333"
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M15.2721 16.1846C17.4473 14.1544 18.4141 10.7708 17.834 7.53223H9.18164V11.1092H14.112C13.9187 12.2693 13.242 13.236 12.2752 13.8644L15.2721 16.1846Z" />
      <path d="M0.964844 13.3326C1.60072 14.5852 2.51265 15.6772 3.6319 16.5261C4.75114 17.375 6.04849 17.9587 7.42616 18.2333C8.80382 18.5079 10.2258 18.4662 11.585 18.1113C12.9442 17.7564 14.2051 17.0976 15.2727 16.1845L12.2758 13.8643C9.71389 15.5562 5.46021 14.9278 4.01009 10.9641L0.964844 13.3326Z" />
      <path d="M4.0101 10.964C3.62341 9.75561 3.62341 8.64385 4.0101 7.43542L0.964857 5.06689C-0.1469 7.29041 -0.485261 10.4323 0.964857 13.3326L4.0101 10.964Z" />
      <path d="M4.01009 7.43566C5.07351 4.10039 9.61721 2.1669 12.6625 5.0188L15.321 2.40858C11.5507 -1.21671 4.20344 -1.0717 0.964844 5.06713L4.01009 7.43566Z" />
    </svg>
  );
}

export default memo(Google);
