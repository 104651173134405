'use client';

import * as React from 'react';
import CalendarIcon from '@/assets/icons/calendar-icon';
import { format } from 'date-fns';
import type { DayPickerSingleProps } from 'react-day-picker';

import { cn } from '@/lib/utils';

import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export type DatePickerProps = Omit<DayPickerSingleProps, 'mode'>;

export default function DayPicker({ selected, className, ...props }: DatePickerProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn(
            'justify-start text-left rounded-md py-5 px-4 h-18 text-sm font-semibold bg-neutral-200 text-black hover:text-black hover:bg-primary-200',
            className,
            !selected && 'text-muted-foreground'
          )}
        >
          <div className="flex items-center gap-4">
            <CalendarIcon className="size-5" />
            {selected ? format(selected, 'PPP') : <span>Pick a date</span>}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-auto p-0">
        <Calendar initialFocus mode="single" selected={selected} {...props} />
      </PopoverContent>
    </Popover>
  );
}
