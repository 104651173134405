import { useNavigate } from '@tanstack/react-router';

import actionsObserver from '@/lib/actions-observer';
import useSendMessage from '@/components/milo-input/use-send-message';

export type HomeActionProperty = {
  label: string;
  icon: string;
  firstMessage: string;
  activitySubject: string;
  activityType: 'ask' | 'operations' | 'setup';
};

export type HomeActionProps = {
  action: HomeActionProperty;
};

export default function HomeAction({ action }: HomeActionProps) {
  const { sendMessage } = useSendMessage();
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col w-40 sm:w-80 py-6 pl-6 pr-3 gap-3 lg:gap-5 lg:p-8 lg:items-center bg-white/20 rounded-xl hover:bg-white/30 cursor-pointer"
      onClick={() => {
        actionsObserver.notify('HOMEPAGE_ACTION_CLICK', { action });
        sendMessage(
          {
            message: action.firstMessage,
            activityType: action.activityType,
            activitySubject: action.activitySubject,
            activityCategory: 'owner_ask_or_request'
          },
          cData => {
            if (location.pathname !== `/conversation/${cData.conversationId}`) {
              navigate({
                to: `/conversation/$id`,
                params: {
                  id: cData.conversationId
                },
                state: {
                  msgs: cData.msgs
                }
              });
            }
          }
        );
      }}
    >
      <div>
        <img alt="" className="size-14 lg:size-18" src={action.icon} />
      </div>

      <div className="text-xs text-white font-medium lg:text-base">{action.label}</div>
    </div>
  );
}
