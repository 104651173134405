import { useEffect } from 'react';
import digging1 from '@/assets/gifs/digging1.gif';
import AnswerSelector from '@/sections/onboarding/questionnaire/answer-selector';
import NextStepSection from '@/sections/onboarding/questionnaire/next-step-section';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { useBoolean } from '@/hooks/use-boolean';
import { Button } from '@/components/ui/button';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';
import { useWizard } from '@/components/form-wizard';


const AnimatedButton = motion(Button);

const revenueRangeVariants = [
  { name: 'Under $100K a year', value: '100K' },
  { name: '$100K to $500K a year', value: '100K-500K' },
  { name: '$500K to $1 million a year', value: '500K-1000K' },
  { name: '$1 million to $5 million a year', value: '1000K-5000K' },
  { name: 'Over $5 million a year', value: '5000K+' }
] as const;

export default function RevenueStep() {
  const { control, getValues } = useFormContext();
  const textRevealed = useBoolean(false);
  const [goToNextStep, stepNumber, activeStep] = useWizard(
    useShallow(state => [state.goToNextStep, state.stepNumber, state.activeStep])
  );
  const bottomAnimationConfig = {
    animate: textRevealed.value ? { opacity: 1 } : false,
    initial: { opacity: 0 },
    transition: { duration: 0.4, ease: 'easeInOut' }
  };
  const questionText = 'Roughly, what’s your annual revenue?';
  useEffect(() => {
    Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_View`, {
      questionText: questionText
    });
  }, []);
  return (
    <>
      <div className="flex flex-1 flex-col gap-8 py-4 px-12 overflow-hidden">
        <div className="flex flex-col gap-4">
          <img className="w-[4.324rem] h-[2.625rem] lg:hidden" src={digging1} />
          <div className="text-2xl">
            <MotionTextRevealContainer
              value={questionText}
              wrapperProps={{
                onAnimationComplete() {
                  textRevealed.onTrue();
                }
              }}
            />
          </div>
        </div>
        <FormField
          control={control}
          name="revenueRange"
          render={({ field }) => (
            <FormItem className="space-y-5 overflow-y-scroll scrollbar-hidden">
              <FormControl>
                <RadioGroup className="flex flex-col" defaultValue={field.value} onValueChange={field.onChange}>
                  {revenueRangeVariants.map(({ name, value }) => (
                    <FormItem
                      className={cn('flex items-center animate-in fade-in duration-[800ms]', {
                        hidden: !textRevealed.value
                      })}
                      key={name}
                    >
                      <FormControl className="hidden">
                        <RadioGroupItem value={value} />
                      </FormControl>
                      <FormLabel className="w-full">
                        <AnswerSelector selected={field.value === value}>{name}</AnswerSelector>
                      </FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {activeStep?.canSkip ? (
          <AnimatedButton
            {...bottomAnimationConfig}
            className="absolute right-9 lg:right-14 lg:top-14 top-7 text-xs font-semibold"
            onClick={() => {
              goToNextStep();
              Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_SkipClick`);
            }}
            size="auto"
            type="button"
            variant="ghost"
          >
            Skip
          </AnimatedButton>
        ) : null}
      </div>
      <NextStepSection
        onClick={() => {
          const values = getValues();
          Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_ButtonClick`, {
            answerText: values?.revenueRange
          });
        }}
        startAnimation={textRevealed.value}
      >
        Next
      </NextStepSection>
    </>
  );
}
