import { memo } from 'react';

import { cn } from '@/lib/utils';

// ----------------------------------------------------------------------

function CloseIcon({ className, ...other }: React.ComponentProps<'svg'>) {
  return (
    <svg
      className={cn('size-6', className)}
      fill="black"
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M6.34794 5.00341L9.70929 1.64206C9.89098 1.46037 9.99092 1.22417 9.99092 0.960709C9.99092 0.697252 9.89098 0.461049 9.70929 0.279355C9.33682 -0.0931183 8.72814 -0.0931183 8.35567 0.279355L4.99432 3.6407L1.63298 0.28844C1.2605 -0.0840336 0.651828 -0.0840336 0.279355 0.28844C-0.0931183 0.660913 -0.0931183 1.26959 0.279355 1.64206L3.6407 5.00341L0.279355 8.36475C-0.0931183 8.73722 -0.0931183 9.3459 0.279355 9.71837C0.461049 9.90007 0.697252 10 0.960709 10C1.21508 10 1.46037 9.90007 1.64206 9.71837L5.00341 6.35703L8.36475 9.71837C8.72814 10.0818 9.36407 10.0818 9.71837 9.71837C9.90007 9.53668 10 9.30048 10 9.03702C10 8.77356 9.90007 8.53736 9.71837 8.35567L6.35703 4.99432L6.34794 5.00341Z" />
    </svg>
  );
}

export default memo(CloseIcon);
